import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useUser } from "../../../../contexts/UserContext";
import { tokenService } from "../../../../services/api/client";
import { useBrand } from "../../../../contexts/BrandContext";
import { endpoints } from "../../../../services/api/endpoints";
import Wrapper from "../Wrapper/Wrapper";
import AnimationLoader from "../../../../CommonComponents/AnimationLoader/AnimationLoader";
import useMicrosoftAuth from "../CustomHooks/useMicrosoftAuth";

const AuthCallback = () => {
  const { handleMicrosoftLogin } = useMicrosoftAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { provider, userId } = useParams();
  const { updateUser } = useUser();
  const { selectedBrand, selectBrand } = useBrand();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const accessToken = params.get("access_token");
    const refreshToken = params.get("refresh_token");
    const userId = params.get("userId");

    const fetchUserData = async (id) => {
      try {
        const response = await endpoints.auth.getUserData(id);
        const userData = response.data;
        updateUser(userData);
        if (userData.brands?.length) {
          navigate(`/workspace-selection`);
        } else if (userData.pending_invites?.length > 0) {
          navigate(`/accounts/${userId}/pending-invites`);
        } else {
          navigate("/workspace-setup");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        navigate("/login");
      } finally {
        setLoading(false);
      }
    };

    if (provider === "microsoft") {
      handleMicrosoftLogin()
        .then((data) => {
          if (data && data.user_id) {
            tokenService.setTokens(data.accessToken, data.refreshToken);
            fetchUserData(data.user_id);
          }
        })
        .catch((error) => {
          console.error("Microsoft login failed:", error);
          navigate("/login");
          setLoading(false);
        });
    } else if (accessToken && refreshToken) {
      tokenService.setTokens(accessToken, refreshToken);
      fetchUserData(userId);
    } else {
      navigate("/login");
      setLoading(false);
    }
  }, [provider, userId, location, navigate, updateUser, selectBrand]);

  if (loading)
    return (
      <Wrapper isfullLength>
        <AnimationLoader text="Authenticating... Almost there!" />
      </Wrapper>
    );

  return null;
};

export default AuthCallback;
