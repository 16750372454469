import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import ChipButton from "../../../../CommonComponents/ChipButton/ChipButton";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import {
  getRiskColor,
  getAVIRankFromPriority,
} from "../../../AEO/utils/DataUtils";
import ScoreMovementTypography from "../../pages/Overview/components/ScoreMovementTypography";
import { useParams } from "react-router-dom";
import PopUp from "../OptimizationPopUp/Popup";

const risks = {
  overall_risk: "High",
  data: [
    { optimization_priority: "High risk", score: 302, movement: 5 },
    { optimization_priority: "Medium risk", score: 257, movement: 5 },
    { optimization_priority: "Low risk", score: 203, movement: 5 },
  ],
};
const RiskAudit = ({ values }) => {
  const theme = useTheme();
  const [openPopup, setOpenPopup] = useState();
  const { noData } = useGlobalFilters();
  const data = noData ? risks : values;

  const totalScore = useMemo(() => {
    return data?.data?.reduce((total, risk) => total + risk.count, 0);
  }, [data]);

  const dataLength = data?.data?.length || 1;

  const { topicName } = useParams();
  const name = topicName ? topicName.replace(/-/g, " ") : "";
  return (
    <Box display="flex" width="100%" flexDirection="column">
      <Box display="flex" width="100%" flexDirection="row">
        {data?.data?.map((risk, index) => (
          <Box
            key={index}
            width={
              totalScore === 0
                ? `${100 / dataLength}%`
                : `${(risk.count / totalScore) * 100}%`
            }
            minWidth={200}
            display="flex"
            p={0.5}
            flexDirection="column"
            rowGap={1}
          >
            <Box
              style={{
                backgroundColor: getRiskColor(
                  risk.optimization_priority,
                  theme
                )[0],
                color: getRiskColor(risk.optimization_priority, theme)[1],
                fontSize: 13,
                borderRadius: 7,
                fontWeight: 400,
                padding: "0px 5px",
                display: "inline-block",
                maxWidth: "fit-content",
              }}
            >
              {getAVIRankFromPriority(risk.optimization_priority)} AVI
            </Box>

            <ScoreMovementTypography
              score={risk.count}
              movement={risk.movement_count}
              padding="20px"
              borderRadius="10px"
              backgroundColor={
                getRiskColor(risk.optimization_priority, theme)[0]
              }
              color={getRiskColor(risk.optimization_priority, theme)[1]}
            />
          </Box>
        ))}{" "}
      </Box>
      <Typography style={{ color: theme.palette.text.tertiary, fontSize: 14 }}>
        Your brand's AI Visibility Impact {name ? `in ${name}` : ""} is{" "}
        {getAVIRankFromPriority(data.overall_risk)}
      </Typography>
      <Divider
        sx={{
          borderColor: theme.palette.border.quaternary,
          width: "100%",
          margin: "10px 0px",
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          maxWidth: 180,
        }}
      >
        <ChipButton
          label={"Optimization Hub"}
          backgroundColor={theme.palette.background.brand}
          textColor={theme.palette.text.chiptext}
          hoverBackgroundColor={theme.palette.text.brandbold}
          hoverTextColor={theme.palette.text.chiptext}
          hoverBorderColor={theme.palette.text.brandbold}
          fullWidth
          padding="8px 10px"
          onClick={() => setOpenPopup(true)}
          // isLoading={isLoading}
        />
      </div>
      {openPopup && (
        <PopUp open={openPopup} onClose={() => setOpenPopup(false)} />
      )}
    </Box>
  );
};
export default RiskAudit;
