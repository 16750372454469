import React, { useState, useEffect, useMemo } from "react";
import {
  Typography,
  Box,
  Button,
  TextField,
  Card,
  CircularProgress,
  useTheme,
} from "@mui/material";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import WorkspacePageTemplate from "../../../Workspace/components/PageTemplate/PageTemplate";
import ErrorTypography from "../../../../CommonComponents/FormField/components/ErrorMessage/components/ErrorTypography/ErrorTypography";
import { useUser } from "../../../../contexts/UserContext";
import { endpoints } from "../../../../services/api/endpoints";

const validateWordCount = (text) => {
  const wordCount = text.trim().split(/\s+/).length;
  return wordCount >= 800 && wordCount <= 3000;
};

const AuthorStamp = () => {
  const { loggedInUser, updateUser } = useUser();
  const userId = loggedInUser.id;
  const theme = useTheme();
  const [stamp, setAuthorStamp] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [err, setError] = useState(false);
  const [saved, setSaved] = useState();
  const wordCount = useMemo(() => {
    return stamp.trim().split(/\s+/).length;
  }, [stamp]);

  useEffect(() => {
    setInitialLoading(true);
    endpoints.profile
      .getAuthorStamp(userId)
      .then((response) => {
        setAuthorStamp(response.data.content);
        setInitialLoading(false);

        if (!loggedInUser.has_author_stamp) {
          updateUser({ ...loggedInUser, has_author_stamp: true });
        }
      })
      .catch((error) => {
        setInitialLoading(false);
      });
  }, [userId, loggedInUser, updateUser]);

  const handleSave = () => {
    const values = { userId: userId, authorStamp: stamp };
    setLoading(true);
    setSaved(false);
    setError(false);
    const isValid = validateWordCount(stamp);

    if (isValid) {
      endpoints.profile
        .createAuthorStamp(values)
        .then((response) => {
          setLoading(false);
          setSaved(true);
        })
        .catch((err) => {
          setSaved(false);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setError(
        wordCount < 800
          ? "Author stamp should be more than 800 words"
          : wordCount > 3000
          ? "Author stamp should be less than 3000 words"
          : ""
      );
    }
  };
  return (
    <WorkspacePageTemplate title="Author Stamp">
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: "14px",
          fontWeight: 450,
          maxWidth: "596px",
          mt: -4,
        }}
        color="text.secondary"
      >
        Add an example of your best written blog or article so Goodie
        understands your personal tone of voice and writing style. Copy and
        paste your best written work below.
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          marginTop: "40px",
          paddingTop: "20px",
          fontSize: "14px",
          fontWeight: 450,
          lineHeight: "20px",
          borderTop: `1px solid ${theme.palette.border.tertiary}`,
        }}
        color="text.tertiary"
      >
        Writing Sample
      </Typography>
      <Card
        style={{
          borderRadius: "10px",
          padding: "20px ",
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          boxShadow: "none",
          rowGap: 10,
          width: "100%",
          marginTop: 40,
          backgroundColor: theme.palette.background.secondary,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "20px",
          }}
          color="text.primary"
        >
          Reference:{" "}
          <span
            style={{
              color: theme.palette.text.secondary,
              fontWeight: 400,
            }}
          >
            Article should be between 800-3000 words max.
          </span>
        </Typography>

        <TextField
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: `1px solid ${theme.palette.border.secondary}`,
                borderRadius: "11px",
              },
              "&:hover fieldset": {
                border: "1px solid",
                borderColor: theme.palette.border.secondary,
              },
              "&.Mui-focused fieldset": {
                border: "1px solid",
                borderColor: theme.palette.border.secondary,
              },
              "& .MuiInputBase-input::placeholder": {
                color: theme.palette.text.quaternary,
              },
            },
          }}
          value={stamp}
          rows={7}
          multiline
          onChange={(e) => {
            setSaved(false);
            setAuthorStamp(e.target.value);
          }}
          placeholder={
            initialLoading
              ? "Loading..."
              : "Lorem ipsum dolor sit amet consectetur. Mollis a etiam aliquam nibh fermentum sollicitudin lectus neque lectus. Ipsum cursus rutrum a purus cursus. Tellus nibh euismod nisi quam magnis orci eu risus nisi. Ac facilisis consectetur proin est quis faucibus. Velit lectus ante suscipit nibh ultrices. Mollis nam cum vitae urna euismod imperdiet id lectus"
          }
        />

        {err && (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ErrorTypography errorMessage={err} hasBackground={false} />{" "}
            <Typography sx={{ fontSize: "14px", color: "#E9301C" }}>
              {wordCount}/3000
            </Typography>{" "}
          </Box>
        )}

        <div style={{ display: "flex", flexDirection: "row" }}>
          {" "}
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              backgroundColor: saved
                ? "background.tertiary"
                : "buttons.primary",
              color: saved ? "text.secondary" : "text.chiptext",
              padding: "10px 12px",
              borderRadius: "11px",
              fontSize: "14px",
              fontWeight: 450,
              lineHeight: "20px",
              boxShadow: "none",
              minWidth: "auto",
              "&:hover": {
                backgroundColor: "buttons.primary",
              },
            }}
            onClick={() => handleSave()}
            startIcon={saved ? <CheckCircleOutlineSharpIcon /> : null}
          >
            {loading ? (
              <CircularProgress
                style={{
                  width: 20,
                  height: 20,
                  color: "#2D333A",
                }}
              />
            ) : saved ? (
              "Saved"
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Card>
    </WorkspacePageTemplate>
  );
};

export default AuthorStamp;
