import React from "react";
import { useTheme } from "@mui/material";

const MainLogoIcon = () => {
  const theme = useTheme();

  const color = theme.palette.background.brand;
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 46 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        width="45"
        height="45"
        rx="22.5"
        fill="#8DA3FA"
        fill-opacity="0.2"
      />
      <g clip-path="url(#clip0_9683_38332)">
        <path
          d="M35 18.2148L23 23.572V37.5006L35 32.1434V18.2148Z"
          fill="#8DA3FA"
        />
        <path d="M11 18.2146L23 23.5717V12.8574L11 18.2146Z" fill="#8DA3FA" />
        <path
          d="M35.0012 12.8571C31.0881 13.597 30.5043 14.1964 29.7838 18.2143C29.0633 14.1964 28.4794 13.597 24.5664 12.8571C28.4794 12.1173 29.0633 11.5179 29.7838 7.5C30.5043 11.5179 31.0881 12.1173 35.0012 12.8571Z"
          fill="#8DA3FA"
        />
      </g>
      <defs>
        <clipPath id="clip0_9683_38332">
          <rect
            width="24"
            height="30"
            fill="white"
            transform="translate(11 7.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MainLogoIcon;
