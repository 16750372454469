import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";

const DNAStep = ({
  title,
  children,
  subtitle,
  required,
  tipTitle,
  tips = [],
}) => {
  const titleRef = useRef(null);
  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: 6,
        pl: 8,
        pt: 5,
        pr: 20,
      }}
    >
      <div>
        <Typography
          variant="h2"
          sx={{
            fontSize: 40,
            fontWeight: 500,
          }}
          ref={titleRef}
          tabIndex={-1}
          color="text.primary"
        >
          {title}
        </Typography>

        {subtitle && (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 450,
              mt: 1,
              lineHeight: 1.6,
              margin: 0,
              padding: 0,
            }}
            color="text.secondary"
          >
            {subtitle}
          </Typography>
        )}

        {tipTitle && tips.length > 0 && (
          <Box sx={{ mt: 1 }}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 450,
                lineHeight: 1.6,
                margin: 0,
                padding: 0,
              }}
              color="text.secondary"
            >
              {tipTitle}
            </Typography>
            <Typography
              component="ul"
              sx={{
                fontSize: "14px",
                fontWeight: 450,
                lineHeight: 1.6,
                margin: 0,
                padding: 0,
                pl: 2,
              }}
              color="text.secondary"
            >
              {tips.map((tip, index) => (
                <li key={index}>{tip}</li>
              ))}
            </Typography>
          </Box>
        )}

        {required && (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 450,
              mt: 1,
              lineHeight: 1.6,
              margin: 0,
              padding: 0,
            }}
            color="text.secondary"
          >
            <span style={{ color: "#718CF9" }}>* </span>
            Required fields
          </Typography>
        )}
      </div>
      {children}
    </Box>
  );
};

export default DNAStep;
