import { createContext, useContext, useState } from "react";

const BrandContext = createContext(null);

export const useBrand = () => {
  const context = useContext(BrandContext);
  if (!context) {
    throw new Error("useBrandContext must be used within a BrandProvider");
  }
  return context;
};

export const BrandProvider = ({ children }) => {
  const [selectedBrand, setSelectedBrand] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem("brandId"));
    } catch {
      return null;
    }
  });

  const selectBrand = (brandId) => {
    setSelectedBrand(brandId);
    localStorage.setItem("brandId", brandId);
  };

  const clearWorkspaceSelection = () => {
    setSelectedBrand(null);
    localStorage.removeItem("brandId");
  };

  const value = {
    selectedBrand,
    selectBrand,
    clearWorkspaceSelection,
  };

  return (
    <BrandContext.Provider value={value}>{children}</BrandContext.Provider>
  );
};
