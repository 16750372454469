import React from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

/**
 * Creates a header cell with an optional tooltip
 * @param {string} headerName - The name to display in the header
 * @param {string} tooltipMessage - Optional tooltip text to show on hover
 * @param {object} theme - The theme object from MUI
 * @returns {JSX.Element} The header cell component
 */
export const createHeaderCellWithTooltip = (
  headerName,
  tooltipMessage,
  theme
) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "4px",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Typography
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {headerName}
      </Typography>
      {tooltipMessage && (
        <Tooltip title={tooltipMessage} placement="top">
          <InfoOutlinedIcon
            sx={{
              color: theme.palette.text.tertiary,
              width: 15,
              height: 15,
              flexShrink: 0,
            }}
          />
        </Tooltip>
      )}
    </Box>
  );
};
