import React from "react";
import {
  Radar,
  RadarChart as RechartsRadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import CustomeTooltip from "../LineChart/Components/Tooltip/Tooltip";
import { useTheme } from "@mui/material";

const RadarChart = ({
  data,
  hasLegend,
  angleDataKey = "benchmark",
  radarTitles,
  hasTooltip = true,
  hasPolarAngleAxis = true,
  hasRaduisAxis = true,
  isLLM,
  colorMap = {},
}) => {
  const theme = useTheme();
  const renderTickWithTheme = (props) => renderCustomTick(props, theme);
  const colors = [
    theme.palette.brands.A,
    theme.palette.brands.B,
    theme.palette.brands.C,
    theme.palette.brands.D,
    theme.palette.brands.E,
    theme.palette.brands.F,
    theme.palette.brands.G,
  ];
  const llmColors = [
    theme.palette.llms.A,
    theme.palette.llms.D,
    theme.palette.llms.E,
    theme.palette.llms.C,
    theme.palette.llms.B,
  ];
  const maxValue = Math.max(
    ...data.flatMap((item) =>
      radarTitles.map((key) => parseFloat(item[key]) || 0)
    )
  );

  return (
    <ResponsiveContainer width="100%" height={400}>
      <RechartsRadarChart data={data}>
        <PolarGrid />
        {hasPolarAngleAxis && (
          <PolarAngleAxis
            dataKey={angleDataKey}
            tick={renderTickWithTheme}
            angle={0}
            tickSize={10}
          />
        )}
        {hasRaduisAxis && (
          <PolarRadiusAxis
            tick={false}
            axisLine={false}
            angle={0}
            domain={[0, maxValue]}
            style={{ color: "#8C8C8C", fontSize: 12, fontWeight: 450 }}
          />
        )}

        {radarTitles?.map((title, index) => (
          <Radar
            key={title}
            name={title}
            dataKey={title}
            stroke={
              isLLM
                ? llmColors[index]
                : Object.keys(colorMap).length > 0
                ? colorMap[title]
                : colors[index]
            }
            fill={
              isLLM
                ? llmColors[index]
                : Object.keys(colorMap).length > 0
                ? colorMap[title]
                : colors[index]
            }
            fillOpacity={0.1}
            strokeWidth={2}
          />
        ))}
        {hasTooltip && (
          <Tooltip
            content={<CustomeTooltip />}
            cursor={{
              stroke: "#CCCCCC",
              strokeWidth: 1,
              strokeDasharray: "2 2",
            }}
          />
        )}
        {hasLegend && <Legend />}
      </RechartsRadarChart>
    </ResponsiveContainer>
  );
};
const renderCustomTick = (props, theme) => {
  const { x, y, payload, textAnchor } = props;
  const { value } = payload;
  const maxWordsPerLine = 1;

  const words = value.split(" ");
  const lines = [];
  let currentLine = [];

  words.forEach((word) => {
    if (
      currentLine.join(" ").length + word.length + 1 <=
      maxWordsPerLine * 10
    ) {
      currentLine.push(word);
    } else {
      lines.push(currentLine.join(" "));
      currentLine = [word];
    }
  });

  if (currentLine.length > 0) {
    lines.push(currentLine.join(" "));
  }

  return (
    <g transform={`translate(${x},${y})`} textAnchor={textAnchor}>
      {lines.map((line, index) => (
        <text
          key={index}
          x={0}
          y={index * 16}
          fill={theme.palette.text.secondary}
          fontSize={12}
          fontWeight={450}
        >
          {line}
        </text>
      ))}
    </g>
  );
};

export default RadarChart;
