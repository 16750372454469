import ChipButton from "../../../../CommonComponents/ChipButton/ChipButton";
import CredentialsSection from "../CredentialsSection/CredentialsSection";
import GoogleLogin from "../GoogleLogin";
import OrSeperator from "../OrSeperator/OrSeperator";
import EastIcon from "@mui/icons-material/East";
import { useCallback, useMemo, useState } from "react";
import { Card, Typography, useTheme } from "@mui/material";
import Wrapper from "../Wrapper/Wrapper";
import { useLocation, useNavigate } from "react-router-dom";
import NamesSection from "./Components/NamesSection";
import { Formik } from "formik";
import { endpoints } from "../../../../services/api/endpoints";
import ErrorTypography from "../../../../CommonComponents/FormField/components/ErrorMessage/components/ErrorTypography/ErrorTypography";
import * as Yup from "yup";
import CreateAccountIcon from "../../../../CommonComponents/customIcons/CreateAccountIcon";
import { useUser } from "../../../../contexts/UserContext";
import MicrosoftLogin from "../MicrosoftLogin/MicrosoftLogin";

const SignUp = () => {
  const [buttonText, setButtonText] = useState("Continue with email");
  const [showPasswordTextfield, setShowPasswordTextfield] = useState(false);
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const theme = useTheme();
  const { updateUser } = useUser();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email") || "";

  const handleOnClick = useCallback((step, values, validateForm) => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length === 0) {
        setError("");

        switch (step) {
          case 1: {
            setShowPasswordTextfield(true);
            setButtonText("Continue");
            setCurrentStep(2);
            break;
          }
          case 2: {
            setButtonText("Create account");
            setCurrentStep(3);
            break;
          }
          case 3: {
            setIsLoading(true);
            endpoints.auth
              .signup(values)
              .then((response) => {
                setIsLoading(false);
                const data = response.data;
                const user_data = data.user;
                const userId = user_data.id;
                localStorage.setItem("userId", userId);
                updateUser(user_data);

                if (user_data.brands?.length > 0) {
                  const brandId = user_data.brands[0].id;
                  localStorage.setItem("brandId", brandId);
                  navigate(`/dashboard/${brandId}/`);
                } else if (user_data.pending_invites?.length > 0) {
                  navigate(`/accounts/${userId}/pending-invites`);
                } else {
                  navigate("/workspace-setup");
                }
              })
              .catch((err) => {
                setIsLoading(false);
                if (err.response && err.response.status === 403) {
                  setError(["You do not have access permission!"]);
                } else if (err.response?.data?.detail) {
                  setError(err.response?.data?.detail);
                } else {
                  const errorMessage =
                    err.response?.data?.message || "Invalid Email or Password!";
                  setError([errorMessage]);
                }
              });
            break;
          }

          default:
            break;
        }
      } else {
        const errorMessages = Object.values(errors).join(", ");
        setError(errorMessages);
      }
    });
  }, []);

  const initialValues = useMemo(
    () => ({ email: email, password: "", firstName: "", lastName: "" }),
    [email]
  );

  const validationSchema = useMemo(() => {
    switch (currentStep) {
      case 1:
        return Yup.object().shape({
          email: Yup.string()
            .email("Invalid email")
            .required("Email is required"),
        });
      case 2:
        return Yup.object().shape({
          password: Yup.string()
            .min(8, "Password must be at least 8 characters")
            .required("Password is required"),
        });
      case 3:
        return Yup.object().shape({
          first_name: Yup.string().required("First name is required"),
          last_name: Yup.string().required("Last name is required"),
        });
      default:
        return Yup.object();
    }
  }, [currentStep]);

  return (
    <Wrapper isfullLength>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={() => {}}
      >
        {({ values, errors, dirty, validateForm }) => (
          <Card
            style={{
              borderRadius: "35px",
              padding: "50px 85px 70px 85px",
              boxShadow: "none",
              display: "flex",
              flexDirection: "column",
              width: 600,
              rowGap: 20,
              backgroundColor: "transparent",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {" "}
              <CreateAccountIcon />
              <Typography
                style={{ fontSize: 36, fontWeight: 600 }}
                color="text.primary"
              >
                Create your account
              </Typography>
            </div>

            {currentStep === 3 ? (
              <NamesSection />
            ) : (
              <CredentialsSection
                showPasswordTextfield={showPasswordTextfield}
              />
            )}
            {error && <ErrorTypography errorMessage={error} />}
            <ChipButton
              iconAfter={!showPasswordTextfield && <EastIcon />}
              label={buttonText}
              backgroundColor={theme.palette.background.brand}
              textColor={theme.palette.text.chiptext}
              hoverBackgroundColor={theme.palette.text.brandbold}
              hoverTextColor={theme.palette.text.chiptext}
              hoverBorderColor={theme.palette.text.brandbold}
              fullWidth
              onClick={() => handleOnClick(currentStep, values, validateForm)}
              isLoading={isLoading}
            />
            <Typography style={{ fontSize: 14 }} color="text.secondary">
              Already have an account?{" "}
              <span
                style={{
                  color: theme.palette.text.brandbold,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/login")}
              >
                Log in
              </span>
            </Typography>
            {!showPasswordTextfield && (
              <>
                <OrSeperator />
                <GoogleLogin />
                <MicrosoftLogin />
              </>
            )}
          </Card>
        )}
      </Formik>
    </Wrapper>
  );
};

export default SignUp;
