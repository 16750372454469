import React from "react";
import {
  BarChart as RechartsBarChart,
  Bar,
  CartesianGrid,
  Legend,
  XAxis,
  YAxis,
  LabelList,
  Tooltip,
  ResponsiveContainer, // Import ResponsiveContainer
} from "recharts";
import CustomeTooltip from "../LineChart/Components/Tooltip/Tooltip";
import { useTheme } from "@mui/material";

const StackedBarChart = ({
  data,
  hasLegend,
  hasGrid,
  XAxisDataKey = "label",
  isVertical,
  height = 480,
  barSize = 119,
  hasTooltip = true,
  reverseTooltip,
}) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer width="100%" height={isVertical ? height : 34}>
      <RechartsBarChart
        data={data}
        barSize={isVertical ? barSize : 34}
        layout={isVertical ? "horizontal" : "vertical"}
        margin={{
          top: 20,
          right: isVertical ? 0 : 5,
          left: isVertical ? 0 : -60,
          bottom: 0,
        }}
      >
        {hasGrid && <CartesianGrid strokeDasharray="3 3" />}

        {isVertical ? (
          <>
            <XAxis
              dataKey={XAxisDataKey}
              axisLine={false}
              tickLine={false}
              tick={{
                fill: theme.palette.text.primary,
                fontSize: 12,
                fontWeight: 400,
                angle: 0,
                textAnchor: "middle",
              }}
              interval={0}
            />

            <YAxis
              type="number"
              domain={[0, "dataMax"]}
              axisLine={false}
              tickLine={false}
              tick={false}
              padding={{ left: 0, right: 0 }}
              width={0}
            />
          </>
        ) : (
          <>
            <XAxis
              type="number"
              axisLine={false}
              tickLine={false}
              tick={false}
            />
            <YAxis
              dataKey={XAxisDataKey}
              axisLine={false}
              tickLine={false}
              tick={false}
              type="category"
            />
          </>
        )}
        {hasTooltip && (
          <Tooltip
            content={<CustomeTooltip reverseTooltip={reverseTooltip} />}
            cursor={false}
          />
        )}

        {hasLegend && <Legend />}
        {isVertical ? (
          <>
            <Bar
              dataKey="negative"
              stackId="a"
              fill={theme.palette.bars.negative}
              stroke="transparent"
              strokeWidth={2}
              radius={[4, 4, 4, 4]}
            >
              <LabelList
                dataKey="negative"
                position="center"
                fill={theme.palette.text.negative}
                style={{ fontSize: 12, fontWeight: 500 }}
                formatter={(value) => (value >= 1 ? Math.round(value) : "")}
              />
            </Bar>
            <Bar
              dataKey="neutral"
              stackId="a"
              fill={theme.palette.bars.neutral}
              stroke="transparent"
              strokeWidth={2}
              radius={[4, 4, 4, 4]}
            >
              <LabelList
                dataKey="neutral"
                position="center"
                fill={theme.palette.text.neutral}
                style={{ fontSize: 12, fontWeight: 500 }}
                formatter={(value) => (value >= 1 ? Math.round(value) : "")}
              />
            </Bar>
            <Bar
              dataKey="positive"
              stackId="a"
              fill={theme.palette.bars.positive}
              stroke="transparent"
              strokeWidth={2}
              radius={[4, 4, 4, 4]}
            >
              <LabelList
                dataKey="positive"
                position="center"
                fill={theme.palette.text.positive}
                style={{ fontSize: 12, fontWeight: 500 }}
                formatter={(value) => (value >= 1 ? Math.round(value) : "")}
              />
            </Bar>
          </>
        ) : (
          <>
            <Bar
              dataKey="positive"
              stackId="a"
              fill={theme.palette.bars.positive}
              stroke="transparent"
              strokeWidth={2}
              radius={[4, 4, 4, 4]}
            >
              <LabelList
                dataKey="positive"
                position="center"
                fill={theme.palette.text.positive}
                style={{ fontSize: 12, fontWeight: 500 }}
                formatter={(value) => (value >= 1 ? Math.round(value) : "")}
              />
            </Bar>
            <Bar
              dataKey="neutral"
              stackId="a"
              fill={theme.palette.bars.neutral}
              stroke="transparent"
              strokeWidth={2}
              radius={[4, 4, 4, 4]}
            >
              <LabelList
                dataKey="neutral"
                position="center"
                fill={theme.palette.text.neutral}
                style={{ fontSize: 12, fontWeight: 500 }}
                formatter={(value) => (value >= 1 ? Math.round(value) : "")}
              />
            </Bar>
            <Bar
              dataKey="negative"
              stackId="a"
              fill={theme.palette.bars.negative}
              stroke="transparent"
              strokeWidth={2}
              radius={[4, 4, 4, 4]}
            >
              <LabelList
                dataKey="negative"
                position="center"
                fill={theme.palette.text.negative}
                style={{ fontSize: 12, fontWeight: 500 }}
                formatter={(value) => (value >= 1 ? Math.round(value) : "")}
              />
            </Bar>
          </>
        )}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

export default StackedBarChart;
