import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import { dummyLLMRankingData, rankingsLables } from "../../consts/consts";
import ChartCard from "../ChartCard/ChartCard";
import RankingTypography from "../RankingTypography/RankingTypography";
import RankingWithWheel from "../RankingWithWheel/RankingWithWheel";
import { endpoints } from "../../../../services/api/endpoints";

const RankingWheelsCard = ({
  title,
  tooltipPrimary,
  tooltipSecondary,
  hasLLMselect,
  height,
  hasRankingTypography,
  wheelsGrid,
  isVertical,
  icon,
  comparsion_type = "LLMs",
  isPerformance,
  wheelSize,
  gap,
}) => {
  const {
    date: globalDate,
    llm,
    initialDate: globalInitialDate,
    noData,
  } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const [rankings, setRankings] = useState([]);
  const { brandId, topicName } = useParams();
  const [date, setDate] = useState(globalDate);
  const [initialDate, setInitialDate] = useState(globalInitialDate);
  const currentWorkspaceName = localStorage.getItem("worskapceName");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const topic = topicName ? topicName.replace(/-/g, " ") : "";

  useEffect(() => {
    setInitialDate(globalInitialDate);
  }, [globalInitialDate]);

  useEffect(() => {
    setDate(globalDate);
  }, [globalDate]);

  useEffect(() => {
    const fetchRankings = (url, values) => {
      setLoading(true);

      const apiCall = isPerformance
        ? endpoints.aeoAnalytics.getPerformanceScoreRank(values)
        : endpoints.aeoAnalytics.getVisibilityScoreRank(values);

      apiCall
        .then((response) => {
          setRankings(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const values = {
      brandId,
      comparsion_type,
      LLMs: selectedLLM,
      ...(topic && { topic: topic }),
    };

    fetchRankings(null, values);
  }, [brandId, selectedLLM, comparsion_type, isPerformance, topic]);

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);
  return (
    <ChartCard
      icon={icon}
      title={title}
      tooltipMessagePrimary={tooltipPrimary}
      tooltipMessageSecondary={tooltipSecondary}
      hasLLMselect={hasLLMselect}
      height={height}
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
      setDate={setDate}
      date={date}
      initialDate={initialDate}
      isLoading={loading}
      isError={error}
    >
      {hasRankingTypography ? (
        <RankingTypography
          ranking={
            rankingsLables[
              rankings?.findIndex((item) => item.label === currentWorkspaceName)
            ]
          }
        />
      ) : (
        <Divider
          sx={{
            height: "1px",
            borderColor: "border.quaternary",
            borderBottomWidth: "1px",
            margin: "20px 0px",
          }}
        />
      )}
      <RankingWithWheel
        isVertical={isVertical}
        grid={wheelsGrid}
        rankings={noData ? dummyLLMRankingData : rankings}
        wheelSize={wheelSize}
        gap={gap}
      />
    </ChartCard>
  );
};
export default RankingWheelsCard;
