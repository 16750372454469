import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import AddMoreButton from "../../../../CommonComponents/FormField/components/AddMoreButton/AddMoreButton";
import SearchInput from "../../../../CommonComponents/SearchInput";
import WorkspacePageTemplate from "../PageTemplate/PageTemplate";
import AllPeopleList from "./AllPeopleList/AllPeopleList";
import InvitePeople from "./InvitePeople/InvitePeople";
import { endpoints } from "../../../../services/api/endpoints";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "12px !important",
    padding: "20px 8px 28px 18px",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    boxShadow: "none !important",
    rowGap: 10,
    width: "100%",
    marginTop: 30,
  },

  subtitle: {
    fontSize: 14,
    color: "#000000",
    fontWeight: 500,
  },
}));
const People = () => {
  const { brandId } = useParams();
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState();
  const [emails, setEmails] = useState([]);
  const [peopleList, setPeopleList] = useState([]);
  const [invitedPeopleList, setInvitedPeopleList] = useState([]);
  const [inActiveUsersEmails, setinActiveUsersEmails] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("author");
  const [loadingPeople, setLoadingPeople] = useState(false);
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const [openInvitePeople, setOpenInvitePeople] = useState(false);

  const filteredData = useMemo(() => {
    return searchQuery
      ? peopleList.filter(
          (item) =>
            item.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.first_name
              ?.toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            item.last_name?.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : peopleList;
  }, [peopleList, searchQuery]);

  const handleAddPerson = () => {
    setLoading(true);
    const values = {
      emails: emails,
      isAdmin: isAdmin,
      invited_by: loggedInUser.id,
    };
    endpoints.brands
      .addUsers(brandId, values)
      .then((response) => {
        setPeopleList(response.data.users);
        setInvitedPeopleList(response.data.invitations);
        setinActiveUsersEmails(response.data.inactive_user_emails);
        setLoading(false);
        setEmails([]);
        setButtonDisabled(true);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const refetch = () => {
    setLoadingPeople(true);
    endpoints.brands
      .getBrand(brandId)
      .then((response) => {
        setLoadingPeople(false);
        setInvitedPeopleList(response.data.invitations);
        setinActiveUsersEmails(response.data.inactive_user_emails);
        setPeopleList(response.data.users);
      })
      .catch((err) => {
        setLoadingPeople(false);
      });
  };
  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [brandId]);

  return (
    <WorkspacePageTemplate
      title="People"
      navigateTo={`/workspace-settings/${brandId}`}
    >
      <Divider sx={{ width: "100%", borderColor: "border.tertiary" }} />
      <Typography
        style={{
          fontSize: 14,
          fontWeight: 450,
          lineHeight: "20px",
          marginTop: 10,
        }}
        color="text.tertiary"
      >
        Invite People
      </Typography>
      <Card
        className={classes.card}
        sx={{ backgroundColor: "background.secondary" }}
      >
        <Typography
          variant="h3"
          sx={{ fontSize: 14, fontWeight: 500, lineHeight: "20px" }}
          color="text.primary"
        >
          Invite people to your workspace by email
        </Typography>
        <Typography
          variant="h3"
          sx={{ fontSize: 14, fontWeight: 400, lineHeight: "20px" }}
          color="text.secondary"
        >
          Separate multiple emails with commas.{" "}
        </Typography>
        <InvitePeople
          emails={emails}
          setEmails={setEmails}
          setDropdownValue={setDropdownValue}
          dropdownValue={dropdownValue}
          setIsAdmin={setIsAdmin}
          setButtonDisabled={setButtonDisabled}
        />

        <div style={{ display: "flex", flexDirection: "row" }}>
          <AddMoreButton
            disabled={buttonDisabled}
            showIcon={false}
            title={
              loading ? (
                <CircularProgress
                  style={{
                    width: 20,
                    height: 20,
                    color: "#2D333A",
                  }}
                />
              ) : (
                "Send Invite"
              )
            }
            onClick={() => handleAddPerson()}
          />
        </div>
      </Card>
      <Divider
        sx={{ width: "100%", marginTop: 10, borderColor: "border.tertiary" }}
      />
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 20,
          marginBottom: 60,
        }}
      >
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 450,
            lineHeight: "20px",
          }}
          color="text.tertiary"
        >
          All People
        </Typography>
        <SearchInput
          placeholder="Search people..."
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </Box>
      {loadingPeople ? (
        <div
          style={{
            position: "absolute",
            top: "95%",
            left: "60%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{
              width: 20,
              height: 20,
              color: "#2D333A",
            }}
          />
        </div>
      ) : (
        <AllPeopleList
          peopleList={filteredData}
          refetch={refetch}
          invitedUsers={invitedPeopleList}
          inActiveUserEmails={inActiveUsersEmails}
        />
      )}
    </WorkspacePageTemplate>
  );
};
export default People;
