import { Typography, useTheme } from "@mui/material";
import { getSentimentColors } from "../../../../../AEO/utils/DataUtils";

const ScoreMovementTypography = ({
  score,
  movement,
  padding = 0,
  borderRadius = "6px",
  backgroundColor,
  color,
  movementBackgroundColor,
  movementColor,
  isPercentage,
}) => {
  const theme = useTheme();
  let movementAvailable = movement !== "N/A";
  return (
    <Typography
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        columnGap: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: backgroundColor || getSentimentColors(score, theme)[0],
        color: color || getSentimentColors(score, theme)[1],
        fontSize: 28,
        fontWeight: 600,
        borderRadius: borderRadius,
        padding: padding,
      }}
    >
      {Number(score).toLocaleString()}
      {movementAvailable && (
        <Typography
          sx={{
            color:
              movementColor || color || getSentimentColors(score, theme)[1],
            backgroundColor:
              movementBackgroundColor ||
              backgroundColor ||
              getSentimentColors(score, theme)[0],
            padding: "0px 4px",
            borderRadius: "7px",
            fontSize: "14px",
          }}
        >
          {movement > 0
            ? `+${movement?.toLocaleString()}`
            : movement == -0
            ? 0
            : movement?.toLocaleString()}
          {isPercentage && " %"}
        </Typography>
      )}
    </Typography>
  );
};
export default ScoreMovementTypography;
