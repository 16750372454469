import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { endpoints } from "../../services/api/endpoints";
import { useUser } from "../../contexts/UserContext";

const Accounts = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { updateUser } = useUser();

  useEffect(() => {
    localStorage.setItem("userId", userId);
    endpoints.auth
      .getUserData(userId)
      .then((response) => {
        const userData = response.data;
        const userId = userData.id;

        updateUser(userData);
        localStorage.setItem("userId", userId);

        if (userData.brands?.length > 0) {
          const brandId = userData.brands[0].id;
          navigate(`/dashboard/${brandId}/`);
        } else if (userData.pending_invites?.length > 0) {
          navigate(`/accounts/${userId}/pending-invites`);
        } else {
          navigate("/workspace-setup");
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [userId]);
  return;
};
export default Accounts;
