import { Typography } from "@mui/material";

const TypographyTableCell = ({ value }) => {
  return (
    <Typography
      style={{
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "20px",
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {value}
    </Typography>
  );
};
export default TypographyTableCell;
