import AverageLineCard from "../../../AEO/Pages/Dashboard/Components/AverageLineCard/AverageLineCard";
import VisibilityIcon from "../../../../CommonComponents/customIcons/VisibilityIcon";
import SentimentIcon from "../../../../CommonComponents/customIcons/SentimentIcon";
import BrandVisibilityScoreCard from "../../../AEO/Pages/Dashboard/Components/BrandVisibilityScoreCard/BrandVisibilityScoreCard";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, useTheme } from "@mui/material";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import dayjs from "dayjs";
import AnimationLoader from "../../../../CommonComponents/AnimationLoader/AnimationLoader";
import { endpoints } from "../../../../services/api/endpoints";
import { getLatestWeekRange } from "../../../AEO/utils/DataUtils";

const AEOCharts = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState();

  const { brandId } = useParams();
  const {
    setDate,
    setInitialDate,
    setNoData: setGlobalNoData,
  } = useGlobalFilters();

  useEffect(() => {
    setLoading(true);
    endpoints.aeo
      .getAvailableDates(brandId)
      .then((response) => {
        setNoData(false);
        setLoading(false);
        const data = response.data[0];
        const dates = getLatestWeekRange(data.start_date, data.end_date);
        setDate(dates);
        setInitialDate([dayjs(data.start_date), dayjs(data.end_date)]);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.data) {
          if (
            error.response.data.detail ===
            "Database error: 404: No evaluations found for the given brand ID"
          ) {
            setNoData(true);
          } else {
            console.log("An error occurred:", error);
          }
        } else {
          console.log("An unexpected error occurred:", error);
        }
      });
  }, [brandId, setDate, setInitialDate]);

  useEffect(() => {
    setGlobalNoData(noData);
  }, [noData, setGlobalNoData]);

  return loading ? (
    <AnimationLoader text="Loading analysis..." />
  ) : (
    <Grid
      container
      direction="row"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 5,
      }}
    >
      <Grid item xs={3.8}>
        <AverageLineCard
          icon={<VisibilityIcon color={theme.palette.text.primary} />}
          title="Brand Visibility"
          tooltipPrimary="Shows the brand visibility over the last 24 hours, calculated across ChatGPT, Gemini and Perplexity."
          tooltipSecondary="Scores are out of 100."
          index={0}
          widget_type="Visibility"
          navigateTo={`/ai-visibility/${brandId}/visibility-analysis`}
        />
      </Grid>
      <Grid item xs={3.8}>
        <AverageLineCard
          icon={<SentimentIcon color={theme.palette.text.primary} />}
          title="Current Sentiment Analysis"
          tooltipPrimary="Shows the LLM's sentiment towards your brand over the last 24 hours, calculated across ChatGPT, Gemini, and Perplexity. Higher scores reflect positive sentiment, lower scores indicate negative sentiment."
          tooltipSecondary="Scores are out of 100."
          index={1}
          widget_type="Sentiment"
          navigateTo={`/ai-visibility/${brandId}/sentiment-analysis`}
        />
      </Grid>
      <Grid item xs={3.8}>
        <BrandVisibilityScoreCard
          navigateTo={`/ai-visibility/${brandId}/visibility-analysis`}
        />
      </Grid>
    </Grid>
  );
};
export default AEOCharts;
