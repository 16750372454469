import { Typography } from "@mui/material";
import FormikTextField from "../../../../CommonComponents/FormField/components/TextField/TextField";
import PasswordField from "../../../../CommonComponents/FormField/components/PasswordField";

const CredentialsSection = ({ showPasswordTextfield }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: 15 }}>
      <div style={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
        <Typography style={{ fontSize: 15 }} color="text.primary">
          Email
        </Typography>
        <FormikTextField
          name="email"
          placeholder="user@nogood.io"
          margin={false}
        />
      </div>
      {showPasswordTextfield && <PasswordField />}
    </div>
  );
};
export default CredentialsSection;
