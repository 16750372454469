import ChartCard from "../../../../components/ChartCard/ChartCard";
import LLMGridRanking from "../LLMGridRanking/LLMGridRanking";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Divider, useTheme } from "@mui/material";
import VisibilityIcon from "../../../../../../CommonComponents/customIcons/VisibilityIcon";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import { dummyLLMRankingData } from "../../../../consts/consts";
import { endpoints } from "../../../../../../services/api/endpoints";

const BrandVisibilityScoreCard = ({ navigateTo }) => {
  const [visibilityScores, setVisibilityScores] = useState([]);
  const { brandId } = useParams();
  const { noData } = useGlobalFilters();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    const values = {
      brandId,
      comparsion_type: "LLMs",
    };

    endpoints.aeo
      .getBrandVisibilityScoreRank(values)
      .then((response) => {
        const data = response.data;
        setVisibilityScores(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching market performance data:", error);
        setError(true);
        setLoading(false);
      });
  }, [brandId]);
  const theme = useTheme();

  return (
    <ChartCard
      icon={<VisibilityIcon color={theme.palette.text.primary} />}
      title="Brand Visibility Score"
      tooltipMessagePrimary="Displays your brand’s rank across individual LLMs. Higher scores indicate a stronger position compared to competitors."
      tooltipMessageSecondary="All scores are out of 100"
      height={240}
      isLoading={loading}
      isError={error}
      navigateTo={navigateTo || undefined}
    >
      <Divider
        sx={{
          height: "1px",
          borderColor: "border.quaternary",
          borderBottomWidth: "1px",
          margin: "12px 0px",
        }}
      />
      <LLMGridRanking
        rankings={noData ? dummyLLMRankingData : visibilityScores}
      />
    </ChartCard>
  );
};

export default BrandVisibilityScoreCard;
