/* eslint-disable */
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useTheme } from "@mui/styles";
import { Typography } from "@mui/material";
import AnimationLoader from "../AnimationLoader/AnimationLoader";
import SearchInput from "../SearchInput";
import FilterPanel from "./components/FilterPanel/FilterPanel";

/**
 * BaseTable component that serves as a foundation for table components across the application
 * @param {Object} props - Component props
 * @param {Array} props.columns - Array of column definitions
 * @param {Function} props.fetchData - Function to fetch data with pagination, sorting, filtering
 * @param {Array} props.initialSortModel - Initial sort model for the table
 * @param {Object} props.initialPaginationModel - Initial pagination model
 * @param {String} props.searchPlaceholder - Placeholder text for the search input
 * @param {Boolean} props.hasFiltering - Whether the table should have filtering capabilities
 * @param {Boolean} props.hasSearch - Whether the table should have search functionality
 * @param {Boolean} props.isServerSide - Whether data operations are handled server-side (pagination, sorting, filtering)
 * @param {Array} props.initialData - Initial data to display (for client-side tables)
 * @param {Boolean} props.loading - Whether the table is loading
 * @param {Function} props.searchHandler - Custom search handler function
 * @param {Array} props.filterFields - Available fields for filtering
 */
const BaseTable = ({
  columns,
  fetchData,
  initialSortModel = [{ field: "created_at", sort: "desc" }],
  initialPaginationModel = { page: 0, pageSize: 20 },
  searchPlaceholder = "Search",
  hasFiltering = true,
  hasSearch = true,
  isServerSide = true,
  initialData = [],
  loading: externalLoading,
  searchHandler,
  filterFields,
}) => {
  const [sortModel, setSortModel] = useState(initialSortModel);
  const [paginationModel, setPaginationModel] = useState(
    initialPaginationModel
  );
  const [rows, setRows] = useState(initialData);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(externalLoading || false);
  const [searchQuery, setSearchQuery] = useState();
  const [activeFilters, setActiveFilters] = useState([]);
  const theme = useTheme();

  const handleApplyFilters = (filters) => {
    setPaginationModel({
      ...paginationModel,
      page: 0,
    });
    setActiveFilters(filters);
  };

  useEffect(() => {
    if (searchQuery !== undefined && (searchHandler || isServerSide)) {
      setPaginationModel({
        ...paginationModel,
        page: 0,
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (isServerSide && fetchData) {
      const handleServerOperation = async () => {
        setLoading(true);
        try {
          const response = await fetchData({
            paginationModel,
            sortModel,
            searchQuery,
            activeFilters,
          });

          if (response) {
            setRows(response.data || []);
            setTotalCount(response.count || 0);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };

      handleServerOperation();
    }
  }, [
    isServerSide,
    fetchData,
    paginationModel,
    sortModel,
    searchQuery,
    activeFilters,
  ]);

  const CustomLoadingOverlay = () => (
    <div
      style={{
        backgroundColor: "rgba(0,0, 0, 0.1)",
        backdropFilter: "blur(2px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        zIndex: 1,
      }}
    >
      <AnimationLoader />
    </div>
  );

  const CustomNoRowsOverlay = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Typography>No data available</Typography>
    </div>
  );

  return (
    <Box sx={{ width: "100%", marginTop: 4 }}>
      {(hasSearch || hasFiltering) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: 4,
          }}
        >
          {hasSearch && (
            <Box sx={{ alignSelf: "flex-start" }}>
              <SearchInput
                placeholder={searchPlaceholder}
                searchQuery={searchQuery}
                setSearchQuery={
                  searchQuery !== undefined ? setSearchQuery : searchHandler
                }
                width="268px"
              />
            </Box>
          )}
          {hasFiltering && filterFields && (
            <Box>
              <FilterPanel
                onApplyFilters={handleApplyFilters}
                filterFields={filterFields}
              />
            </Box>
          )}
        </Box>
      )}

      <Box
        sx={{
          maxHeight: 700,
          height: "auto",
          width: "100%",
          display: "flex",
          "& .MuiDataGrid-root": {
            border: "none",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            display: "flex",
            flexDirection: "column",
          },
          "& .MuiDataGrid-main": {
            overflow: "auto",
            flexGrow: 1,
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "auto !important",
            maxHeight: "calc(700px - 120px) !important",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            position: "sticky",
            bottom: 0,
            backgroundColor: theme.palette.background.secondary,
            zIndex: 2,
          },
          "& .MuiDataGrid-cell": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          },
          "& .MuiDataGrid-row": {
            alignItems: "center",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: theme.palette.background.primary,
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: theme.palette.background.tertiary,
            height: "48px !important",
            padding: "0 16px",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "14px",
            fontWeight: 500,
          },
          "& .MuiDataGrid-columnHeaders": {
            minHeight: "48px !important",
            maxHeight: "48px !important",
          },
          "& .MuiDataGrid-columnHeader:hover": {
            backgroundColor: theme.palette.background.tertiaryhover,
          },
          "& .MuiDataGrid-sortIcon": {
            color: theme.palette.text.tertiary,
          },
          ".MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnSeparator:hover": {
            color: theme.palette.text.tertiary,
          },
          "& .MuiDataGrid-columnSeparator--resizing": {
            color: theme.palette.text.tertiaryhover,
          },
          ".MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            display: "none",
          },
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
            {
              display: "none",
            },
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              "& .MuiDataGrid-cell:last-child": {
                paddingRight: "16px",
              },
            },
          },

          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows":
            {
              color: theme.palette.text.secondary,
            },
          "& .MuiTablePagination-select": {
            color: theme.palette.text.primary,
          },
          "& .MuiTablePagination-actions": {
            color: theme.palette.text.primary,
          },
          "& .MuiIconButton-root.Mui-disabled": {
            color: theme.palette.text.disabled,
          },
          "& .MuiSelect-icon": {
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-menuIconButton": {
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-iconButtonContainer": {
            color: theme.palette.text.primary,
          },
        }}
      >
        <DataGridPremium
          rows={rows}
          columns={columns}
          loading={loading || externalLoading}
          rowCount={totalCount}
          rowHeight={60}
          pageSizeOptions={[20, 50, 100]}
          paginationMode={isServerSide ? "server" : "client"}
          sortingMode={isServerSide ? "server" : "client"}
          filterMode={isServerSide ? "server" : "client"}
          pagination
          density="compact"
          paginationModel={paginationModel}
          onPaginationModelChange={(model) => {
            setPaginationModel({
              page: model.page,
              pageSize: model.pageSize,
            });
          }}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          disableColumnMenu
          slots={{
            loadingOverlay: CustomLoadingOverlay,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={{
            pagination: {
              SelectProps: {
                MenuProps: {
                  sx: {
                    "& .MuiPaper-root": {
                      backgroundColor: theme.palette.background.primary,
                    },
                  },
                },
              },
            },
          }}
          sx={{
            "--DataGrid-containerBackground": "transparent",
            "--DataGrid-rowBorderColor": theme.palette.background.tertiary,
            "& .MuiDataGrid-row:hover .should-optimize-typography": {
              backgroundColor: theme.palette.background.quaternary,
              borderRadius: "30px",
              padding: "5px 8px",
              maxWidth: "fit-content",
            },
            "& .MuiDataGrid-cell": {
              display: "flex",
              alignItems: "center",
            },

            "& .MuiDataGrid-sortIcon": {
              color: theme.palette.text.tertiary,
            },
            ".MuiDataGrid-columnHeader:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-columnSeparator:hover": {
              color: theme.palette.text.tertiary,
            },
            "& .MuiDataGrid-columnSeparator--resizing": {
              color: theme.palette.text.tertiaryhover,
            },
            ".MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              display: "none",
            },
            "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
              {
                display: "none",
              },
            "& .MuiDataGrid-virtualScrollerRenderZone": {
              "& .MuiDataGrid-row": {
                "& .MuiDataGrid-cell:last-child": {
                  paddingRight: "16px",
                },
              },
            },

            "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows":
              {
                color: theme.palette.text.secondary,
              },
            "& .MuiTablePagination-select": {
              color: theme.palette.text.primary,
            },
            "& .MuiTablePagination-actions": {
              color: theme.palette.text.primary,
            },
            "& .MuiIconButton-root.Mui-disabled": {
              color: theme.palette.text.disabled,
            },
            "& .MuiSelect-icon": {
              color: theme.palette.text.primary,
            },
            "& .MuiDataGrid-menuIconButton": {
              color: theme.palette.text.primary,
            },
            "& .MuiDataGrid-iconButtonContainer": {
              color: theme.palette.text.primary,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default BaseTable;
