import { Button, CircularProgress, useTheme } from "@mui/material";

const ChipButton = ({
  label,
  iconBefore,
  iconAfter,
  onClick,
  backgroundColor,
  textColor,
  borderColor,
  hoverBackgroundColor,
  hoverTextColor,
  hoverBorderColor,
  padding = "10px 20px",
  fontSize = "16px",
  fontWeight = 600,
  columnGap = 1,
  fullWidth = false,
  borderRadius = "25px",
  disabled,
  isLoading,
  width = "auto",
}) => {
  const theme = useTheme();
  return (
    <Button
      sx={{
        backgroundColor: `${
          disabled ? "background.disabled" : backgroundColor
        } !important`,
        color: `${
          disabled ? theme.palette.text.disabled : textColor
        } !important`,
        padding: `${padding} !important`,
        fontSize: `${fontSize} !important`,
        borderRadius: `${borderRadius} !important`,
        height: "fit-content !important",
        border: `1px solid ${
          disabled ? "background.disabled" : borderColor
        } !important`,
        fontWeight: `${fontWeight} !important`,
        display: "flex !important",
        alignItems: "center",
        flexDirection: "row",
        textTransform: "none",
        columnGap: columnGap,
        width: `${width} !important`,
        "&:hover": {
          backgroundColor: `${hoverBackgroundColor} !important`,
          color: `${hoverTextColor} !important`,
          borderColor: `${hoverBorderColor} !important`,
        },
      }}
      onClick={onClick}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{
              width: 20,
              height: 20,
              color: textColor || theme.palette.text.chiptext
            }}
          />
        </div>
      ) : (
        <>
          {iconBefore}
          {label}
          {iconAfter}
        </>
      )}
    </Button>
  );
};
export default ChipButton;
