import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import googleLogo from "../../../..../../../../../assets/images/GoogleLogo.webp";
import googleAnalytics from "../../../..../../../../../assets/images/Google-analytics.png";
import googleSearchConsole from "../../../..../../../../../assets/images/Google-search-console.png";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const scope = "https://www.googleapis.com/auth/analytics.readonly";

const connectorsPlatforms = [
  // {
  //   type: "Google Search Console",
  //   image: googleSearchConsole,
  //   callbackfunction: `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${process.env.REACT_APP_API_GATEWAY_URL}/brands/connector/google-callback&response_type=code&scope=profile email&`,
  //   logo: googleLogo,
  // },
  {
    type: "Google Analytics",
    image: googleAnalytics,
    callbackfunction: `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${process.env.REACT_APP_API_GATEWAY_URL}/goodie/brands/connector/google-callback&response_type=code& &access_type=offline&prompt=consent&scope=${scope}&`,
    logo: googleLogo,
  },
];
const AddConnector = ({ connectedAccounts }) => {
  const { brandId } = useParams();
  const isPlatformConnected = useMemo(() => {
    return (platform) =>
      connectedAccounts.some((account) => account.type === platform.type);
  }, [connectedAccounts]);

  const handleOnClick = (platform) => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    const fullUrl = window.location.href;
    const baseUrl =
      new URL(fullUrl).origin +
      `/workspace-settings/${brandId}/connect-accounts`;

    const values = {
      brandId: brandId,
      addedBy: loggedInUser.id,
      type: platform.type,
      redirectTo: baseUrl,
    };

    const state = encodeURIComponent(JSON.stringify(values));

    window.location.href = platform.callbackfunction + `state=${state}`;
  };
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: "40px 40px 0px 40px",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Typography
        variant="h4"
        sx={{ mt: { xs: 2, md: 1 }, mb: { xs: 2, md: 5 }, fontWeight: 500 }}
        color="text.primary"
      >
        Connect Accounts
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: "14px",
          fontWeight: 450,
          maxWidth: "596px",
          mt: -4,
        }}
        color="text.secondary"
      >
        Choose the accounts you'd like to link to your workspace.
      </Typography>
      <Divider
        sx={{
          width: "100%",
          borderColor: "border.tertiary",
          pb: { xs: 1, md: 6 },
          mb: { xs: 1, md: 2 },
        }}
      />
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 450,
          marginTop: 5,
        }}
        color="content.tertiary"
      >
        All Accounts
      </Typography>
      <Grid container sx={{ marginTop: 4 }}>
        {connectorsPlatforms.map((platform) => (
          <Grid item xs={4} key={platform.type}>
            <Card
              sx={{
                maxWidth: 332,
                border: "1px solid",
                borderColor: "border.tertiary",
                borderRadius: "8px",
                boxShadow: "none",
              }}
            >
              <CardMedia
                component="img"
                alt={platform.type}
                height="156"
                image={platform.image}
              />
              <Divider sx={{ borderColor: "border.tertiary" }} />
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  backgroundColor: "background.secondary",
                  borderRadius: "0px 0px 8px 8px",
                }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    columnGap: 1,
                  }}
                >
                  <img src={platform.logo} width={25} height={25} />{" "}
                  {platform.type}
                </Typography>
                <Typography
                  sx={{
                    padding: "5px 8px",
                    borderRadius: 30,
                    backgroundColor: "background.brand20",
                    fontSize: 12,
                    color: isPlatformConnected(platform)
                      ? theme.palette.text.brandbold
                      : theme.palette.text.primary,
                    cursor: isPlatformConnected(platform)
                      ? "default"
                      : "pointer",
                  }}
                  onClick={() => {
                    !isPlatformConnected(platform) && handleOnClick(platform);
                  }}
                >
                  {isPlatformConnected(platform) ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <CheckCircleOutlineRoundedIcon style={{ fontSize: 18 }} />
                      Connected{" "}
                    </div>
                  ) : (
                    "Connect"
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AddConnector;
