import DNAStep from "../Step/Step";
import CompetitionForm from "./Form/CompetitionForm";
const Competition = ({ brandDetails }) => {
  return (
    <DNAStep
      title="Competition"
      subtitle="List your key competitors to provide Goodie with context for your market. By understanding what others are doing, Goodie can help you create content that stands out, giving you a competitive edge while being more specific to your brand’s positioning."
      children={<CompetitionForm brandDetails={brandDetails} />}
      required
    />
  );
};
export default Competition;
