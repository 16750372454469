import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { endpoints } from "../../../../services/api/endpoints";

const useMicrosoftAuth = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const hasRun = useRef(false);

  const handleMicrosoftLogin = async () => {
    if (hasRun.current) return null;
    hasRun.current = true;

    await instance.initialize();
    const response = await instance.handleRedirectPromise();

    if (response?.account) {
      try {
        const tokenResponse = await instance.acquireTokenSilent({
          scopes: ["openid", "profile", "email", "User.Read"],
          account: response.account,
        });

        const backendResponse = await endpoints.auth.microsoftLogin(
          tokenResponse.accessToken
        );

        const { user_id, access_token, refresh_token } = backendResponse.data;
        return {
          user_id,
          accessToken: access_token,
          refreshToken: refresh_token,
        };
      } catch (error) {
        console.error("Microsoft Auth Error:", error);
        navigate("/error");
      }
    }
    return null;
  };

  return { handleMicrosoftLogin };
};

export default useMicrosoftAuth;
