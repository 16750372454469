/* eslint-disable */
import React, { useState, useCallback, useMemo } from "react";
import { useTheme } from "@mui/styles";
import {
  Box,
  Typography,
  Tooltip,
  LinearProgress,
  Divider,
} from "@mui/material";
import ChartCard from "../../../../../AEO/components/ChartCard/ChartCard";
import BaseTable from "../../../../../../CommonComponents/BaseTable/BaseTable";
import { createHeaderCellWithTooltip } from "../../../../../../CommonComponents/BaseTable/utils/headerUtils";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// Initialize dayjs plugins
dayjs.extend(relativeTime);

// Component to display URL with external link icon
const PagePathCell = ({ path }) => {
  const theme = useTheme();

  // Ensure URL has proper format for opening
  let url = path;
  if (url && !/^https?:\/\//i.test(url)) {
    url = `https://${url}`;
  }

  const displayPath = path.replace(/^https?:\/\//i, "");

  return (
    <Tooltip title={`Open external website: ${url}`}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
          overflow: "hidden",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        onClick={() => {
          if (url) window.open(url, "_blank", "noopener,noreferrer");
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            color: theme.palette.text.primary,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {displayPath}
        </Typography>
        <Box
          component="span"
          sx={{
            display: "inline-flex",
            marginLeft: "4px",
            color: theme.palette.text.tertiary,
            "& svg": {
              width: "12px",
              height: "12px",
            },
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M10 6v2H5v11h11v-5h2v6a1 1 0 01-1 1H4a1 1 0 01-1-1V7a1 1 0 011-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z" />
          </svg>
        </Box>
      </Box>
    </Tooltip>
  );
};

// Component to display a progress bar for crawl visits
const CrawlVisitsBar = ({ value, max }) => {
  const theme = useTheme();
  const percentage = Math.min((value / max) * 100, 100);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1, width: "100%" }}>
      <Typography
        sx={{
          fontSize: "14px",
          color: theme.palette.text.primary,
        }}
      >
        {value}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={percentage}
        sx={{
          height: 8,
          borderRadius: 4,
          width: "100%",
          backgroundColor: theme.palette.background.tertiary,
          "& .MuiLinearProgress-bar": {
            borderRadius: 4,
            backgroundColor: theme.palette.background.brand,
          },
        }}
      />
    </Box>
  );
};

const PageCrawlTable = ({ initialDate }) => {
  const theme = useTheme();
  const {
    date: globalDate,
    initialDate: globalInitialDate,
    llm: globalLLM,
  } = useGlobalFilters();
  const [date, setDate] = useState(globalDate);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedLLMs, setSelectedLLMs] = useState([
    "ChatGPT",
    "Gemini",
    "Perplexity",
  ]);

  // Columns definition
  const columns = useMemo(
    () => [
      {
        field: "pagePath",
        headerName: "Page",
        flex: 2,
        minWidth: 250,
        renderHeader: () =>
          createHeaderCellWithTooltip(
            "Page",
            "The URL path of the page crawled by AI agents",
            theme
          ),
        renderCell: (params) => <PagePathCell path={params.value} />,
        sortable: false,
      },
      {
        field: "totalCrawlVisits",
        headerName: "Total Crawl Visits",
        flex: 1.5,
        minWidth: 150,
        renderHeader: () =>
          createHeaderCellWithTooltip(
            "Total Crawl Visits",
            "Number of times AI crawlers have visited this page",
            theme
          ),
        renderCell: (params) => (
          <CrawlVisitsBar
            value={params.value}
            max={params.row.maxCrawlVisits}
          />
        ),
        sortable: true,
      },
      {
        field: "responseTime",
        headerName: "Response Time",
        flex: 1,
        minWidth: 130,
        renderHeader: () =>
          createHeaderCellWithTooltip(
            "Response Time",
            "Average response time in milliseconds for AI crawler requests",
            theme
          ),
        renderCell: (params) => (
          <Typography
            sx={{
              fontSize: "14px",
              color: theme.palette.text.primary,
            }}
          >
            {params.value}ms
          </Typography>
        ),
        sortable: true,
      },
      {
        field: "errorRate",
        headerName: "Error Rate",
        flex: 1,
        minWidth: 120,
        renderHeader: () =>
          createHeaderCellWithTooltip(
            "Error Rate",
            "Percentage of crawler requests resulting in errors (4xx, 5xx)",
            theme
          ),
        renderCell: (params) => (
          <Typography
            sx={{
              fontSize: "14px",
              color: theme.palette.text.primary,
            }}
          >
            {params.value}%
          </Typography>
        ),
        sortable: true,
      },
      {
        field: "lastCrawled",
        headerName: "Last Crawled",
        flex: 1,
        minWidth: 130,
        renderHeader: () =>
          createHeaderCellWithTooltip(
            "Last Crawled",
            "Time elapsed since the page was last visited by an AI crawler",
            theme
          ),
        renderCell: (params) => (
          <Typography
            sx={{
              fontSize: "14px",
              color: theme.palette.text.primary,
            }}
          >
            {params.value}
          </Typography>
        ),
        sortable: true,
      },
    ],
    [theme]
  );

  // Mock data array (will be replaced with API call)
  const mockData = [
    {
      id: 1,
      pagePath: "/blog/ai-trends-2024",
      totalCrawlVisits: 554,
      maxCrawlVisits: 554,
      responseTime: 180,
      errorRate: 2,
      lastCrawled: "2h ago",
    },
    {
      id: 2,
      pagePath: "/product/goodie",
      totalCrawlVisits: 444,
      maxCrawlVisits: 554,
      responseTime: 162,
      errorRate: 2,
      lastCrawled: "2h ago",
    },
    {
      id: 3,
      pagePath: "/docs/api-reference",
      totalCrawlVisits: 300,
      maxCrawlVisits: 554,
      responseTime: 245,
      errorRate: 2,
      lastCrawled: "3h ago",
    },
    {
      id: 4,
      pagePath: "/blog/ai-trends-2025",
      totalCrawlVisits: 254,
      maxCrawlVisits: 554,
      responseTime: 180,
      errorRate: 2,
      lastCrawled: "3h ago",
    },
    {
      id: 5,
      pagePath: "/product/goodie/pricing",
      totalCrawlVisits: 120,
      maxCrawlVisits: 554,
      responseTime: 245,
      errorRate: 2,
      lastCrawled: "3h ago",
    },
    {
      id: 6,
      pagePath: "/features/analytics",
      totalCrawlVisits: 12,
      maxCrawlVisits: 554,
      responseTime: 55,
      errorRate: 2,
      lastCrawled: "4h ago",
    },
    {
      id: 7,
      pagePath: "/company/about",
      totalCrawlVisits: 98,
      maxCrawlVisits: 554,
      responseTime: 110,
      errorRate: 1,
      lastCrawled: "5h ago",
    },
    {
      id: 8,
      pagePath: "/blog/seo-best-practices",
      totalCrawlVisits: 210,
      maxCrawlVisits: 554,
      responseTime: 195,
      errorRate: 0,
      lastCrawled: "6h ago",
    },
    {
      id: 9,
      pagePath: "/features/integrations",
      totalCrawlVisits: 175,
      maxCrawlVisits: 554,
      responseTime: 150,
      errorRate: 3,
      lastCrawled: "8h ago",
    },
    {
      id: 10,
      pagePath: "/docs/getting-started",
      totalCrawlVisits: 320,
      maxCrawlVisits: 554,
      responseTime: 175,
      errorRate: 1,
      lastCrawled: "10h ago",
    },
    {
      id: 11,
      pagePath: "/product/goodie/enterprise",
      totalCrawlVisits: 88,
      maxCrawlVisits: 554,
      responseTime: 220,
      errorRate: 5,
      lastCrawled: "12h ago",
    },
    {
      id: 12,
      pagePath: "/support/faq",
      totalCrawlVisits: 143,
      maxCrawlVisits: 554,
      responseTime: 130,
      errorRate: 0,
      lastCrawled: "18h ago",
    },
    {
      id: 13,
      pagePath: "/legal/privacy-policy",
      totalCrawlVisits: 65,
      maxCrawlVisits: 554,
      responseTime: 90,
      errorRate: 0,
      lastCrawled: "1d ago",
    },
    {
      id: 14,
      pagePath: "/blog/content-strategy",
      totalCrawlVisits: 201,
      maxCrawlVisits: 554,
      responseTime: 205,
      errorRate: 3,
      lastCrawled: "1d ago",
    },
    {
      id: 15,
      pagePath: "/product/goodie/features",
      totalCrawlVisits: 189,
      maxCrawlVisits: 554,
      responseTime: 170,
      errorRate: 1,
      lastCrawled: "1d ago",
    },
    {
      id: 16,
      pagePath: "/features/dashboards",
      totalCrawlVisits: 122,
      maxCrawlVisits: 554,
      responseTime: 165,
      errorRate: 2,
      lastCrawled: "2d ago",
    },
    {
      id: 17,
      pagePath: "/docs/advanced/configuration",
      totalCrawlVisits: 78,
      maxCrawlVisits: 554,
      responseTime: 230,
      errorRate: 4,
      lastCrawled: "2d ago",
    },
    {
      id: 18,
      pagePath: "/case-studies/enterprise",
      totalCrawlVisits: 105,
      maxCrawlVisits: 554,
      responseTime: 190,
      errorRate: 1,
      lastCrawled: "3d ago",
    },
    {
      id: 19,
      pagePath: "/blog/marketing-tips",
      totalCrawlVisits: 167,
      maxCrawlVisits: 554,
      responseTime: 175,
      errorRate: 2,
      lastCrawled: "3d ago",
    },
    {
      id: 20,
      pagePath: "/product/goodie/demo",
      totalCrawlVisits: 255,
      maxCrawlVisits: 554,
      responseTime: 200,
      errorRate: 0,
      lastCrawled: "4d ago",
    },
  ];

  // Filter fields for the filter panel
  const filterFields = [
    { value: "pagePath", label: "Page Path", type: "text" },
    { value: "totalCrawlVisits", label: "Total Crawl Visits", type: "number" },
    { value: "responseTime", label: "Response Time", type: "number" },
    { value: "errorRate", label: "Error Rate", type: "number" },
    { value: "lastCrawled", label: "Last Crawled", type: "text" },
  ];

  // Fetch data function - would be connected to an API in production
  const fetchData = useCallback(
    async ({ paginationModel, sortModel, searchQuery, activeFilters }) => {
      setLoading(true);
      setErrorMessage(null);

      try {
        // Simulate API delay
        await new Promise((resolve) => setTimeout(resolve, 500));

        // In real implementation, this would be an API call like:
        /*
        const dateMin = date && date[0] ? date[0].format("YYYY-MM-DD") : null;
        const dateMax = date && date[1] ? date[1].format("YYYY-MM-DD") : null;

        const response = await endpoints.agentAnalytics.getPageCrawlAnalytics({
          brandId: selectedBrand,
          start_date: dateMin,
          end_date: dateMax,
          llms: selectedLLMs, // Pass selected LLMs to API
          page: paginationModel.page + 1,
          page_size: paginationModel.pageSize,
          ordering: ordering,
          search: searchQuery,
          ...filterParams
        });
        
        return {
          data: response.data.results,
          count: response.data.count,
        };
        */

        // Filter mockData based on search query
        let filteredData = [...mockData];

        if (selectedLLMs.length > 0 && selectedLLMs.length < 8) {
          const filterRatio = selectedLLMs.length / 8;
          const dataCount = Math.max(
            5,
            Math.floor(filteredData.length * filterRatio)
          );
          filteredData = filteredData.slice(0, dataCount);
        }

        if (searchQuery) {
          const lowerQuery = searchQuery.toLowerCase();
          filteredData = filteredData.filter((item) =>
            item.pagePath.toLowerCase().includes(lowerQuery)
          );
        }

        // Apply active filters
        if (activeFilters && activeFilters.length > 0) {
          filteredData = filteredData.filter((item) => {
            return activeFilters.every((filter) => {
              const { field, operator, value } = filter;

              switch (operator) {
                case "contains":
                  return String(item[field])
                    .toLowerCase()
                    .includes(value.toLowerCase());
                case "equals":
                  return String(item[field]) === String(value);
                case "startswith":
                  return String(item[field])
                    .toLowerCase()
                    .startsWith(value.toLowerCase());
                case "gte":
                  return Number(item[field]) >= Number(value);
                case "lte":
                  return Number(item[field]) <= Number(value);
                default:
                  return true;
              }
            });
          });
        }

        // Apply sorting
        if (sortModel && sortModel.length > 0) {
          const { field, sort } = sortModel[0];
          filteredData.sort((a, b) => {
            const multiplier = sort === "asc" ? 1 : -1;
            if (typeof a[field] === "number") {
              return (a[field] - b[field]) * multiplier;
            }
            return (
              String(a[field]).localeCompare(String(b[field])) * multiplier
            );
          });
        }

        // Apply pagination
        const start = paginationModel.page * paginationModel.pageSize;
        const paginatedData = filteredData.slice(
          start,
          start + paginationModel.pageSize
        );

        return {
          data: paginatedData,
          count: filteredData.length,
        };
      } catch (error) {
        console.error("Error fetching page crawl data:", error);
        setErrorMessage(error.message || "Failed to fetch page crawl data");
        return {
          data: [],
          count: 0,
        };
      } finally {
        setLoading(false);
      }
    },
    [date, selectedLLMs]
  );

  return (
    <Box>
      <Divider
        sx={{
          height: "0.5px",
          mb: 2,
          borderColor: theme.palette.border.tertiary,
        }}
      />
      <Typography
        variant="body1"
        sx={{
          my: 2,
          color: theme.palette.text.tertiary,
        }}
      >
        Page Crawl Stats
      </Typography>
      <ChartCard
        title="Crawls by Page"
        tooltipMessagePrimary="Detailed analysis of AI crawler activity at the page level to assess indexing and technical performance."
        description="Monitor AI crawler activity at the page level to assess indexing and technical performance."
        height="100%"
        hasCalendarSelect
        hasLLMselect
        llmIsMultipleSelect
        selectedLLM={selectedLLMs}
        setSelectedLLM={setSelectedLLMs}
        date={date}
        setDate={setDate}
        initialDate={initialDate || globalInitialDate}
      >
        <BaseTable
          columns={columns}
          fetchData={fetchData}
          initialSortModel={[{ field: "totalCrawlVisits", sort: "desc" }]}
          initialPaginationModel={{ page: 0, pageSize: 20 }}
          searchPlaceholder="Search by page path"
          hasFiltering={true}
          hasSearch={true}
          isServerSide={true}
          filterFields={filterFields}
          loading={loading}
        />
      </ChartCard>
    </Box>
  );
};

export default PageCrawlTable;
