import { Box, Typography, useTheme } from "@mui/material";
import LineChart from "../../../../../../CommonComponents/Charts/LineChart";
import { dummyLineData } from "../../../../consts/consts";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import TextWithmovement from "../../../../components/TextWithMovement/TextWithMovement";

const OverallLineChartCard = ({
  data,
  mainArea = "score",
  isIncrease,
  index,
}) => {
  const theme = useTheme();
  const { Score, Movement } = data;
  const { noData } = useGlobalFilters();
  let mainAreaColor =
    isIncrease || noData
      ? theme.palette.text.positive
      : theme.palette.text.negative;

  return (
    <Box style={{ display: "flex", flexDirection: "column", rowGap: 0 }}>
      <TextWithmovement
        score={Score}
        movement={Movement}
        noData={noData}
        isIncrease={isIncrease}
      />
      <LineChart
        data={noData ? dummyLineData : data?.Line_Chart}
        mainArea={noData ? "NoGood" : mainArea}
        mainAreaColor={mainAreaColor}
        index={index}
        height={100}
        isMainSelected
      />
      <Typography
        style={{ fontSize: 12, fontWeight: 400, marginTop: 6 }}
        color="text.tertiary"
      >
        Displaying data from all time
      </Typography>
    </Box>
  );
};
export default OverallLineChartCard;
