/**
 * Converts filter objects to API query parameters
 * Handles various filter types and operators according to the backend API format
 */
export const convertFiltersToQueryParams = (filters) => {
  const params = {};

  filters.forEach((filter) => {
    const { field, operator, value } = filter;

    // Handle different operators based on field type
    switch (operator) {
      case "contains":
        params[field] = value;
        break;
      case "exact":
        params[`${field}_exact`] = value;
        break;
      case "startswith":
        params[`${field}_startswith`] = value;
        break;
      case "equals":
        // For choice fields or exact numeric matches
        params[field] = value;
        break;
      case "gt":
        params[`${field}__gt`] = value;
        break;
      case "lt":
        params[`${field}__lt`] = value;
        break;
      case "gte":
        params[`${field}__gte`] = value;
        break;
      case "lte":
        params[`${field}__lte`] = value;
        break;
      case "after":
        // For date fields (created_after)
        params[`created_after`] = value;
        break;
      case "before":
        // For date fields (created_before)
        params[`created_before`] = value;
        break;
      default:
        // Default to exact match
        params[field] = value;
    }
  });

  return params;
};

/**
 * Special mapping for number fields that should use min/max suffix
 * instead of the default operator syntax
 *
 * Based on Django backend's filter naming convention:
 * - influence_score_min (for greater than/equal)
 * - influence_score_max (for less than/equal)
 * And similar patterns for other numeric fields
 */
export const specialFieldMappings = {
  influence_score: {
    gte: "influence_score_min",
    lte: "influence_score_max",
    equals: "influence_score",
  },
  impact_score: {
    gte: "impact_score_min",
    lte: "impact_score_max",
    equals: "impact_score",
  },
  brand_mentions: {
    gte: "brand_mentions_min",
    lte: "brand_mentions_max",
    equals: "brand_mentions",
  },
};

/**
 * Convert filters to query parameters with special handling for certain fields
 * This matches the backend's filter parameter naming convention
 */
export const convertFiltersToParams = (filters) => {
  const params = {};

  filters.forEach((filter) => {
    const { field, operator, value } = filter;

    // Check if this field has special mappings (for numeric fields)
    if (specialFieldMappings[field] && specialFieldMappings[field][operator]) {
      // Use the special field mapping
      params[specialFieldMappings[field][operator]] = value;
    } else {
      // Handle standard operators
      switch (operator) {
        case "contains":
          params[field] = value;
          break;
        case "exact":
          params[`${field}_exact`] = value;
          break;
        case "startswith":
          params[`${field}_startswith`] = value;
          break;
        case "equals":
          params[field] = value;
          break;
        case "gte":
          params[`${field}__gte`] = value;
          break;
        case "lte":
          params[`${field}__lte`] = value;
          break;
        case "after":
          params["created_after"] = value;
          break;
        case "before":
          params["created_before"] = value;
          break;
        default:
          params[field] = value;
      }
    }
  });

  return params;
};
