import {
  Box,
  Card,
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WorkspacePageTemplate from "../PageTemplate/PageTemplate";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ChipButton from "../../../../CommonComponents/ChipButton";
import { endpoints } from "../../../../services/api/endpoints";

const GeneralSettings = () => {
  const { brandId } = useParams();
  const [image, setImage] = useState();
  const [uploadedImage, setUploadedImage] = useState();
  const [imageChanged, setImagedChanged] = useState();
  const [name, setName] = useState();
  const [loading, setLoading] = useState();
  const [isSaving, setIsSaving] = useState();
  const theme = useTheme();

  const refetch = () => {
    setUploadedImage("");
    setLoading(true);
    endpoints.brands
      .getOverview(brandId)
      .then((response) => {
        const data = response.data;
        setName(data.name);
        setImage(data.image);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    refetch();
  }, [brandId]);

  const handleFileChange = (event) => {
    setImagedChanged(true);
    const file = event.target.files[0];
    setImage(file);
    const reader = new FileReader();

    reader.onload = () => {
      setUploadedImage(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleSave = () => {
    const values = imageChanged ? { image: image, name: name } : { name: name };
    setIsSaving(true);
    endpoints.brands
      .update(brandId, values, true)
      .then((response) => {
        setIsSaving(false);
        refetch();
      })
      .catch((err) => {
        setIsSaving(false);
      });
  };

  return (
    <WorkspacePageTemplate title="General Settings">
      <Divider
        sx={{
          width: "100%",
          pb: { xs: 1, md: 2 },
          mb: { xs: 1, md: 3 },
          borderColor: "border.tertiary",
        }}
      />
      <Typography
        style={{
          fontSize: 14,
          fontWeight: 450,
          marginTop: 10,
        }}
        color="text.tertiary"
      >
        Customize Workspace
      </Typography>

      <Box style={{ marginBottom: 22, marginTop: 52 }}>
        <Card
          style={{
            borderRadius: "10px",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
            boxShadow: "none",
            rowGap: 10,
            marginTop: 40,
            backgroundColor: theme.palette.background.secondary,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "44px",
              mb: "1px",
            }}
            color="text.primary"
          >
            Workspace Name
          </Typography>

          <TextField
            sx={{
              width: "30%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: `1px solid ${theme.palette.border.secondary}`,
                  borderRadius: "11px",
                },
                "&:hover fieldset": {
                  border: "1px solid",
                  borderColor: theme.palette.border.secondary,
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid",
                  borderColor: theme.palette.border.secondary,
                },
              },
            }}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder="Type here"
          />
        </Card>

        <Card
          style={{
            borderRadius: "10px",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
            boxShadow: "none",
            rowGap: 10,
            marginTop: 40,
            backgroundColor: theme.palette.background.secondary,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "44px",
              mb: "1px",
            }}
            color="text.primary"
          >
            Workspace Profile Image
          </Typography>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={1}>
              {image || uploadedImage ? (
                <img
                  src={uploadedImage ? uploadedImage : image}
                  alt="Workspace"
                  width={60}
                  height={60}
                  style={{ borderRadius: 8 }}
                />
              ) : (
                <Typography
                  style={{
                    borderRadius: 8,
                    backgroundColor: theme.palette.background.primary,
                    width: 60,
                    height: 60,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: 500,
                    fontSize: 20,
                  }}
                  color="text.primary"
                >
                  {name?.charAt(0).toUpperCase()}
                </Typography>
              )}
            </Grid>
            <Grid item xs={11}>
              <Box
                sx={{
                  border: "1px solid",
                  borderColor: "border.tertiary",
                  borderRadius: 2,
                  padding: 3,
                  textAlign: "center",
                }}
              >
                <FileUploadOutlinedIcon sx={{ fontSize: 25 }} />
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png,.svg"
                  style={{ display: "none" }}
                  id="upload-file"
                  onChange={handleFileChange}
                />
                <label htmlFor="upload-file" style={{ cursor: "pointer" }}>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: 500 }}
                    color="text.secondary"
                  >
                    <span
                      style={{
                        color: theme.palette.text.brand,
                        marginRight: "2px",
                      }}
                    >
                      Click to upload
                    </span>
                    or drag and drop
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      mt: 1,
                    }}
                    color="text.secondary"
                  >
                    SVG, PNG, JPG (max. 800x800px)
                  </Typography>
                </label>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <div
        style={{
          display: "inline-flex",
          whiteSpace: "nowrap",
        }}
      >
        {" "}
        <ChipButton
          label="Save Changes"
          backgroundColor={theme.palette.background.brand}
          textColor={theme.palette.text.chiptext}
          hoverBackgroundColor={theme.palette.background.brandhover}
          hoverTextColor={theme.palette.text.chiptext}
          hoverBorderColor={theme.palette.background.brandhover}
          fullWidth
          onClick={() => handleSave()}
          isLoading={isSaving}
        />
      </div>
    </WorkspacePageTemplate>
  );
};

export default GeneralSettings;
