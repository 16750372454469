import { Box, Typography, useTheme } from "@mui/material";
import SentimentSatisfiedAltSharpIcon from "@mui/icons-material/SentimentSatisfiedAltSharp";
import SentimentDissatisfiedSharpIcon from "@mui/icons-material/SentimentDissatisfiedSharp";

const normalizeScore = (score) =>
  typeof score === "string" ? score.toLowerCase() : score;

const getColors = (score, theme) => {
  const normalizedScore = normalizeScore(score);

  if (normalizedScore === "positive" || normalizedScore >= 80) {
    return [theme.palette.background.positive, theme.palette.text.positive];
  } else if (normalizedScore === "neutral" || normalizedScore >= 50) {
    return [theme.palette.background.neutral, theme.palette.text.neutral];
  } else if (normalizedScore === "negative" || normalizedScore < 50) {
    return [theme.palette.background.negative, theme.palette.text.negative];
  } else {
    return [theme.palette.background.brand20, theme.palette.text.brandbold];
  }
};

const getIcon = (score) => {
  const normalizedScore = normalizeScore(score);
  if (normalizedScore === "") return null;

  if (normalizedScore === "positive" || normalizedScore >= 80) {
    return <SentimentSatisfiedAltSharpIcon fontSize="small" />;
  } else if (normalizedScore === "neutral" || normalizedScore >= 50) {
    return <SentimentSatisfiedAltSharpIcon fontSize="small" />;
  } else if (normalizedScore === "negative" || normalizedScore < 50) {
    return <SentimentDissatisfiedSharpIcon fontSize="small" />;
  }

  return null;
};

const SentimentTableCell = ({ score }) => {
  const theme = useTheme();
  const normalizedScore = normalizeScore(score);
  const [backgroundColor, textColor] = getColors(normalizedScore, theme);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Typography
        style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          maxWidth: "100%",
          backgroundColor,
          color: textColor,
          borderRadius: "30px",
          padding: "5px 18px",
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        {getIcon(normalizedScore)}
        {score ? score : "Not Recognized"}
      </Typography>
    </Box>
  );
};

export default SentimentTableCell;
