import axios from "axios";

const BASE_URL =
  process.env.REACT_APP_API_GATEWAY_URL || "http://localhost:8000/";

// Create axios instance with default config
export const apiClient = axios.create({
  baseURL: BASE_URL,
  timeout: 60000 * 5, // revist, vercel content generation takes along time
});

export const tokenService = {
  getAccessToken: () => localStorage.getItem("accessToken"),
  getRefreshToken: () => localStorage.getItem("refreshToken"),
  setTokens: (accessToken, refreshToken) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  },
  clearTokens: () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  },
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

apiClient.interceptors.request.use(
  (config) => {
    const token = tokenService.getAccessToken();
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status !== 401 || originalRequest._retry) {
      return Promise.reject(error);
    }

    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return apiClient(originalRequest);
        })
        .catch((err) => Promise.reject(err));
    }

    originalRequest._retry = true;
    isRefreshing = true;
    try {
      const refresh = tokenService.getRefreshToken();

      const refreshClient = axios.create({
        baseURL: BASE_URL,
        timeout: 60000 * 5,
      });

      const response = await refreshClient.post(
        "goodie/accounts/token/refresh/",
        {
          refresh,
        },
        {
          headers: {
            Authorization: `Bearer ${refresh}`,
          },
        }
      );

      const { access: newAccessToken, refresh: newRefreshToken } =
        response.data;
      tokenService.setTokens(newAccessToken, newRefreshToken);

      apiClient.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
      originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

      processQueue(null, newAccessToken);
      isRefreshing = false;

      return apiClient(originalRequest);
    } catch (refreshError) {
      processQueue(refreshError, null);
      isRefreshing = false;
      tokenService.clearTokens();
      // call logout as well ?
      window.location.href = "/login";
      return Promise.reject(refreshError);
    }
  }
);
