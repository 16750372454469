import { Box, useTheme } from "@mui/material";
import StarIcon from "../../../../../../../../CommonComponents/customIcons/StarIcon";
import ChartCard from "../../../../../../../AEO/components/ChartCard/ChartCard";
import TextWithmovement from "../../../../../../../AEO/components/TextWithMovement/TextWithMovement";

const InsightsCard = ({
  title,
  description,
  tooltipMessagePrimary,
  tooltipMessageSecondary,
  tooltipMessageTertiary,
  loading,
  error,
  movement,
  isIncrease,
}) => {
  const theme = useTheme();
  return (
    <ChartCard
      icon={<StarIcon color={theme.palette.text.primary} />}
      title={title}
      tooltipMessagePrimary={tooltipMessagePrimary}
      tooltipMessageSecondary={tooltipMessageSecondary}
      tooltipMessageTertiary={tooltipMessageTertiary}
      description={description}
      height={144}
      isLoading={loading}
      isError={error}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box sx={{ marginTop: "auto" }}>
          <TextWithmovement
            movement={movement}
            isIncrease={isIncrease}
            percentageValue
          />
        </Box>
      </Box>
    </ChartCard>
  );
};
export default InsightsCard;
