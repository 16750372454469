import { Card, Typography, Box, useTheme } from "@mui/material";
import BoxWithStatus from "../../../../CommonComponents/BoxWithStatus";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate, useParams } from "react-router-dom";

const AdmissAccessRequired = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {children}
      <Typography
        sx={{
          backgroundColor: "background.tertiary",
          color: "text.secondary",
          padding: "2px 12px",
          borderRadius: "25px",
          fontSize: 14,
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          columnGap: 1,
        }}
      >
        <LockOutlinedIcon style={{ fontSize: 15 }} />
        Admin access only
      </Typography>
    </Box>
  );
};

const WorkSpaceCard = ({ card, onClick }) => {
  const {
    icon,
    title,
    description,
    isIncomplete,
    navigateTo,
    requiresAdminAccess,
    adminAccess,
  } = card;
  const theme = useTheme();
  const navigate = useNavigate();
  const { brandId } = useParams();

  const renderStatus = () => {
    if (requiresAdminAccess && !adminAccess) {
      return <AdmissAccessRequired>{icon}</AdmissAccessRequired>;
    } else if (isIncomplete) {
      return <BoxWithStatus isIncomplete={isIncomplete}>{icon}</BoxWithStatus>;
    }

    return (
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          width: "40px",
          height: "40px",
          backgroundColor: "background.tertiary",
          borderRadius: "50%",
        }}
      >
        {icon}
      </Box>
    );
  };

  const disabled = requiresAdminAccess && !adminAccess;
  return (
    <Card
      style={{
        borderRadius: "12px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        cursor: disabled ? "not-allowed" : "pointer",
        border: `1px solid ${theme.palette.background.secondary}`,
        position: "relative",
        height: "188px",
        boxShadow: "none",
        backgroundColor: theme.palette.background.secondary,
      }}
      sx={{
        "&:hover": {
          backgroundColor: disabled
            ? "inherit"
            : `${theme.palette.background.secondaryhover} !important`,
          border: disabled
            ? `1px solid ${theme.palette.border.tertiary} !important`
            : `1px solid ${theme.palette.border.tertiary} !important`,
        },
      }}
      onClick={() => {
        if (!disabled) {
          if (onClick && title === "Brand DNA") {
            onClick();
          } else {
            navigate(`/workspace-settings/${brandId}/${navigateTo}`);
          }
        }
      }}
    >
      {disabled && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
            borderRadius: "12px",
          }}
        />
      )}
      {renderStatus()}
      <Typography
        variant="body"
        sx={{
          fontSize: 24,
          mt: { xs: 1, md: 4 },
          fontWeight: 500,
          lineHeight: "32px",
          letterSpacing: "-0.48px",
        }}
        color="text.primary"
      >
        {title}
      </Typography>
      <Typography style={{ fontSize: 14 }} color="text.secondary">
        {description}
      </Typography>
    </Card>
  );
};
export default WorkSpaceCard;
