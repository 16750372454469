import { createContext, useCallback, useContext, useState } from "react";
import { tokenService } from "../services/api/client";
import { useBrand } from "./BrandContext";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { clearWorkspaceSelection } = useBrand();
  const [loggedInUser, setLoggedInUser] = useState(() => {
    const stored = localStorage.getItem("loggedInUser");
    return stored ? JSON.parse(stored) : {};
  });
  const updateUser = (userData) => {
    setLoggedInUser(userData);
    localStorage.setItem("loggedInUser", JSON.stringify(userData));
  };
  const logout = useCallback(async () => {
    try {
      // logout endpoint if exists
    } finally {
      tokenService.clearTokens();
      updateUser({});
      localStorage.clear("workspace");
      clearWorkspaceSelection();
    }
  }, []);
  return (
    <UserContext.Provider value={{ loggedInUser, updateUser, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
