import {
  Avatar,
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useUser } from "../../../../contexts/UserContext";
import { useBrand } from "../../../../contexts/BrandContext";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import MainLogoIcon from "../../../../CommonComponents/customIcons/MainLogoIcon";

const WorkspaceSelection = () => {
  const theme = useTheme();
  const { loggedInUser, logout } = useUser();
  const { clearWorkspaceSelection, selectBrand } = useBrand();
  const navigate = useNavigate();

  const handleSelectBrand = async (brandId) => {
    try {
      const selectedBrandData = loggedInUser.brands.find(
        (brand) => brand.id === brandId
      );

      if (!selectedBrandData) {
        throw new Error("Invalid brand selection");
      }
      await selectBrand(selectedBrandData.id);

      const targetPath = `/dashboard/${brandId}`;
      navigate(targetPath, { replace: true });
    } catch (error) {}
  };

  const handleLogout = () => {
    logout();
    clearWorkspaceSelection();
    navigate(`/login`);
  };
  return (
    <Box
      sx={{
        minHeight: "100vh",
        minWidth: "100vw",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(298.96deg, #718CF9 -20.88%, #262626 106.53%)",
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.background.secondary,
          borderRadius: { xs: 0, sm: "20px" },
          p: 2,
          height: { xs: "100vh", sm: "95vh" },
          width: "100%",
          zIndex: 1,
          maxWidth: { xs: "100%", sm: "90%", md: "55%" },
          maxHeight: { xs: "100%", sm: "800px" },
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.text.tertiary,
            borderRadius: "4px",
            maxHeight: "80%",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: theme.palette.text.tertiary,
          },
        }}
      >
        <Stack alignItems="center" justifyContent="center" minHeight="100%">
          <MainLogoIcon />
          <Typography
            variant="h2"
            align="center"
            sx={{
              color: "common.white",
              fontWeight: 500,
              mt: 3,
              mb: 2,
            }}
          >
            Welcome back!
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{
              color: "grey.400",
              mb: 3,
            }}
          >
            Select the workspace that you'd like to open.
          </Typography>
          <Stack
            sx={{
              pr: 1,
              maxHeight: "50vh",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.text.tertiary,
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: theme.palette.text.tertiary,
              },
            }}
            alignItems="center"
            width="42%"
            spacing={2}
          >
            {loggedInUser.brands.map((workspace) => (
              <Button
                disableRipple
                sx={{
                  textTransform: "none",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  px: 2,
                  py: "10px",
                  columnGap: 1,
                  borderRadius: "11px",
                  color: theme.palette.text.primary,
                  width: "100%",
                  borderColor: theme.palette.border.secondary,
                  "&:hover": {
                    borderColor: "border.tertiary",
                    backgroundColor: "transparent",
                    "& .rightIcon": {
                      opacity: 1,
                    },
                  },
                  "&.Mui-focused": {
                    borderColor: "border.tertiary",
                  },
                  "& .rightIcon": {
                    opacity: 0,
                    marginLeft: "auto",
                    transition: theme.transitions.create("opacity", {
                      duration: theme.transitions.duration.shorter,
                    }),
                  },
                }}
                variant="outlined"
                startIcon={
                  <Avatar
                    variant="rounded"
                    src={workspace.image}
                    alt={workspace.name}
                    style={{ width: "27px", height: "27px" }}
                  ></Avatar>
                }
                key={workspace.id}
                onClick={() => handleSelectBrand(workspace.id)}
              >
                <Typography
                  variant="body1"
                  sx={{ textAlign: "left", flexGrow: 0 }}
                >
                  {workspace.name}
                </Typography>
                <ArrowForward className="rightIcon" sx={{ fontSize: 20 }} />
              </Button>
            ))}
          </Stack>
          <Stack spacing={1} alignItems="center" sx={{ mt: 2 }}>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.text.secondary }}
            >
              Want to create a new workspace?{" "}
              <Button
                variant="text"
                onClick={() => {
                  navigate("/workspace-setup");
                }}
                sx={{
                  m: 0,
                  p: 0,
                  color: theme.palette.text.brandbold,
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                  },
                }}
              >
                Create workspace
              </Button>
            </Typography>
            <Button
              variant="text"
              sx={{
                color: theme.palette.text.brandbold,
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}
              onClick={() => handleLogout()}
            >
              <Typography variant="body1"> Back to log in</Typography>
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
export default WorkspaceSelection;
