import { Box, Button, Typography, useTheme } from "@mui/material";
import Wrapper from "../Wrapper/Wrapper";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import AccountsCard from "../Card/Card";
import EastIcon from "@mui/icons-material/East";
import CheckIcon from "../../../../CommonComponents/customIcons/CheckIcon";

const ConfirmResetPassword = () => {
  return (
    <Wrapper isfullLength>
      <AccountsCard
        icon={<CheckIcon />}
        title="Password reset"
        backToLoginButton
        description="Your new password has been successfully reset."
        rowGap={20}
      />
    </Wrapper>
  );
};
export default ConfirmResetPassword;
