import { Button, Card, Typography, useTheme } from "@mui/material";
import ErrorTypography from "../../../../CommonComponents/FormField/components/ErrorMessage/components/ErrorTypography/ErrorTypography";
import ChipButton from "../../../../CommonComponents/ChipButton";
import { useUser } from "../../../../contexts/UserContext";
import { useBrand } from "../../../../contexts/BrandContext";
import { useNavigate } from "react-router-dom";

const AccountsCard = ({
  title,
  children,
  onButtonClick,
  isLoading,
  icon,
  error,
  description,
  backToLoginButton,
  buttonIcon,
  buttonLabel,
  isButtonDisabled,
  rowGap = 20,
}) => {
  const theme = useTheme();
  const { logout } = useUser();
  const { clearWorkspaceSelection } = useBrand();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    clearWorkspaceSelection();
    navigate(`/login`);
  };
  return (
    <Card
      style={{
        borderRadius: "35px",
        padding: "50px 85px 70px 85px",
        boxShadow: "none",
        display: "flex",
        flexDirection: "column",
        width: 600,
        rowGap: rowGap,
        backgroundColor: "transparent",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          rowGap: 16,
        }}
      >
        {icon && icon}
        <Typography
          style={{
            fontSize: 40,
            color: theme.palette.text.primary,
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        {description && (
          <Typography
            style={{
              fontSize: 14,
              color: theme.palette.text.secondary,
              fontWeight: 400,
              maxWidth: 345,
            }}
          >
            {description}
          </Typography>
        )}
      </div>

      {children}
      {error && <ErrorTypography errorMessage={error} />}

      {buttonLabel && (
        <ChipButton
          iconAfter={buttonIcon}
          label={buttonLabel}
          backgroundColor={theme.palette.background.brand}
          textColor={theme.palette.text.chiptext}
          hoverBackgroundColor={theme.palette.text.brandbold}
          hoverTextColor={theme.palette.text.chiptext}
          hoverBorderColor={theme.palette.text.brandbold}
          fullWidth
          onClick={onButtonClick}
          isLoading={isLoading}
          disabled={isButtonDisabled}
        />
      )}
      {backToLoginButton && (
        <Button
          variant="text"
          sx={{
            color: theme.palette.text.brandbold,
            textTransform: "none",
            "&:hover": {
              backgroundColor: "transparent",
              textDecoration: "underline",
            },
          }}
          onClick={() => handleLogout()}
        >
          <Typography variant="body1" style={{ fontSize: "14px" }}>
            {" "}
            Back to log in
          </Typography>
        </Button>
      )}
    </Card>
  );
};
export default AccountsCard;
