import { Button } from "@mui/material";
import React from "react";
import googleLogo from "../../../../assets/images/GoogleLogo.webp";

const GoogleLoginButton = () => {
  const handleLogin = () => {
    const fullUrl = window.location.href;
    const baseUrl = new URL(fullUrl).origin + "/";
    const redirectTo = encodeURIComponent(baseUrl);
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${process.env.REACT_APP_API_GATEWAY_URL}/goodie/accounts/auth/google/callback/&response_type=code&scope=profile email&state=${redirectTo}`;
  };

  return (
    <Button
      onClick={handleLogin}
      sx={{
        fontFamily: "Roboto",
        textTransform: "none",
        display: "flex",
        flexDirection: "row",
        columnGap: 1,
        borderRadius: "11px",
        color: "text.tertiary",
        fontSize: 20,
        borderColor: "border.tertiary",
        "&:hover": {
          borderColor: "border.tertiary",
          backgroundColor: "transparent",
        },
        "&.Mui-focused": {
          borderColor: "border.tertiary",
        },
      }}
      fullWidth
      variant="outlined"
      startIcon={
        <img src={googleLogo} alt="google logo" width={30} height={30} />
      }
    >
      Continue with Google
    </Button>
  );
};

export default GoogleLoginButton;
