import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import InsightsCard from "./Components/InsightsCard/InsightsCard";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AnimationLoader from "../../../../../../CommonComponents/AnimationLoader/AnimationLoader";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import { endpoints } from "../../../../../../services/api/endpoints";

const isPositive = (num) => num > 0;

const InsightsSection = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState();
  const { brandId } = useParams();
  const [data, setData] = useState([]);

  const { date: globalDate } = useGlobalFilters();
  const [date, setDate] = useState(globalDate);

  useEffect(() => {
    if (date.length > 0 && date[0] && date[1]) {
      setLoading(true);
      endpoints.agentAnalytics
        .getAgentAnalyticsInsighits({
          brandId,
          start_date: date[0].format("YYYY-MM-DD"),
          end_date: date[1].format("YYYY-MM-DD"),
        })
        .then((response) => {
          setLoading(false);
          setData(response.data);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching user data:", error);
        });
    }
  }, [brandId, date]);

  useEffect(() => {
    setDate(globalDate);
  }, [globalDate]);

  return loading ? (
    <AnimationLoader text="Generating chart..." width={90} textFontSize={17} />
  ) : (
    data.length > 0 && (
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
        <Divider
          sx={{
            height: "0.5px",
            mt: 2,
            mb: 2,
            borderColor: "border.tertiary",
          }}
        />
        <Typography
          variant="h4"
          style={{
            fontWeight: 450,
            color: theme.palette.text.tertiary,
            fontSize: 14,
            marginBottom: 15,
          }}
        >
          Insights{" "}
        </Typography>
        <Grid container spacing={3}>
          {data?.map((insight) => (
            <Grid item xs={6}>
              <InsightsCard
                title={insight?.title}
                description={insight?.description}
                movement={insight?.movement}
                isIncrease={isPositive(insight?.movement)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  );
};
export default InsightsSection;
