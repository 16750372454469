import { Grid, useTheme } from "@mui/material";
import PageLayout from "../../components/PageLayout/PageLayout";
import SentimentSatisfiedAltOutlinedIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import AverageLineCard from "./Components/AverageLineCard/AverageLineCard";
import BrandVisibilityScoreCard from "./Components/BrandVisibilityScoreCard/BrandVisibilityScoreCard";
import LineChartCard from "../../components/LineChartCard/LineChartCard";
import RadarChartCard from "../../components/RadarChartCard/RadarChartCard";
import RankingWheelsCard from "../../components/RankingWheelsCard/RankingWheelsCard";
import StackedBarCard from "../../components/StackedBarCard/StackedBarCard";
import { GlobalFiltersProvider } from "../../../../contexts/GlobalFiltersContext";
import VisibilityIcon from "../../../../CommonComponents/customIcons/VisibilityIcon";
import SentimentIcon from "../../../../CommonComponents/customIcons/SentimentIcon";

const radarData = [
  {
    benchmark: "Brand Recognition",
    Addidas: 120,
    Nike: 110,
    Puma: 150,
  },
  {
    benchmark: "Market Share",
    Addidas: 98,
    Nike: 130,
    Puma: 150,
  },
  {
    benchmark: "Product Quality",
    Addidas: 86,
    Nike: 130,
    Puma: 150,
  },
  {
    benchmark: "Innovation",
    Addidas: 99,
    Nike: 100,
    Puma: 150,
  },
  {
    benchmark: "Sustainability",
    Addidas: 85,
    Nike: 90,
    Puma: 150,
  },
  {
    benchmark: "Endorsements",
    Addidas: 65,
    Nike: 85,
    Puma: 150,
  },
];

const Dashboard = () => {
  const theme = useTheme();
  return (
    <GlobalFiltersProvider>
      <PageLayout
        title="AI Visibility Dashboard"
        description="Access in-depth metrics, trend analysis, and side-by-side comparisons, along with global and localized insights, to make data-driven decisions and optimize your brand strategy."
        hasPromptCounter
      >
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={3.8}>
            <AverageLineCard
              icon={<VisibilityIcon color={theme.palette.text.primary} />}
              title="Brand Visibility"
              tooltipPrimary="Shows your brand’s visibility over the last 24 hours, calculated across ChatGPT, Gemini, and Perplexity."
              tooltipSecondary="Scores are out of 100."
              index={0}
              widget_type="Visibility"
            />
          </Grid>
          <Grid item xs={3.8}>
            <AverageLineCard
              icon={<SentimentIcon color={theme.palette.text.primary} />}
              title="Current Sentiment Analysis"
              tooltipPrimary="Shows LLM sentiment toward your brand over the last 24 hours, calculated across ChatGPT, Gemini, and Perplexity. Higher scores reflect positive sentiment, while lower scores indicate negative sentiment."
              tooltipSecondary="Scores are out of 100."
              index={1}
              widget_type="Sentiment"
            />
          </Grid>{" "}
          <Grid item xs={3.8}>
            <BrandVisibilityScoreCard />
          </Grid>{" "}
        </Grid>

        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={7.9}>
            <LineChartCard
              icon={<VisibilityIcon color={theme.palette.text.primary} />}
              title="Brand Visibility"
              tooltipPrimary="Track your brand’s visibility score over time across selected LLMs."
              hasCaledarSelect
              hasLLMselect
              height={345}
              chartHasXasix
              chartHasYaxis
              chartHeight={235}
              chartHasTooltip
              chartHasMarginTop={20}
              chartHasMarginLeft={-20}
              chartHasGrid
              isLLMComparison
              isMainSelected
            />
          </Grid>
          <Grid item xs={3.8}>
            <RankingWheelsCard
              icon={<VisibilityIcon color={theme.palette.text.primary} />}
              title="Visibility Ranking"
              tooltipPrimary="Displays your brand’s rank against competitors for the selected LLM. Higher scores indicate a stronger position compared to competitors."
              tooltipSecondary="Scores are out of 100."
              hasLLMselect
              height={345}
              hasRankingTypography
              wheelsGrid={12}
              isVertical
              comparsion_type="Competitors"
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={5.85}>
            <RadarChartCard
              title="Competitive Analysis"
              tooltipPrimary="Compares your brand to competitors across key industry criteria."
              icon={<LayersOutlinedIcon style={{ width: 15, height: 15 }} />}
              data={radarData}
              hasLLMselect
              height={560}
            />
          </Grid>
          <Grid item xs={5.85}>
            <StackedBarCard
              icon={
                <SentimentSatisfiedAltOutlinedIcon
                  style={{ width: 15, height: 15 }}
                />
              }
              title="Side-By-Side Sentiment Analysis"
              tooltipPrimary="Compares the sentiment distribution (positive, neutral, and negative) across brands."
              tooltipSecondary="All score add up to 100."
              hasLLMselect
              height={560}
              chartWidth={470}
              chartHeight={480}
              isVertical
              reverseTooltip="true"
            />
          </Grid>{" "}
        </Grid>
        <LineChartCard
          icon={<VisibilityIcon color={theme.palette.text.primary} />}
          title="Competitive Visibility Analysis"
          tooltipPrimary="Track your brand’s visibility over time across selected competitors and LLMs. Use the dropdown to select your preferred LLM."
          tooltipSecondary="Higher scores indicate stronger brand visibility."
          hasCaledarSelect
          hasLLMselect
          height={"100%"}
          chartHasXasix
          chartHasYaxis
          chartHeight={200}
          chartHasTooltip
          chartHasMarginTop={20}
          chartHasMarginLeft={-20}
          chartHasGrid
        />
      </PageLayout>
    </GlobalFiltersProvider>
  );
};
export default Dashboard;
