import { Box, Typography, useTheme } from "@mui/material";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import TrendingDownOutlinedIcon from "@mui/icons-material/TrendingDownOutlined";

const TextWithmovement = ({
  noData,
  score,
  movement,
  isIncrease,
  percentageValue = true,
}) => {
  const theme = useTheme();
  let backgroundColor =
    isIncrease || noData
      ? theme.palette.background.positive
      : theme.palette.background.negative;

  let mainAreaColor =
    isIncrease || noData
      ? theme.palette.text.positive
      : theme.palette.text.negative;

  let noMovementAvailable = movement === "N/A";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: 10,
        alignItems: "center",
        height: "100%",
      }}
    >
      {(score || score === 0) && (
        <Typography style={{ fontSize: 28, fontWeight: 450, lineHeight: 1 }}>
          {noData
            ? "1.8"
            : typeof score === "number" && !isNaN(score)
            ? Math.round(score).toLocaleString()
            : score}
        </Typography>
      )}
      {(movement || movement === 0) && (
        <Box
          style={{
            color: noMovementAvailable
              ? theme.palette.text.brandbold
              : mainAreaColor,
            backgroundColor: noMovementAvailable
              ? theme.palette.background.brand20
              : backgroundColor,
            borderRadius: 5,
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            columnGap: 2,
            fontSize: 14,
            padding: "2px 4px",
            height: "24px",
          }}
        >
          {noMovementAvailable ? (
            "Pending"
          ) : (
            <>
              {isIncrease ? (
                <TrendingUpOutlinedIcon style={{ width: 16, height: 16 }} />
              ) : (
                <TrendingDownOutlinedIcon style={{ width: 16, height: 16 }} />
              )}
              {noData ? "1.8" : movement && Math.round(movement)}{" "}
              {percentageValue && "%"}{" "}
            </>
          )}
        </Box>
      )}
    </div>
  );
};
export default TextWithmovement;
