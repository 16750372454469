import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Snackbar,
  useTheme,
  GlobalStyles,
  Avatar,
} from "@mui/material";
import PageTemplate from "../../../../CommonComponents/PageTemplate/PageTemplate";
import { useParams, useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";

import { useBlog } from "../../../../contexts/blogContext";
import TextEditor from "../TextEditor/TextEditor";
import { format } from "date-fns";
import { endpoints } from "../../../../services/api/endpoints";

const FinalBlog = () => {
  const { title, blog_id } = useParams();
  const [open, setOpen] = useState(false);
  const { editedBlog = "", setEditedBlog } = useBlog();
  const location = useLocation();
  const author = location.state?.author;
  const [createdDate, setCreatedDate] = useState("");
  const [sanitizedContent, setSanitizedContent] = useState("");

  const sanitizeContent = (content) => {
    return content.replace(/<p><br><\/p>/g, "").trim();
  };

  useEffect(() => {
    if (editedBlog) {
      const cleanedContent = sanitizeContent(editedBlog);
      setSanitizedContent(cleanedContent);
      setEditedBlog(cleanedContent);
    }
  }, []);

  useEffect(() => {
    endpoints.content
      .getRecentBlog(blog_id)
      .then((response) => {
        const { created_at } = response.data;
        setCreatedDate(new Date(created_at).toLocaleDateString());
        setCreatedDate(new Date(created_at));
      })
      .catch((error) => {
        console.error("Error fetching blog details:", error);
      });
  }, [blog_id]);

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  const formattedAuthorName = author
    ? `${author.first_name || author.email || "Unknown"} ${
        author.last_name ? author.last_name.charAt(0) + "." : ""
      }`.trim()
    : "Author information not available";

  return (
    <>
      <PageTemplate>
        <GlobalStyles
          styles={{
            ".ql-toolbar": {
              backgroundColor: theme.palette.background.secondary,
            },
            ".ql-container": {
              backgroundColor: theme.palette.background.secondary,
            },
            ".ql-editor": {
              backgroundColor: theme.palette.background.secondary,
            },
            ".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded ": {
              backgroundColor: theme.palette.background.secondary,
            },
          }}
        />
        <Typography
          variant="h2"
          style={{
            fontWeight: 500,
            fontSize: 40,
            margin: 0,
            padding: 0,
            lineHeight: 1,
            maxWidth: 684,
            marginBottom: 20,
          }}
        >
          {title}
        </Typography>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              width: 20,
              height: 20,
              marginRight: "10px",
              backgroundColor: "background.quaternary",
              color: "text.secondary",
              textAlign: "center",
              fontSize: "11.537px",
              fontWeight: 400,
            }}
            src={author?.profile?.profile_picture}
          >
            {author?.first_name
              ? author.first_name.charAt(0)
              : author?.email?.charAt(0) || "?"}
          </Avatar>
          <Typography
            style={{ fontSize: 14, fontWeight: 450 }}
            color="text.primary"
          >
            {formattedAuthorName}
          </Typography>

          <Typography
            style={{
              fontSize: 14,
              fontWeight: 450,
              marginLeft: "8px",
              marginRight: "8px",
            }}
            color="text.secondary"
          >
            (Author)
          </Typography>
          <Typography
            style={{ fontSize: 14, fontWeight: 450 }}
            color="text.tertiary"
          >
            {createdDate
              ? `${format(new Date(createdDate), "MMMM dd, yyyy '@' h:mma")}`
              : ""}{" "}
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px 0px 70px 0px",
            boxShadow: "none",
            flexDirection: "column",
            paddingTop: 20,
          }}
        >
          <TextEditor
            generatedOutline={editedBlog}
            setEditedBlog={setEditedBlog}
            backgroundColor={theme.palette.background.secondary}
          />
        </Box>
      </PageTemplate>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Blog copied to clipboard!
        </Alert>
      </Snackbar>
    </>
  );
};

export default FinalBlog;
