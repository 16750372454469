import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const IncompleteBrandConnections = () => {
  const navigate = useNavigate();
  const { brandId } = useParams();

  return (
    <Typography
      sx={{
        backgroundColor: "background.negative",
        padding: "5px 12px",
        fontSize: "14.8px",
        fontWeight: 500,
        borderRadius: "11px",
        mb: 2,
        mt: 3,
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        columnGap: 1,
      }}
      color="text.negative"
    >
      <LockOutlinedIcon style={{ fontSize: 18 }} />
      We need additional information from you to access these features. Please
      complete your
      <span
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={() =>
          navigate(`/workspace-settings/${brandId}/connect-accounts`)
        }
      >
        Brand Connections
      </span>{" "}
      to unlock this dashboard.
    </Typography>
  );
};
export default IncompleteBrandConnections;
