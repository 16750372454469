import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  Avatar,
  TextField,
  useTheme,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { endpoints } from "../../../../../../services/api/endpoints";
import RecommendationsSection from "../RecommendationsSection/RecommendationsSection";
import StatusDropdown from "../StatusDropDown";
import { getFaviconUrl } from "../../../../../../utils/getFaviconUrl";
import ChipButton from "../../../../../../CommonComponents/ChipButton/ChipButton";
const isEmpty = (obj) => Object.keys(obj).length === 0;

const Opportunity = ({ selectedRow, subTab }) => {
  const theme = useTheme();
  const { id } = selectedRow;
  const [showStatusReminder, setShowStatusReminder] = useState(false);
  const [loading, setLoading] = useState();
  const [data, setData] = useState({});
  const [feedbackLoading, setFeedbackLoading] = useState();
  const [status, setStatus] = useState(selectedRow?.source_status || "Inbox");
  const [isGenerated, setIsGenerated] = useState();
  const [showFeedback, setShowFeedback] = useState();
  const [showNegativeFeedback, setShowNegativeFeedback] = useState(false);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const refetch = useCallback(() => {
    setShowFeedback(false);
    setIsGenerated(false);
    setLoading(true);
    id
      ? endpoints.optimization
          .getReference(id)
          .then((response) => {
            setLoading(false);
            setData(response.data);
            setStatus(response.data.source_status);
            setShowFeedback(
              !response.data.feedback_sentiment &&
                !isEmpty(response.data.optimization)
            );
          })
          .catch((err) => {
            setLoading(false);
          })
      : setData({});
  }, [id]);

  useEffect(() => {
    refetch();
  }, [id, refetch]);

  useEffect(() => {
    setShowStatusReminder(false);
  }, [data, subTab]);

  const handleGenerateClick = useCallback(() => {
    setLoading(true);
    endpoints.optimization
      .generateOptimization({ sourceId: id })
      .then((response) => {
        setIsGenerated(true);
        setData(response.data);
        setShowFeedback(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("test error", err);
      });
    setShowStatusReminder(true);
  }, [id]);

  const handleCloseStatusReminder = useCallback(() => {
    setShowStatusReminder(false);
  }, []);

  const handlePositiveFeedback = useCallback(() => {
    setFeedbackLoading(true);
    endpoints.optimization
      .updateReference(id, { feedback_sentiment: "positive" })
      .then(() => {
        setFeedbackLoading(false);
        setShowThankYouMessage(true);
        setTimeout(() => {
          setShowFeedback(false);
          setShowThankYouMessage(false);
        }, 3000);
      })
      .catch((err) => {
        setFeedbackLoading(false);
        console.error("Error updating feedback:", err);
      });
  }, [id]);

  const handleNegativeFeedback = useCallback(() => {
    setShowNegativeFeedback(true);
  }, []);

  const handleReasonSelect = useCallback(
    (reason) => {
      if (selectedReason === reason) {
        setSelectedReason("");
      } else {
        setSelectedReason(reason);
      }
    },
    [selectedReason]
  );

  const handleSubmitNegativeFeedback = useCallback(() => {
    if (!selectedReason) return;

    setFeedbackLoading(true);
    endpoints.optimization
      .updateReference(id, {
        feedback_sentiment: "negative",
        feedback_issue_type: selectedReason,
        feedback_additional_comment: feedbackMessage,
      })
      .then(() => {
        setFeedbackLoading(false);
        setShowThankYouMessage(true);
        setTimeout(() => {
          setShowFeedback(false);
          setShowNegativeFeedback(false);
          setShowThankYouMessage(false);
          setSelectedReason("");
          setFeedbackMessage("");
        }, 4000);
      })
      .catch((err) => {
        setFeedbackLoading(false);
        console.error("Error updating feedback:", err);
      });
  }, [id, selectedReason, feedbackMessage]);

  useEffect(() => {
    setStatus(selectedRow.source_status);
  }, [selectedRow]);

  useEffect(() => {
    endpoints.optimization
      .updateReference(id, { source_status: status })
      .then((response) => {})
      .catch((err) => {
        console.error("Failed to update the item:", err);
      });
  }, [status]);
  if (!data) {
    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "text.secondary",
          borderRadius: "10px",
          padding: "16px",
          backgroundColor: "background.secondary",
        }}
      >
        No data selected.
      </Box>
    );
  }
  return (
    <Box
      sx={{
        flex: 1,
        padding: "20px",
        backgroundColor: "background.secondary",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        mt: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        {data?.source_url && (
          <Avatar
            sx={{
              width: "20px",
              height: "20px",
              textAlign: "center",
              mr: 1,
            }}
            src={getFaviconUrl(data.source_url)}
          />
        )}
        <Typography
          variant="body2"
          sx={{
            fontSize: "12px",
            lineHeight: "14px",
            color: "text.primary",
            wordBreak: "break-word",
            flex: 1,
          }}
        >
          {data.source_url}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              color: "text.secondary",
              textTransform: "capitalize",
              fontSize: "12px",
              lineHeight: "14px",
            }}
          >
            Status
          </Typography>

          <StatusDropdown
            selectedStatus={status}
            setSelectedStatus={setStatus}
          />
        </Box>
      </Box>
      {showStatusReminder && (
        <Box
          sx={{
            backgroundColor: "background.brand20",
            padding: "8px 16px",
            borderRadius: "20px",
            mb: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "text.brandbold",
              fontSize: "12px",
              lineHeight: "14px",
            }}
          >
            To keep track of your recommendations, change the status in the top
            right.
          </Typography>
          <IconButton
            size="small"
            onClick={handleCloseStatusReminder}
            sx={{ color: "text.primary", padding: 0 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
      <Box sx={{ overflowY: "auto", height: "400px" }}>
        <Box
          sx={{
            backgroundColor: "background.tertiary",
            padding: "10px",
            borderRadius: "10px",
            mb: "10px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              color: "text.primary",
              fontSize: "16px",
              lineHeight: "18px",
            }}
          >
            Opportunity
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{
            color: "text.tertiary",
            marginBottom: "10px",
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          {data.recommendations}
        </Typography>

        <RecommendationsSection
          data={isGenerated ? data : data.optimization}
          loading={loading}
          isEarned={selectedRow?.type?.toLowerCase() === "earned"}
        />

        {!loading && showFeedback && (
          <Box
            sx={{
              padding: "8px 16px ",
              borderRadius: "18px",
              backgroundColor: showThankYouMessage
                ? "background.brand20"
                : "background.tertiary",
              marginTop: "20px",
            }}
          >
            {showThankYouMessage ? (
              <Typography
                variant="body2"
                sx={{
                  color: "text.primary",
                  fontSize: "12px",
                  lineHeight: "14px",
                  textAlign: "center",
                }}
              >
                Thank you for your feedback!
              </Typography>
            ) : showNegativeFeedback ? (
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ThumbDownOffAltOutlinedIcon
                      sx={{
                        fontSize: "16px",
                        color: "text.tertiary",
                        marginRight: "6px",
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        color: "text.tertiary",
                        fontSize: "12px",
                        lineHeight: "14px",
                      }}
                    >
                      Tell us more
                    </Typography>
                  </Box>
                  <IconButton
                    size="small"
                    sx={{ color: "text.primary", padding: 0 }}
                    onClick={() => {
                      setShowNegativeFeedback(false);
                      setSelectedReason("");
                      setFeedbackMessage("");
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>

                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 1 }}>
                  {[
                    "Not relevant",
                    "Inaccurate",
                    "Confusing",
                    "Repetitive",
                    "Other",
                  ].map((reason) => (
                    <ChipButton
                      key={reason}
                      label={
                        selectedReason === reason ? (
                          <>
                            {reason}
                            <CloseIcon
                              fontSize="small"
                              sx={{ width: "14px", height: "14px" }}
                              textColor={theme.palette.text.secondary}
                            />
                          </>
                        ) : (
                          reason
                        )
                      }
                      onClick={() => handleReasonSelect(reason)}
                      backgroundColor={
                        selectedReason === reason
                          ? theme.palette.background.activeHover
                          : theme.palette.background.secondary
                      }
                      textColor={
                        selectedReason === reason
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary
                      }
                      borderColor={
                        selectedReason === reason
                          ? theme.palette.background.activeHover
                          : theme.palette.border.secondary
                      }
                      hoverBackgroundColor={
                        selectedReason === reason
                          ? theme.palette.background.activeHover
                          : theme.palette.background.secondaryhover
                      }
                      hoverTextColor={
                        selectedReason === reason
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary
                      }
                      hoverBorderColor={
                        selectedReason === reason
                          ? theme.palette.background.activeHover
                          : theme.palette.border.secondary
                      }
                      padding="10px 11px"
                      fontSize="14px"
                      fontWeight="400"
                      borderRadius="10px"
                    />
                  ))}
                </Box>

                <TextField
                  fullWidth
                  multiline
                  rows={1}
                  placeholder="Tell us more (optional)"
                  variant="outlined"
                  size="small"
                  value={feedbackMessage}
                  onChange={(e) => setFeedbackMessage(e.target.value)}
                  sx={{
                    mb: 1,
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: theme.palette.background.secondary,
                      borderColor: theme.palette.border.tertiary,
                      borderRadius: "11px",
                      padding: "8px 11px",
                      "& fieldset": {
                        borderColor: theme.palette.border.tertiary,
                      },
                      "&:hover fieldset": {
                        borderColor: theme.palette.border.tertiary,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: theme.palette.border.tertiary,
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: theme.palette.text.quaternary,
                    },
                  }}
                />

                <ChipButton
                  label={
                    feedbackLoading ? (
                      <CircularProgress size={20} sx={{ color: "white" }} />
                    ) : (
                      "Submit"
                    )
                  }
                  onClick={handleSubmitNegativeFeedback}
                  disabled={!selectedReason || feedbackLoading}
                  backgroundColor={theme.palette.background.brand}
                  textColor={theme.palette.text.chiptext}
                  borderColor={theme.palette.background.brand}
                  hoverBackgroundColor={theme.palette.background.brandhover}
                  hoverTextColor={theme.palette.text.chiptext}
                  hoverBorderColor={theme.palette.background.brandhover}
                  padding="8px 12px"
                  fontSize="14px"
                  borderRadius="10px"
                  fontWeight={400}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.tertiary",
                      fontSize: "12px",
                      lineHeight: "14px",
                    }}
                  >
                    Was this recommendation helpful?
                  </Typography>
                  {!feedbackLoading ? (
                    <>
                      <ThumbUpOutlinedIcon
                        sx={{
                          color: "text.tertiary",
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                        onClick={handlePositiveFeedback}
                      />
                      <ThumbDownOffAltOutlinedIcon
                        sx={{
                          color: "text.tertiary",
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                        onClick={handleNegativeFeedback}
                      />
                    </>
                  ) : (
                    <CircularProgress
                      style={{
                        width: 20,
                        height: 20,
                        color: "#2D333A",
                      }}
                    />
                  )}
                </Box>
                <IconButton
                  size="small"
                  sx={{ color: "text.primary", padding: 0 }}
                  onClick={() => setShowFeedback(false)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
        )}
        {!loading && !(isGenerated ? data : data.optimization) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: "auto",
              backgroundColor: "background.brand20",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "text.brand",
                fontSize: "16px",
                lineHeight: "18px",
                fontWeight: 500,
              }}
            >
              Optimization Recommendation
            </Typography>

            <Button
              variant="contained"
              endIcon={<ArrowRightAltIcon />}
              sx={{
                backgroundColor: "background.brand",
                color: "text.chiptext",
                padding: "6px 15px",
                borderRadius: "100px",
                textTransform: "none",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "background.brand",
                },
                "& .css-9tj150-MuiButton-endIcon": { ml: "4px" },
              }}
              onClick={handleGenerateClick}
            >
              Generate
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Opportunity;
