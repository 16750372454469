import FormField from "../../../../../../../CommonComponents/FormField";
import InputSection from "../../InputSection/InputSection";

const TopicsForm = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: 40 }}>
      <InputSection
        title="Topics"
        description="Enter up to 20 topics, with a maximum of 5 words each."
        rowGap={10}
        required
        children={
          <FormField
            maxTextFields={20}
            type="dynamicMultipleTextFields"
            name="topics"
            variant="outlined"
            fullwidth
            multiline
            minCharacters={20}
            placeholder="Type here..."
            requiredInputsCount={3}
            errorMessage
            customizedStyling={{ width: "100%" }}
            startAdornment="Topic"
          />
        }
      />
    </div>
  );
};
export default TopicsForm;
