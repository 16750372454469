import React, { useState, useEffect, useMemo } from "react";
import {
  Typography,
  Box,
  Button,
  TextField,
  Card,
  CircularProgress,
  useTheme,
} from "@mui/material";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import { useParams } from "react-router-dom";
import WorkspacePageTemplate from "../PageTemplate/PageTemplate";
import ErrorTypography from "../../../../CommonComponents/FormField/components/ErrorMessage/components/ErrorTypography/ErrorTypography";
import { endpoints } from "../../../../services/api/endpoints";

const validateWordCount = (text) => {
  const wordCount = text.trim().split(/\s+/).length;
  return wordCount >= 800 && wordCount <= 3000;
};

const KnowledgeBase = () => {
  const userId = JSON.parse(localStorage.getItem("loggedInUser"))?.id;
  const { brandId } = useParams();

  const [knowledgeBase, setKnowledgeBase] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [err, setError] = useState(false);
  const [saved, setSaved] = useState();
  const theme = useTheme();

  const wordCount = useMemo(() => {
    return knowledgeBase.trim().split(/\s+/).length;
  }, [knowledgeBase]);

  useEffect(() => {
    setInitialLoading(true);
    endpoints.brands
      .getKnowledgeBase(brandId)
      .then((response) => {
        setKnowledgeBase(response.data.content);
        setInitialLoading(false);
      })
      .catch((error) => {
        setInitialLoading(false);
      });
  }, [brandId]);

  const handleSave = () => {
    const values = {
      userId: userId,
      text_input: knowledgeBase,
      brandId: brandId,
    };
    setLoading(true);
    setSaved(false);
    setError(false);
    const isValid = validateWordCount(knowledgeBase);

    if (isValid) {
      endpoints.brands
        .updateKnowledgeBase(values)
        .then((response) => {
          setLoading(false);
          setSaved(true);
        })
        .catch((err) => {
          setSaved(false);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setError(
        wordCount < 800
          ? "Knowledge base should be more than 800 words"
          : wordCount > 3000
          ? "Knowledge base should be less than 3000 words"
          : ""
      );
    }
  };

  return (
    <WorkspacePageTemplate title="Knowledge Base">
      <Typography
        variant="subtitle1"
        sx={{
          color: "text.secondary",
          fontSize: "14px",
          fontWeight: 450,
          maxWidth: "550px",
          mt: -4,
          mb: 5,
        }}
      >
        Add an example of your best written blogs or articles so Goodie
        understands your brand's aspirational tone of voice and writing style.
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 450,
          lineHeight: "20px",
          borderTop: `1px solid ${theme.palette.border.tertiary}`,
          pt: "20px",
          mb: "20px",
        }}
        color="text.tertiary"
      >
        All Sections
      </Typography>

      <Card
        style={{
          borderRadius: "10px",
          padding: "20px ",
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          boxShadow: "none",
          rowGap: 10,
          width: "100%",
          marginTop: 40,
          backgroundColor: theme.palette.background.secondary,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "20px",
          }}
          color="text.primary"
        >
          Reference:{" "}
          <span
            style={{
              color: theme.palette.text.secondary,
              fontWeight: 400,
            }}
          >
            Article should be between 800-3000 words max.
          </span>
        </Typography>

        <TextField
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: `1px solid ${theme.palette.border.secondary}`,
                borderRadius: "11px",
              },
              "&:hover fieldset": {
                border: "1px solid",
                borderColor: theme.palette.border.secondary,
              },
              "&.Mui-focused fieldset": {
                border: "1px solid",
                borderColor: theme.palette.border.secondary,
              },
              "& .MuiInputBase-input::placeholder": {
                color: theme.palette.text.quaternary,
              },
            },
          }}
          value={knowledgeBase}
          rows={7}
          multiline
          onChange={(e) => {
            setSaved(false);
            setKnowledgeBase(e.target.value);
          }}
          placeholder={
            initialLoading
              ? "Loading..."
              : "Lorem ipsum dolor sit amet consectetur. Mollis a etiam aliquam nibh fermentum sollicitudin lectus neque lectus. Ipsum cursus rutrum a purus cursus. Tellus nibh euismod nisi quam magnis orci eu risus nisi. Ac facilisis consectetur proin est quis faucibus. Velit lectus ante suscipit nibh ultrices. Mollis nam cum vitae urna euismod imperdiet id lectus"
          }
        />

        {err && (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ErrorTypography errorMessage={err} hasBackground={false} />{" "}
            <Typography sx={{ fontSize: "14px", color: "#E9301C" }}>
              {wordCount}/3000
            </Typography>{" "}
          </Box>
        )}
      </Card>
      <div style={{ display: "flex", flexDirection: "row", marginTop: "40px" }}>
        {" "}
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            backgroundColor: saved ? "background.tertiary" : "buttons.primary",
            color: saved ? "text.secondary" : "text.chiptext",
            padding: "0px 22px",
            borderRadius: "99999px",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "48px",
            boxShadow: "none",
            minWidth: "auto",
            "&:hover": {
              backgroundColor: "buttons.primary",
            },
          }}
          onClick={() => handleSave()}
          startIcon={saved ? <CheckCircleOutlineSharpIcon /> : null}
        >
          {loading ? (
            <CircularProgress
              style={{
                width: 20,
                height: 20,
                color: "#2D333A",
              }}
            />
          ) : saved ? (
            "Saved"
          ) : (
            "Save Changes"
          )}
        </Button>
      </div>
    </WorkspacePageTemplate>
  );
};

export default KnowledgeBase;
