/* eslint-disable */
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useTheme } from "@mui/styles";
import BaseTable from "../../../../../../CommonComponents/BaseTable/BaseTable";
import { createHeaderCellWithTooltip } from "../../../../../../CommonComponents/BaseTable/utils/headerUtils";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import ChartCard from "../../../../../AEO/components/ChartCard/ChartCard";
import TextWithmovement from "../../../../../AEO/components/TextWithMovement/TextWithMovement";
import DateFilter from "../../../../../AEO/components/DateFilter/DateFilter";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import { endpoints } from "../../../../../../services/api/endpoints";
import { convertFiltersToParams } from "../../../../../../CommonComponents/BaseTable/utils/filterUtils";
import dayjs from "dayjs";
import { useDebounce } from "use-debounce";
import chatgptIcon from "../../../../../../assets/images/llmLogos/chatgpt.png";
import perplexityIcon from "../../../../../../assets/images/llmLogos/perplexity.png";
import geminiIcon from "../../../../../../assets/images/llmLogos/gemini.png";
import claudeIcon from "../../../../../../assets/images/llmLogos/Claude.png";
import deepseekIcon from "../../../../../../assets/images/llmLogos/Deepseek.png";
import metaAiIcon from "../../../../../../assets/images/llmLogos/meta-ai.png";
import mistralIcon from "../../../../../../assets/images/llmLogos/mistral.png";
import copilotIcon from "../../../../../../assets/images/llmLogos/microsoft-copilot.png";
import { useBrand } from "../../../../../../contexts/BrandContext";

// AI model icons/components
const ModelIcon = ({ model }) => {
  const theme = useTheme();

  // Map model names to their respective icons
  const modelIcons = {
    ChatGPT: chatgptIcon,
    Gemini: geminiIcon,
    Perplexity: perplexityIcon,
    Claude: claudeIcon,
    DeepSeek: deepseekIcon,
    MetaAI: metaAiIcon,
    Mistral: mistralIcon,
    MicrosoftCopilot: copilotIcon,
  };

  const icon = modelIcons[model];

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {icon && <img src={icon} style={{ width: 16, height: 16 }} alt={model} />}
      <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
        {model}
      </Typography>
    </Box>
  );
};

const PagePathCell = ({ path, fullUrl }) => {
  const theme = useTheme();

  let url = fullUrl || path;

  if (url && !/^https?:\/\//i.test(url)) {
    url = `https://${url}`;
  }

  const displayPath = path.replace(/^https?:\/\//i, "");

  return (
    <Tooltip title={`Open external website: ${url}`}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
          overflow: "hidden",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        onClick={() => {
          if (url) window.open(url, "_blank", "noopener,noreferrer");
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            color: theme.palette.text.primary,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {displayPath}
        </Typography>
        <Box
          component="span"
          sx={{
            display: "inline-flex",
            marginLeft: "4px",
            color: theme.palette.text.tertiary,
            "& svg": {
              width: "12px",
              height: "12px",
            },
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M10 6v2H5v11h11v-5h2v6a1 1 0 01-1 1H4a1 1 0 01-1-1V7a1 1 0 011-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z" />
          </svg>
        </Box>
      </Box>
    </Tooltip>
  );
};

const GrowthRateIndicator = ({ rate }) => {
  const isPositive = rate > 0;

  const formattedRate =
    rate > 0 ? Math.abs(rate).toFixed(2) : `-${Math.abs(rate).toFixed(2)}`;

  return (
    <Box
      sx={{
        height: "24px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <TextWithmovement
        movement={formattedRate}
        isIncrease={isPositive}
        percentageValue
      />
    </Box>
  );
};

const ImpressionsIndicator = ({ impressions, movement }) => {
  const theme = useTheme();
  // Handle the case where movement is exactly 0 (don't show negative zero)
  const isZero =
    movement === 0 || Math.abs(movement) < 0.01 || movement === "N/A";
  const isPositive = movement > 0;

  // Format the movement with a % sign and ensure negative values have a minus sign
  const displayMovement = isZero
    ? "0.00"
    : isPositive
    ? Math.abs(movement).toFixed(2)
    : `-${Math.abs(movement).toFixed(2)}`;

  const formattedImpressions = impressions ? impressions.toLocaleString() : "0";

  return (
    <Box
      sx={{
        height: "24px",
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: "14px",
          color: theme.palette.text.primary,
        }}
      >
        {formattedImpressions}
      </Typography>
      <TextWithmovement
        movement={displayMovement}
        isIncrease={isPositive}
        percentageValue={true}
      />
    </Box>
  );
};

const SessionsBar = ({ value, max }) => {
  const percentage = Math.min((value / max) * 100, 100);
  const theme = useTheme();
  const formattedValue = value ? value.toLocaleString() : "0";

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1, width: "100%" }}>
      <Typography
        sx={{
          fontSize: "14px",
          color: theme.palette.text.primary,
        }}
      >
        {formattedValue}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={percentage}
        sx={{
          height: 8,
          borderRadius: 4,
          width: "100%",
          backgroundColor: theme.palette.background.tertiary,
          "& .MuiLinearProgress-bar": {
            borderRadius: 4,
            backgroundColor: theme.palette.background.brand,
          },
        }}
      />
    </Box>
  );
};

const getTabColumns = (tabValue, theme) => {
  const commonColumns = [
    {
      field: "sessions",
      headerName: "Sessions",
      flex: 1.5,
      minWidth: 130,
      renderHeader: () =>
        createHeaderCellWithTooltip(
          "Sessions",
          tabValue === 0
            ? "Total number of visits driven by this AI model. Higher sessions indicate stronger traffic from that platform."
            : tabValue === 1
            ? "Total number of visits to this page from AI traffic."
            : "Total number of AI-driven visits from this channel and medium.",
          theme
        ),
      renderCell: (params) => (
        <SessionsBar value={params.value} max={params.row.maxSessions} />
      ),
      sortable: true,
    },
    {
      field: "growthRate",
      headerName: "Growth Rate",
      flex: 1,
      minWidth: 130,
      renderHeader: () =>
        createHeaderCellWithTooltip(
          "Growth Rate",
          tabValue === 0
            ? "Percentage change in sessions compared to the previous period. Positive growth indicates increasing AI traffic."
            : tabValue === 1
            ? "Percentage change in AI-driven sessions compared to the previous period."
            : "Percentage change in AI-driven sessions compared to the previous period.",
          theme
        ),
      renderCell: (params) => <GrowthRateIndicator rate={params.value} />,
      sortable: false,
    },
    {
      field: "conversions",
      headerName: "Conversions",
      flex: 1,
      minWidth: 120,
      renderHeader: () =>
        createHeaderCellWithTooltip(
          "Conversions",
          tabValue === 0
            ? "Total number of desired actions (e.g., purchases, form submissions) completed by visitors from this AI model."
            : tabValue === 1
            ? "Total number of desired actions (e.g., purchases, form submissions) completed by AI-referred visitors."
            : "Total number of desired actions (e.g., purchases, form submissions) completed by AI-referred visitors from this channel and medium.",
          theme
        ),
      renderCell: (params) => (
        <Typography
          sx={{ fontSize: "14px", color: theme.palette.text.primary }}
        >
          {params.value ? params.value.toLocaleString() : "0"}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "convRate",
      headerName: "Conv. Rate",
      flex: 1,
      minWidth: 120,
      renderHeader: () =>
        createHeaderCellWithTooltip(
          "Conv. Rate",
          tabValue === 0
            ? "Percentage of AI-driven sessions that resulted in a conversion."
            : tabValue === 1
            ? "Percentage of AI-driven sessions that resulted in a conversion."
            : "Percentage of AI-driven sessions from this channel and medium that resulted in a conversion.",
          theme
        ),
      renderCell: (params) => (
        <Typography
          sx={{ fontSize: "14px", color: theme.palette.text.primary }}
        >
          {params.value}%
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 1,
      minWidth: 120,
      renderHeader: () =>
        createHeaderCellWithTooltip(
          "Revenue",
          tabValue === 0
            ? "Total monetary value generated from AI-driven sessions attributed to this model."
            : tabValue === 1
            ? "Total monetary value generated from AI-driven sessions to this page."
            : "Total monetary value generated from AI-driven sessions through this channel and medium.",
          theme
        ),
      renderCell: (params) => (
        <Typography
          sx={{ fontSize: "14px", color: theme.palette.text.primary }}
        >
          {params.value}
        </Typography>
      ),
      sortable: true,
    },
    {
      field: "engagementDuration",
      headerName: "Engagement Duration",
      flex: 1,
      minWidth: 180,
      renderHeader: () =>
        createHeaderCellWithTooltip(
          "Engagement Duration",
          tabValue === 0
            ? "Average time AI-referred visitors spend interacting with your site. Longer durations suggest higher engagement."
            : tabValue === 1
            ? "Average time AI-referred visitors who land on this page interact with your site."
            : "Average time AI-referred visitors spend interacting with your site from this channel.",
          theme
        ),
      renderCell: (params) => (
        <Typography
          sx={{ fontSize: "14px", color: theme.palette.text.primary }}
        >
          {params.value}
        </Typography>
      ),
      sortable: true,
    },
    {
      field: "eventsPerSession",
      headerName: "Events per Session",
      flex: 1,
      minWidth: 160,
      renderHeader: () =>
        createHeaderCellWithTooltip(
          "Events per Session",
          tabValue === 0
            ? "Average number of tracked user interactions per AI-driven session. Higher numbers may indicate more active engagement."
            : tabValue === 1
            ? "Average number of tracked user interactions per AI-driven sessions to this page."
            : "Average number of tracked user interactions per AI-driven session from this channel and medium.",
          theme
        ),
      renderCell: (params) => (
        <Typography
          sx={{ fontSize: "14px", color: theme.palette.text.primary }}
        >
          {typeof params.value === "number"
            ? params.value.toLocaleString()
            : params.value}
        </Typography>
      ),
      sortable: true,
    },
    {
      field: "keyEvents",
      headerName: "Key Events",
      flex: 1,
      minWidth: 120,
      renderHeader: () =>
        createHeaderCellWithTooltip(
          "Key Events",
          tabValue === 0
            ? 'Notable actions or conversions flagged as "key events" in GA4. These are predefined high-value interactions.'
            : tabValue === 1
            ? 'Notable actions or conversions flagged as "key events" in GA4.'
            : 'Notable actions or conversions flagged as "key events" in GA4 for this traffic source.',
          theme
        ),
      renderCell: (params) => (
        <Typography
          sx={{ fontSize: "14px", color: theme.palette.text.primary }}
        >
          {typeof params.value === "number"
            ? params.value.toLocaleString()
            : params.value}
        </Typography>
      ),
      sortable: true,
    },
  ];

  // Define tab-specific second column with appropriate renderer
  const tabSpecificColumn = {
    field:
      tabValue === 0 ? "model" : tabValue === 1 ? "pagePath" : "channelMedium",
    headerName:
      tabValue === 0
        ? "Model"
        : tabValue === 1
        ? "Page Path"
        : "Channel / Medium",
    flex: 1,
    minWidth: tabValue === 0 ? 120 : tabValue === 1 ? 250 : 180,
    renderHeader: () =>
      createHeaderCellWithTooltip(
        tabValue === 0
          ? "Model"
          : tabValue === 1
          ? "Page Path"
          : "Channel / Medium",
        tabValue === 0
          ? "The AI model (e.g., ChatGPT) generating traffic to your site."
          : tabValue === 1
          ? "The URL path of the page receiving AI traffic."
          : "The source and medium driving AI-generated traffic (e.g., organic, direct, referral).",
        theme
      ),
    renderCell: (params) => {
      if (tabValue === 0) {
        return <ModelIcon model={params.value} />;
      } else if (tabValue === 1) {
        return (
          <PagePathCell path={params.value} fullUrl={params.row.fullPageUrl} />
        );
      }
      return (
        <Typography
          sx={{
            fontSize: "14px",
            color: theme.palette.text.primary,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {params.value}
        </Typography>
      );
    },
    sortable: false,
  };

  let columns = [tabSpecificColumn];

  if (tabValue === 0) {
    columns.push({
      field: "impressions",
      headerName: "Impressions",
      flex: 1,
      minWidth: 130,
      renderHeader: () =>
        createHeaderCellWithTooltip(
          "Impressions",
          "The number of times AI models mention or reference your brand/site. More impressions indicate greater exposure within AI-generated answers.",
          theme
        ),
      renderCell: (params) => (
        <ImpressionsIndicator
          impressions={params.value}
          movement={params.row.impressionsMovement}
        />
      ),
      sortable: false,
    });
  }

  return [...columns, ...commonColumns];
};

// Map tab value to API group_by parameter
const getGroupByForTab = (tabValue) => {
  switch (tabValue) {
    case 0:
      return "model";
    case 1:
      return "page_path";
    case 2:
      return "session_medium";
    default:
      return "model";
  }
};

const formatApiResponseData = (
  responseData,
  tabValue,
  impressionsData = {}
) => {
  const MAX_SESSIONS = 1000;

  const findImpressionData = (modelName) => {
    if (impressionsData[modelName]) {
      return impressionsData[modelName];
    }

    const normalizedModelName = modelName.toLowerCase();
    const keys = Object.keys(impressionsData);

    for (const key of keys) {
      if (key.toLowerCase() === normalizedModelName) {
        return impressionsData[key];
      }

      if (
        (key === "ChatGpt" && modelName === "ChatGPT") ||
        (key === "ChatGPT" && modelName === "ChatGpt")
      ) {
        return impressionsData[key];
      }
    }

    return { total_impressions: 0, movement: 0 };
  };

  return responseData.map((item, index) => {
    // Determine the tab-specific field based on the tabValue
    let tabSpecificField;
    switch (tabValue) {
      case 0:
        const modelName = item.llm || item.model || "Unknown";
        const impressionData = findImpressionData(modelName);

        tabSpecificField = {
          model: modelName,
          // Add impressions data if we're on the Model tab
          impressions: impressionData?.total_impressions || 0,
          impressionsMovement: impressionData?.movement || 0,
        };
        break;
      case 1:
        tabSpecificField = {
          pagePath: item.page_path || "Unknown",
          fullPageUrl: item.full_page_url || item.page_path,
        };
        break;
      case 2:
        // Format as "source / medium" for display
        const source = item.session_source || "Unknown";
        const medium = item.session_medium || "Unknown";
        tabSpecificField = {
          channelMedium: `${source} / ${medium}`,
          channel: source,
          medium: medium,
        };
        break;
      default:
        tabSpecificField = { model: item.llm || "Unknown" };
    }

    // Format revenue with dollar sign if not already present
    const formattedRevenue = item.revenue
      ? item.revenue.toString().startsWith("$")
        ? item.revenue
        : `$${parseFloat(item.revenue).toFixed(2)}`
      : "$0.00";

    return {
      id: `item-${index}`,
      ...tabSpecificField,
      sessions: item.sessions || 0,
      growthRate: item.growth_rate || 0,
      conversions: item.conversions || 0,
      convRate: item.conv_rate || 0,
      revenue: formattedRevenue,
      engagementDuration: item.engagement_duration || "0:00m",
      eventsPerSession: item.events_per_session || 0,
      keyEvents: item.key_events || 0,
      maxSessions: MAX_SESSIONS,
    };
  });
};

// Map frontend filter fields to backend filter fields
const mapFilterFieldsToBackend = (activeFilters) => {
  const mappedFilters = [];

  activeFilters.forEach((filter) => {
    const { field, operator, value, timeUnit } = filter;

    // Map frontend field names to backend field names
    let backendField;
    let backendOperator;

    // Define field type mapping (text or numeric)
    const numericFields = ["sessions", "revenue", "events", "keyEvents"];
    const durationFields = ["engagementDuration"];

    const isNumeric = numericFields.includes(field);
    const isDuration = durationFields.includes(field);

    // Handle field mapping
    switch (field) {
      case "model":
        backendField = "llm";
        break;
      case "pagePath":
        backendField = "full_page_url";
        break;
      case "channel":
        backendField = "session_source";
        break;
      case "medium":
        backendField = "session_medium";
        break;
      case "sessions":
        backendField = "sessions";
        break;
      case "revenue":
        backendField = "purchase_revenue";
        break;
      case "engagementDuration":
        backendField = "user_engagement_duration";
        break;
      case "events":
        backendField = "event_count";
        break;
      case "keyEvents":
        backendField = "key_events";
        break;
      default:
        backendField = field;
    }

    // Handle operator mapping based on field type
    if (isNumeric || isDuration) {
      // Numeric field operators
      switch (operator) {
        case "equals":
          backendOperator = ""; // Use exact value
          break;
        case "gte":
          backendOperator = "_min";
          break;
        case "lte":
          backendOperator = "_max";
          break;
        default:
          backendOperator = "";
      }
    } else {
      // Text field operators
      switch (operator) {
        case "contains":
          backendOperator = ""; // Use default icontains
          break;
        case "exact":
        case "equals":
          backendOperator = "_exact";
          break;
        case "startswith":
          backendOperator = "_startswith";
          break;
        default:
          backendOperator = "";
      }
    }

    // Convert duration values based on time unit
    let convertedValue = value;
    if (isDuration) {
      // Always convert to seconds for API
      if (timeUnit) {
        switch (timeUnit) {
          case "minutes":
            convertedValue = Number(value) * 60;
            break;
          case "hours":
            convertedValue = Number(value) * 3600;
            break;
          case "seconds":
            convertedValue = Number(value);
            break;
          default:
            // Default to seconds if unknown unit
            convertedValue = Number(value);
        }
      } else {
        // If no timeUnit specified, assume seconds
        convertedValue = Number(value);
      }
    }

    mappedFilters.push({
      field: `${backendField}${backendOperator}`,
      value: isDuration ? convertedValue : value,
    });
  });

  return mappedFilters;
};

const TrafficAnalyticsTable = ({ initialDate, selectedDate, selectedLLM }) => {
  const theme = useTheme();
  const { date: globalDate, initialDate: globalInitialDate } =
    useGlobalFilters();
  const [tabValue, setTabValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const [date, setDate] = useState(selectedDate || globalDate);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { selectedBrand } = useBrand();
  const [impressionsData, setImpressionsData] = useState({});

  useEffect(() => {
    if (selectedDate) {
      setDate(selectedDate);
    }
  }, [selectedDate]);

  // Fetch impressions data when tab is on Model view or date changes
  useEffect(() => {
    const fetchImpressionsData = async () => {
      if (tabValue === 0) {
        try {
          const dateMin = date && date[0] ? date[0].format("YYYY-MM-DD") : null;
          const dateMax = date && date[1] ? date[1].format("YYYY-MM-DD") : null;

          if (!dateMin || !dateMax) {
            return;
          }

          // Update the API call to match the ImpressionByModelCard implementation
          const response =
            await endpoints.agentAnalytics.getAgentAnalyticsModelImpressions({
              brandId: selectedBrand,
              start_date: dateMin,
              end_date: dateMax,
            });

          setImpressionsData(response.data || {});
        } catch (error) {
          console.error("Error fetching model impressions data:", error);
        }
      }
    };

    fetchImpressionsData();
  }, [tabValue, date, selectedBrand]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const columns = useMemo(
    () => getTabColumns(tabValue, theme),
    [tabValue, theme]
  );

  const fetchData = useCallback(
    async ({
      paginationModel,
      sortModel,
      searchQuery: queryFromTable,
      activeFilters,
    }) => {
      setLoading(true);
      setErrorMessage(null);

      try {
        const dateMin = date && date[0] ? date[0].format("YYYY-MM-DD") : null;
        const dateMax = date && date[1] ? date[1].format("YYYY-MM-DD") : null;

        if (!dateMin || !dateMax) {
          throw new Error("Date range is required");
        }

        const groupBy = getGroupByForTab(tabValue);

        let ordering = "";
        if (sortModel.length > 0) {
          const fieldMap = {
            sessions: "sessions",
            revenue: "purchase_revenue",
            engagementDuration: "user_engagement_duration",
            eventsPerSession: "event_count",
            keyEvents: "key_events",
          };

          const apiField = fieldMap[sortModel[0].field] || sortModel[0].field;
          ordering = sortModel[0].sort === "asc" ? apiField : `-${apiField}`;
        }

        const currentSearchQuery = queryFromTable || debouncedSearchQuery;

        const mappedFilters = mapFilterFieldsToBackend(activeFilters);
        const filterParams = convertFiltersToParams(mappedFilters);

        const params = {
          group_by: groupBy,
          date_min: dateMin,
          date_max: dateMax,
          page: paginationModel.page + 1,
          page_size: paginationModel.pageSize,
          ordering: ordering || "-sessions",
          search: currentSearchQuery || undefined,
          ...filterParams,
        };

        const response = await endpoints.agentAnalytics.getGA4Analytics(
          selectedBrand,
          params
        );

        const formattedData = formatApiResponseData(
          response.data.results,
          tabValue,
          impressionsData
        );

        return {
          data: formattedData,
          count: response.data.count || 0,
        };
      } catch (error) {
        console.error("Error fetching analytics data:", error);
        setErrorMessage(error.message || "Failed to fetch analytics data");
        return {
          data: [],
          count: 0,
        };
      } finally {
        setLoading(false);
      }
    },
    [tabValue, debouncedSearchQuery, date, selectedLLM, impressionsData]
  );

  const filterFields = useMemo(() => {
    const baseFilters = [
      { value: "sessions", label: "Sessions", type: "number" },
      { value: "revenue", label: "Revenue", type: "number" },
      {
        value: "engagementDuration",
        label: "Engagement Duration",
        type: "duration",
      },
      {
        value: "events",
        label: "Events",
        type: "number",
      },
      { value: "keyEvents", label: "Key Events", type: "number" },
    ];

    if (tabValue === 0) {
      return [{ value: "model", label: "Model", type: "text" }, ...baseFilters];
    } else if (tabValue === 1) {
      return [
        { value: "pagePath", label: "Page Path", type: "text" },
        ...baseFilters,
      ];
    } else {
      return [
        { value: "channel", label: "Channel (Source)", type: "text" },
        { value: "medium", label: "Medium", type: "text" },
        ...baseFilters,
      ];
    }
  }, [tabValue]);

  const searchPlaceholder = useMemo(
    () =>
      `Search by ${
        tabValue === 0
          ? "model"
          : tabValue === 1
          ? "page path"
          : "channel or medium"
      }`,
    [tabValue]
  );

  return (
    <ChartCard
      title="Traffic Analytics"
      tooltipMessagePrimary="Detailed analysis of traffic and engagement metrics across AI models."
      description={
        <>
          Examine AI-driven traffic patterns across models, channels, mediums,
          and pages to uncover trends, optimize
          <br />
          performance, and refine acquisition strategies.
        </>
      }
      height="100%"
      hasCalendarSelect
      date={date}
      setDate={setDate}
      initialDate={initialDate || globalInitialDate}
    >
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          sx={{
            "& .MuiTab-root": {
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.text.secondary,
              "&.Mui-selected": {
                color: theme.palette.text.primary,
              },
            },
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.text.primary,
            },
          }}
        >
          <Tab label="Traffic by Model" disableRipple />
          <Tab label="Traffic by Page" disableRipple />
          <Tab label="Traffic by Channel & Medium" disableRipple />
        </Tabs>

        {errorMessage && (
          <Box
            sx={{
              padding: 2,
              color: theme.palette.error.main,
              backgroundColor: alpha(theme.palette.error.main, 0.1),
              borderRadius: 1,
              marginY: 2,
            }}
          >
            <Typography>{errorMessage}</Typography>
          </Box>
        )}

        <BaseTable
          columns={columns}
          fetchData={fetchData}
          initialSortModel={[{ field: "sessions", sort: "desc" }]}
          initialPaginationModel={{ page: 0, pageSize: 20 }}
          searchPlaceholder={searchPlaceholder}
          hasFiltering={true}
          hasSearch={true}
          isServerSide={true}
          filterFields={filterFields}
          searchHandler={setSearchQuery}
          loading={loading}
          key={`traffic-tab-${tabValue}`}
        />
      </Box>
    </ChartCard>
  );
};

export default TrafficAnalyticsTable;
