import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import ChartCard from "../../../../../AEO/components/ChartCard/ChartCard";
import ScoreMovementTypography from "../ScoreMovementTypography";
import AuthorityIcon from "../../../../../../CommonComponents/customIcons/AuthorityIcon";
import { useParams } from "react-router-dom";
import { endpoints } from "../../../../../../services/api/endpoints";

const AIAuthorityScoreCard = ({}) => {
  const { date: globalDate, initialDate: globalInitialDate } =
    useGlobalFilters();
  const [date, setDate] = useState(globalInitialDate);
  const theme = useTheme();
  const { brandId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    if (date.length > 0) {
      const values = {
        brandId,
        start_date: date[0] && date[0].format("YYYY-MM-DD"),
        end_date: date[1] && date[1].format("YYYY-MM-DD"),
      };
      setLoading(true);
      endpoints.aeo
        .getAviIndex(values)
        .then((response) => {
          setLoading(false);
          setData(response.data);
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
        });
    }
  }, [brandId, date]);
  return (
    <ChartCard
      icon={<AuthorityIcon color={theme.palette.text.primary} />}
      title="AI Visibility Impact Score (AVI) "
      tooltipMessagePrimary="The AI Visibility Impact Score (AVI) measures how strongly the analyzed sources affect your brand’s visibility in AI search. It’s a score from 0 to 100 that’s based on several key factors, including:"
      tooltipMessageSecondary="
Frequency & ranking: How often and how prominently these sources contribute to AI outputs.
Quality of mentions: The placement (e.g., lead paragraphs) and frequency of brand references within each source.
Sentiment: The overall tone (positive, neutral, or negative) expressed toward your brand within each source."
      tooltipMessageTertiary="
A higher AVI indicates a stronger, more positive impact on AI search visibility. While not a direct ranking factor, it helps you understand which sources most influence how AI models present your brand."
      description="Evaluates how the analyzed sources shape your brand’s AI visibility by weighing LLM prominence, quality of mentions, and sentiment."
      height={168}
      setDate={setDate}
      date={date}
      initialDate={globalInitialDate}
      hasCalendarSelect
      isLoading={loading}
      isError={error}
    >
      <ScoreMovementTypography
        score={data?.avi_index?.toFixed(0)}
        movement={data?.movement?.toFixed(0)}
      />
    </ChartCard>
  );
};
export default AIAuthorityScoreCard;
