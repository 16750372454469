import React from "react";

const GlobalDNAIcon = ({ color = "#8C8C8C", width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : "16px"}
    height={height ? height : "16px"}
    viewBox="0 0 16 16"
    fill={color}
  >
    <path
      d="M14.2133 3.36687L10.2133 2.03353H10.1666C10.1356 2.03041 10.1043 2.03041 10.0733 2.03353H9.91998H9.83331H9.78665L5.99998 3.33353L2.21332 2.03353C2.11306 2.00047 2.00638 1.9917 1.90207 2.00792C1.79776 2.02415 1.69879 2.06492 1.61332 2.12687C1.52715 2.18821 1.45683 2.26917 1.40814 2.36307C1.35945 2.45697 1.3338 2.5611 1.33332 2.66687V12.0002C1.33295 12.14 1.37653 12.2763 1.45788 12.39C1.53923 12.5036 1.65423 12.5888 1.78665 12.6335L5.78665 13.9669C5.92095 14.0107 6.06568 14.0107 6.19998 13.9669L9.99998 12.7002L13.7866 14.0002C13.8574 14.0098 13.9292 14.0098 14 14.0002C14.1394 14.0022 14.2754 13.9576 14.3866 13.8735C14.4728 13.8122 14.5431 13.7312 14.5918 13.6373C14.6405 13.5434 14.6662 13.4393 14.6666 13.3335V4.0002C14.667 3.86044 14.6234 3.7241 14.5421 3.61045C14.4607 3.4968 14.3457 3.41159 14.2133 3.36687ZM5.33331 12.4069L2.66665 11.5202V3.59353L5.33331 4.4802V12.4069ZM9.33331 11.5202L6.66665 12.4069V4.4802L9.33331 3.59353V11.5202ZM13.3333 12.4069L10.6666 11.5202V3.59353L13.3333 4.4802V12.4069Z"
      fill={color}
    />
  </svg>
);

export default GlobalDNAIcon;
