import ChipButton from "../../../../../../CommonComponents/ChipButton/ChipButton";
import EastIcon from "@mui/icons-material/East";
import { useState } from "react";
import {
  Card,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import Wrapper from "../../../Wrapper/Wrapper";
import { useNavigate } from "react-router-dom";
import { endpoints } from "../../../../../../services/api/endpoints";
import ErrorTypography from "../../../../../../CommonComponents/FormField/components/ErrorMessage/components/ErrorTypography/ErrorTypography";
import { useBrand } from "../../../../../../contexts/BrandContext";

const PendingInvites = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedInvite, setSelectedInvite] = useState("");
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const { selectBrand } = useBrand();
  const pendingInvitations = loggedInUser?.pending_invites;

  const acceptInvite = () => {
    const values = { status: "accepted" };
    endpoints.inviteUsers
      .acceptInvite(selectedInvite.id, values)
      .then(async (response) => {
        setIsLoading(false);
        await selectBrand(selectedInvite.brand.id);
        navigate(
          `/accounts/${loggedInUser.id}?brandId=${selectedInvite.brand.id}`
        );
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  return (
    <Wrapper isfullLength>
      <Card
        style={{
          borderRadius: "35px",
          padding: "50px 85px 70px 85px",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          width: 550,
          rowGap: 20,
          backgroundColor: "transparent",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {" "}
          <Typography
            style={{ fontSize: 36, fontWeight: 600 }}
            color="text.primary"
          >
            Join a workspace
          </Typography>
          <Typography
            style={{ fontSize: 14, fontWeight: 450 }}
            color="text.secondary"
          >
            Select the workspace that you'd like to join.
          </Typography>{" "}
          <Typography
            style={{ fontSize: 14, fontWeight: 450 }}
            color="text.secondary"
          >
            The following workspaces have invited your email.
          </Typography>
        </div>
        <FormControl
          sx={{
            width: "100%",
            marginTop: 3,
          }}
        >
          <RadioGroup
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
            }}
          >
            {pendingInvitations?.map((invite) => (
              <div
                style={{
                  border: `1px solid ${theme.palette.border.secondary}`,
                  borderRadius: "11px",
                  padding: "10px 16px",
                  width: "100%",
                }}
                onClick={() => {
                  setSelectedInvite(invite);
                }}
              >
                <FormControlLabel
                  value={invite.brand.id}
                  control={
                    <Radio
                      sx={{
                        color:
                          invite.brand.id === selectedInvite.id
                            ? theme.palette.secondary.main
                            : "#E1E1E1",
                        "&.Mui-checked": {
                          color: theme.palette.background.brand,
                        },
                        "& .MuiSvgIcon-root": {
                          borderRadius: "50%",
                          width: 24,
                          height: 24,
                          backgroundColor:
                            invite.brand.id === selectedInvite.id
                              ? theme.palette.background.brand
                              : "transparent",
                        },
                      }}
                    />
                  }
                  label={invite.brand.name}
                  sx={{ color: theme.palette.text.primary, width: "100%" }}
                />
              </div>
            ))}{" "}
          </RadioGroup>
        </FormControl>

        {error && <ErrorTypography errorMessage={error} />}
        <ChipButton
          iconAfter={<EastIcon />}
          label={"Continue"}
          backgroundColor={theme.palette.background.brand}
          textColor={theme.palette.text.chiptext}
          hoverBackgroundColor={theme.palette.text.brandbold}
          hoverTextColor={theme.palette.text.chiptext}
          hoverBorderColor={theme.palette.text.brandbold}
          fullWidth
          onClick={() => acceptInvite()}
          isLoading={isLoading}
        />
        <Typography style={{ fontSize: 14 }} color="text.secondary">
          Want to create a new workspace?
          <span
            style={{
              color: theme.palette.text.brandbold,
              cursor: "pointer",
              marginLeft: 5,
            }}
            onClick={() => navigate("/workspace-setup")}
          >
            Create workspace
          </span>
        </Typography>
      </Card>{" "}
    </Wrapper>
  );
};
export default PendingInvites;
