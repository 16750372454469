import { Box, Typography } from "@mui/material";
import Wrapper from "../Wrapper/Wrapper";
import LoginIcon from "../../../../CommonComponents/customIcons/LoginIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { endpoints } from "../../../../services/api/endpoints";
import AccountsCard from "../Card/Card";
import EastIcon from "@mui/icons-material/East";
import FormikTextField from "../../../../CommonComponents/FormField/components/TextField/TextField";
import { Formik } from "formik";

const ForgetPassword = () => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");

  const handleOnClick = (values) => {
    setError("");
    setIsLoading(true);
    endpoints.auth
      .forgotPassword(values.email)
      .then((response) => {})
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
        navigate(`confirm?email=${values?.email || ""}`);
      });
  };

  const initialValues = useMemo(() => ({ email: email }), [email]);
  return (
    <Wrapper isfullLength>
      <Formik initialValues={initialValues} onSubmit={handleOnClick}>
        {({ values, handleSubmit }) => (
          <AccountsCard
            icon={<LoginIcon />}
            title="Forgot password?"
            backToLoginButton
            buttonIcon={<EastIcon />}
            buttonLabel="Reset password"
            description="Enter your email address and we will send you instructions to reset your password."
            error={error}
            isLoading={isLoading}
            onButtonClick={() => handleSubmit()}
          >
            <Box
              style={{ display: "flex", flexDirection: "column", rowGap: 8 }}
            >
              <Typography style={{ fontSize: 15 }} color="text.primary">
                Email
              </Typography>
              <FormikTextField
                name="email"
                placeholder="user@nogood.io"
                margin={false}
              />
            </Box>
          </AccountsCard>
        )}
      </Formik>
    </Wrapper>
  );
};
export default ForgetPassword;
