import ChipButton from "../../../../../../CommonComponents/ChipButton/ChipButton";
import EastIcon from "@mui/icons-material/East";
import { useState } from "react";
import { Card, TextField, Typography, useTheme } from "@mui/material";
import Wrapper from "../../../Wrapper/Wrapper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ErrorTypography from "../../../../../../CommonComponents/FormField/components/ErrorMessage/components/ErrorTypography/ErrorTypography";

const Setup = ({ setCurrentStep }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  return (
    <Wrapper>
      <Card
        style={{
          borderRadius: "35px",
          padding: "50px 85px 70px 85px",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          width: 550,
          rowGap: 20,
          backgroundColor: "transparent",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {" "}
          <Typography
            style={{ fontSize: 36, fontWeight: 600 }}
            color="text.primary"
          >
            What’s a good name for your workspace?
          </Typography>
          <Typography
            style={{ fontSize: 14, fontWeight: 450 }}
            color="text.secondary"
          >
            This will be the name of your Goodie workspace.
          </Typography>{" "}
          <Typography
            style={{ fontSize: 14, fontWeight: 450 }}
            color="text.secondary"
          >
            Choose something that your team will recognize.
          </Typography>
        </div>
        <div style={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
          <Typography style={{ fontSize: 15 }}>Workspace name</Typography>
          <TextField
            // value={brandName}
            // onChange={handleChange}
            placeholder="Company"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1px solid rgba(0, 0, 0, 0.10)",
                },
                "&:hover fieldset": {
                  border: "1px solid",
                  borderColor: "#BAC1C9",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid",
                  borderColor: "#BAC1C9",
                },
              },
            }}
          />
        </div>
        {error && <ErrorTypography errorMessage={error} />}
        <ChipButton
          iconAfter={<EastIcon />}
          label={"Continue"}
          backgroundColor={theme.palette.background.brand}
          textColor={theme.palette.text.chiptext}
          hoverBackgroundColor={theme.palette.text.brandbold}
          hoverTextColor={theme.palette.text.chiptext}
          hoverBorderColor={theme.palette.text.brandbold}
          fullWidth
          onClick={() => setCurrentStep(4)}
          isLoading={isLoading}
        />
      </Card>{" "}
    </Wrapper>
  );
};
export default Setup;
