import { useTheme } from "@mui/styles";
import GlobalIcon from "../../../../../../CommonComponents/customIcons/GlobalIcon";
import ChartCard from "../../../../../AEO/components/ChartCard/ChartCard";
import MapChart from "../../../../../../CommonComponents/Charts/MapChart/MapChart";
import { endpoints } from "../../../../../../services/api/endpoints";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";

const transformData = (backendResponse) => {
  return backendResponse.data
    .filter((item) => item.country)
    .map((item) => ({
      country: item.country,
      value: item.total_sessions,
    }));
};

const TrafficByCountry = () => {
  const theme = useTheme();
  const { date: globalDate, initialDate: globalInitialDate } =
    useGlobalFilters();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { brandId } = useParams();
  const [date, setDate] = useState(globalDate);
  const [initialDate, setInitialDate] = useState(globalInitialDate);

  useEffect(() => {
    setDate(globalDate);
  }, [globalDate]);

  useEffect(() => {
    setInitialDate(globalInitialDate);
  }, [globalInitialDate]);

  useEffect(() => {
    if (date.length > 0 && date[0] && date[1]) {
      endpoints.agentAnalytics
        .getAgentAnalyticsTraffic({
          brandId,
          start_date: date[0].format("YYYY-MM-DD"),
          end_date: date[1].format("YYYY-MM-DD"),
          aggregate_by: ["country"],
        })
        .then((response) => {
          setLoading(false);
          setData(transformData(response.data));
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching user data:", error);
        });
    }
  }, [brandId, date]);

  useEffect(() => {
    setDate(globalDate);
  }, [globalDate]);

  const countryData =
    data?.reduce((acc, item) => {
      acc[item.country] = item.value;
      return acc;
    }, {}) || {};

  return (
    <ChartCard
      title="Traffic by Country"
      icon={<GlobalIcon color={theme.palette.text.primary} />}
      tooltipMessagePrimary="Geographic distribution of AI-driven traffic. Understanding country-level trends can help optimize regional strategies and AI search performance."
      height={"fit-content"}
      hasCalendarSelect
      setDate={setDate}
      initialDate={initialDate}
      date={date}
    >
      <MapChart countryData={countryData} />
    </ChartCard>
  );
};

export default TrafficByCountry;
