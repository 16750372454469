import * as Yup from "yup";

export const steps = [
  "Business",
  "Persona",
  "Brand Voice",
  "Competition",
  "Topics",
  "Global Audience",
];

export const businessValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .max(100, "Please answer with atmost 100 characters"),

  primary_product: Yup.string()
    .test(
      "word-count",
      "Must be at most 3 words",
      (value) => value && value.split(" ").filter(Boolean).length <= 3
    )
    .required("Required")
    .max(100, "Please answer with atmost 100 characters"),

  description: Yup.string()
    .required("Required")
    .max(500, "Please answer with atmost 500 characters"),

  conversion_goals: Yup.array()
    .min(1, "Please select at least 1")
    .required("Required"),

  industry: Yup.string().required("Required"),

  business_model: Yup.string().required("Required"),

  market_focus: Yup.string().required("Required"),

  primary_language: Yup.string().required("Required"),

  website: Yup.string()
    .required("Required")
    .transform((value) => {
      // Add `http://` if the URL does not start with `http://` or `https://`
      if (value && !/^https?:\/\//i.test(value)) {
        return `http://${value}`;
      }
      return value;
    })
    .url("Please enter a valid website url"),

  usp: Yup.string().max(250, "Please answer with at most 250 characters"),

  features: Yup.array()
    .min(2, "Please provide at least 2")
    .of(
      Yup.string()
        .min(12, "Please answer with at least 12 characters")
        .max(60, "Please answer with at most 60 characters")
        .required("Required")
    ),

  benefits: Yup.array().of(
    Yup.string()
      .min(12, "Please answer with at least 12 characters")
      .max(60, "Please answer with at most 60 characters")
      .required("Required")
  ),

  market_attributes: Yup.array()
    .of(Yup.string().required("Required"))
    .required("Required")
    .min(1, "Please provide at least 1"),
});

export const personaValidationSchema = Yup.object().shape({
  age_ranges: Yup.array()
    .of(Yup.string().required("Required"))
    .required("Required")
    .min(1, "Select at least 1")
    .max(3, "You can select up to 3 options"),

  genders: Yup.array()
    .of(Yup.string().required("Required"))
    .required("Required")
    .min(1, "Select at least 1")
    .test(
      "valid-gender",
      'Select up to 3 options and "Prefer not to say" alone',
      (values) => {
        if (values.includes("Prefer not to say")) {
          return values.length === 1 || values.length <= 3;
        }
        return values.length <= 3;
      }
    ),

  interests: Yup.array()
    .of(Yup.string().required("Required"))
    .required("Required")
    .min(1, "Select at least 1")
    .max(5, "You can select up to 5 options"),

  job_titles: Yup.array()
    .of(Yup.string().required("Required"))
    .max(5, "You can select up to 5 options"),

  job_functions: Yup.array()
    .of(Yup.string().required("Required"))
    .max(5, "You can select up to 5 options"),

  pain_points: Yup.array()
    .of(
      Yup.string()
        .min(20, "Please answer with at least 20 characters")
        .max(200, "Please answer with at most 200 characters")
        .required("Required")
    )
    .min(1, "Required")
    .required("Required"),

  desires: Yup.array()
    .of(
      Yup.string()
        .min(20, "Please answer with at least 20 characters")
        .max(200, "Please answer with at most 200 characters")
        .required("Required")
    )
    .min(1, "Required")
    .required("Required"),
});

export const brandVoiceValidationSchema = Yup.object().shape({
  brand_voice: Yup.string()
    .max(250, "Please answer with at most 250 characters")
    .nullable(),

  not_brand_voice: Yup.string()
    .max(250, "Please answer with at most 250 characters")
    .nullable(),

  formality_tone: Yup.string().required("Required"),

  emotions: Yup.array()
    .of(Yup.string())
    .min(1, "Select at least one options")
    .required("Required"),

  styles: Yup.array()
    .of(Yup.string())
    .min(1, "Select at least one option")
    .required("Required"),

  core_themes: Yup.array().of(Yup.string()),
});

export const competitionValidationSchema = Yup.object().shape({
  competitors: Yup.array()
    .of(
      Yup.object()
        .shape({
          name: Yup.string()
            .max(100, "Name must be at most 100 characters")
            .nullable(),
          website: Yup.string()
            .transform((value) => {
              // Add `http://` if the URL does not start with `http://` or `https://`
              if (value && !/^https?:\/\//i.test(value)) {
                return `http://${value}`;
              }
              return value;
            })
            .url("Please enter a valid URL")
            .nullable(),
        })
        .nullable()
    )
    .max(4, "You can select up to 4 competitors"),
});

export const globalValidationSchema = Yup.object().shape({
  country: Yup.string().required("Required"),
  secondary_countries: Yup.array()
    .of(Yup.string())
    .max(4, "You can select up to 4 countries")
    .optional(),
});

export const topicsValidationSchema = Yup.object().shape({
  topics: Yup.array()
    .of(
      Yup.string().test(
        "word-count",
        "Each topic must be at most 5 words",
        (value) => value && value.split(" ").filter(Boolean).length <= 5
      )
    )
    .min(3, "Please provide at least 3 topics")
    .max(20, "Please provide at most 20 topics")
    .required("Topics are required"),
});

// Function to get the validation schema based on the active step
export const getValidationSchema = (activeStep) => {
  switch (activeStep) {
    case 0:
      return businessValidationSchema;
    case 1:
      return personaValidationSchema;
    case 2:
      return brandVoiceValidationSchema;
    case 3:
      return competitionValidationSchema;
    case 4:
      return topicsValidationSchema;
    case 5:
      return globalValidationSchema;

    default:
      return Yup.object();
  }
};
