/* eslint-disable */
import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
  FormControl,
  useTheme,
  alpha,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { AddCircleOutline } from "@mui/icons-material";
import CustomFilterIcon from "../../../customIcons/CustomFilterIcon";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const operators = {
  text: [
    { value: "contains", label: "Contains" },
    { value: "exact", label: "Exact Match" },
    { value: "startswith", label: "Starts With" },
  ],
  number: [
    { value: "equals", label: "Equals" },
    { value: "gte", label: "Minimum Value" },
    { value: "lte", label: "Maximum Value" },
  ],
  duration: [
    { value: "equals", label: "Equals" },
    { value: "gte", label: "Minimum Value" },
    { value: "lte", label: "Maximum Value" },
  ],
  choice: [{ value: "equals", label: "Equals" }],
  date: [
    { value: "after", label: "After" },
    { value: "before", label: "Before" },
  ],
};

const timeUnits = [
  { value: "seconds", label: "Seconds" },
  { value: "minutes", label: "Minutes" },
  { value: "hours", label: "Hours" },
];

const FilterPanel = ({ onApplyFilters, filterFields = [] }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [timeUnitSelections, setTimeUnitSelections] = useState({});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddFilter = () => {
    setFilters([
      ...filters,
      { id: Date.now(), field: "", operator: "", value: "" },
    ]);
  };

  const handleFilterChange = (id, field, value) => {
    setFilters(
      filters.map((filter) => {
        if (filter.id === id) {
          const updatedFilter = { ...filter, [field]: value };
          if (field === "field") {
            updatedFilter.operator = "";
            updatedFilter.value = "";

            if (getFieldType(value) !== "duration") {
              setTimeUnitSelections((prev) => {
                const newSelections = { ...prev };
                delete newSelections[id];
                return newSelections;
              });
            } else {
              setTimeUnitSelections((prev) => ({
                ...prev,
                [id]: "seconds",
              }));
            }
          }
          return updatedFilter;
        }
        return filter;
      })
    );
  };

  const handleRemoveFilter = (id) => {
    setFilters(filters.filter((filter) => filter.id !== id));
  };

  const handleTimeUnitChange = (id, unit) => {
    setTimeUnitSelections((prev) => ({
      ...prev,
      [id]: unit,
    }));
  };

  const handleApplyFilters = () => {
    const validFilters = filters
      .filter((filter) => filter.field && filter.operator && filter.value)
      .map((filter) => {
        if (
          getFieldType(filter.field) === "duration" &&
          timeUnitSelections[filter.id]
        ) {
          return {
            ...filter,
            timeUnit: timeUnitSelections[filter.id],
            originalValue: filter.value,
          };
        }
        return filter;
      });

    setActiveFilters(validFilters);
    onApplyFilters(validFilters);
    handleClose();
  };

  const handleClearFilters = () => {
    setFilters([]);
    setActiveFilters([]);
    onApplyFilters([]);
    handleClose();
  };

  const handleRemoveActiveFilter = (id) => {
    const newActiveFilters = activeFilters.filter((filter) => filter.id !== id);
    setActiveFilters(newActiveFilters);
    setFilters(filters.filter((filter) => filter.id !== id));
    onApplyFilters(newActiveFilters);
  };

  const getFieldType = (fieldName) => {
    const field = filterFields.find((f) => f.value === fieldName);
    return field ? field.type : "text";
  };

  const getFieldLabel = (fieldName) => {
    const field = filterFields.find((f) => f.value === fieldName);
    return field ? field.label : fieldName;
  };

  const getOperatorLabel = (fieldType, operatorValue) => {
    const operator = operators[fieldType].find(
      (op) => op.value === operatorValue
    );
    return operator ? operator.label : operatorValue;
  };

  const getValueLabel = (fieldName, value, timeUnit) => {
    const field = filterFields.find((f) => f.value === fieldName);
    if (field && field.type === "choice") {
      const option = field.options.find((opt) => opt.value === value);
      return option ? option.label : value;
    }

    if (field && field.type === "duration" && timeUnit) {
      return `${value} ${timeUnit}`;
    }

    return value;
  };

  const renderFilterValueInput = (filter) => {
    const fieldType = getFieldType(filter.field);

    if (fieldType === "choice") {
      const field = filterFields.find((f) => f.value === filter.field);
      return (
        <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
          <Select
            IconComponent={ExpandMoreOutlinedIcon}
            value={filter.value}
            onChange={(e) =>
              handleFilterChange(filter.id, "value", e.target.value)
            }
            displayEmpty
            sx={{
              backgroundColor: theme.palette.background.tertiary,
              borderRadius: "10px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "10px",
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: "none",
                  boxShadow: "none",
                },
              "&.Mui-focused, & .MuiOutlinedInput-root.Mui-focused": {
                outline: "none",
                boxShadow: "none",
              },
              "& .MuiSelect-icon": {
                color: theme.palette.text.tertiary,
              },
              "&:hover": {
                backgroundColor: theme.palette.background.tertiaryhover,
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.background.tertiary,
                  borderRadius: "10px",
                  "& .MuiMenuItem-root": {
                    color: theme.palette.text.primary,
                  },
                },
              },
              slotProps: {
                paper: {
                  style: { marginTop: 2 },
                },
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }}
          >
            <MenuItem value="" disabled>
              Select a value
            </MenuItem>
            {field?.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else if (fieldType === "date") {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={filter.value ? dayjs(filter.value) : null}
            onChange={(newValue) => {
              handleFilterChange(
                filter.id,
                "value",
                newValue ? newValue.format("YYYY-MM-DD") : ""
              );
            }}
            slotProps={{
              textField: {
                size: "small",
                sx: {
                  backgroundColor: theme.palette.background.tertiary,
                  borderRadius: "10px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "10px",
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: "none",
                      boxShadow: "none",
                    },
                  "&.Mui-focused, & .MuiOutlinedInput-root.Mui-focused": {
                    outline: "none",
                    boxShadow: "none",
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme.palette.text.primary,
                  },
                  "& .MuiInputBase-input": {
                    color: theme.palette.text.primary,
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: theme.palette.text.tertiary,
                    opacity: 1,
                  },
                },
              },
              popper: {
                sx: {
                  "& .MuiPaper-root": {
                    backgroundColor: theme.palette.background.secondary,
                    borderRadius: "10px",
                    boxShadow: theme.shadows[3],
                    padding: "8px",
                  },
                  "& .MuiPickersDay-root": {
                    color: theme.palette.text.primary,
                    "&:hover": {
                      backgroundColor: theme.palette.background.quaternary,
                    },
                    "&.Mui-selected": {
                      backgroundColor: theme.palette.background.brand,
                      color: theme.palette.text.primary,
                    },
                  },
                  "& .MuiTypography-root": {
                    color: theme.palette.text.primary,
                  },
                  "& .MuiPickersCalendarHeader-root": {
                    color: theme.palette.text.primary,
                  },
                  "& .MuiPickersArrowSwitcher-root .MuiButtonBase-root": {
                    color: theme.palette.text.primary,
                  },
                  "& .MuiPickersCalendarHeader-switchViewButton": {
                    color: theme.palette.text.primary,
                  },
                  "& .MuiPickersYear-root": {
                    color: theme.palette.text.primary,
                  },
                  "& .MuiPickersYear-yearButton": {
                    color: theme.palette.text.primary,
                    "&.Mui-selected": {
                      backgroundColor: theme.palette.background.brand,
                      color: theme.palette.text.primary,
                    },
                  },
                  "& .Mui-selected": {
                    backgroundColor:
                      theme.palette.background.brand + " !important",
                  },
                  "& .MuiTypography-caption": {
                    color: theme.palette.text.tertiary,
                  },
                },
              },
            }}
          />
        </LocalizationProvider>
      );
    } else if (fieldType === "duration") {
      return (
        <Box sx={{ display: "flex", gap: 1 }}>
          <TextField
            type="number"
            size="small"
            value={filter.value}
            onChange={(e) =>
              handleFilterChange(filter.id, "value", e.target.value)
            }
            placeholder="Enter value"
            sx={{
              backgroundColor: theme.palette.background.tertiary,
              borderRadius: "10px",
              width: "100px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "10px",
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: "none",
                  boxShadow: "none",
                },
              "&.Mui-focused, & .MuiOutlinedInput-root.Mui-focused": {
                outline: "none",
                boxShadow: "none",
              },
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
          />
          <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
            <Select
              IconComponent={ExpandMoreOutlinedIcon}
              value={timeUnitSelections[filter.id] || "seconds"}
              onChange={(e) => handleTimeUnitChange(filter.id, e.target.value)}
              displayEmpty
              sx={{
                backgroundColor: theme.palette.background.tertiary,
                borderRadius: "10px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: "none",
                    boxShadow: "none",
                  },
                "&.Mui-focused, & .MuiOutlinedInput-root.Mui-focused": {
                  outline: "none",
                  boxShadow: "none",
                },
                "& .MuiSelect-icon": {
                  color: theme.palette.text.tertiary,
                },
                "&:hover": {
                  backgroundColor: theme.palette.background.tertiaryhover,
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: theme.palette.background.tertiary,
                    borderRadius: "10px",
                    "& .MuiMenuItem-root": {
                      color: theme.palette.text.primary,
                    },
                  },
                },
                slotProps: {
                  paper: {
                    style: { marginTop: 2 },
                  },
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
            >
              {timeUnits.map((unit) => (
                <MenuItem key={unit.value} value={unit.value}>
                  {unit.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      );
    } else if (fieldType === "number") {
      return (
        <TextField
          type="number"
          size="small"
          value={filter.value}
          onChange={(e) =>
            handleFilterChange(filter.id, "value", e.target.value)
          }
          placeholder="Enter value"
          sx={{
            backgroundColor: theme.palette.background.tertiary,
            borderRadius: "10px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "10px",
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: "none",
                boxShadow: "none",
              },
            "&.Mui-focused, & .MuiOutlinedInput-root.Mui-focused": {
              outline: "none",
              boxShadow: "none",
            },
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          }}
        />
      );
    } else {
      return (
        <TextField
          size="small"
          value={filter.value}
          onChange={(e) =>
            handleFilterChange(filter.id, "value", e.target.value)
          }
          placeholder="Enter value"
          sx={{
            backgroundColor: theme.palette.background.tertiary,
            borderRadius: "10px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "10px",
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: "none",
                boxShadow: "none",
              },
            "&.Mui-focused, & .MuiOutlinedInput-root.Mui-focused": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiSelect-icon": {
              color: theme.palette.text.tertiary,
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                backgroundColor: theme.palette.background.tertiary,
                borderRadius: "10px",
                "& .MuiMenuItem-root": {
                  color: theme.palette.text.primary,
                },
              },
            },
            slotProps: {
              paper: {
                style: { marginTop: 1 },
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        />
      );
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
        <Button
          variant="contained"
          disableRipple
          disableElevation
          startIcon={<CustomFilterIcon />}
          onClick={handleClick}
          sx={{
            textTransform: "none",
            color: theme.palette.text.tertiary,
            border: "none",
            backgroundColor: theme.palette.background.tertiary,
            borderRadius: "10px",
            "&:hover": {
              backgroundColor: theme.palette.background.tertiaryhover,
            },
          }}
        >
          Filter
          {activeFilters.length > 0 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: alpha(theme.palette.background.brand, 0.2),
                color: theme.palette.text.brandbold,
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                marginLeft: "8px",
                fontSize: "12px",
              }}
            >
              {activeFilters.length}
            </Box>
          )}
        </Button>

        {activeFilters.length > 0 && (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {activeFilters.map((filter) => (
              <Chip
                key={filter.id}
                label={`${getFieldLabel(filter.field)} ${getOperatorLabel(
                  getFieldType(filter.field),
                  filter.operator
                )} ${getValueLabel(
                  filter.field,
                  filter.value,
                  filter.timeUnit
                )}`}
                onDelete={() => handleRemoveActiveFilter(filter.id)}
                sx={{
                  backgroundColor: theme.palette.background.tertiary,
                  color: theme.palette.text.primary,
                  "& .MuiChip-deleteIcon": {
                    color: theme.palette.text.primary,
                  },
                  "&:hover": {
                    backgroundColor: theme.palette.background.tertiaryhover,
                  },
                }}
              />
            ))}
            {activeFilters.length > 0 && (
              <Chip
                label="Clear Filters"
                onClick={handleClearFilters}
                sx={{
                  backgroundColor: theme.palette.background.tertiary,
                  color: theme.palette.text.primary,
                  "&:hover": {
                    backgroundColor: theme.palette.background.tertiaryhover,
                  },
                }}
              />
            )}
          </Box>
        )}
      </Box>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              width: 610,
              backgroundColor: theme.palette.background.secondary,
            },
          },
        }}
      >
        <Typography variant="h6" gutterBottom>
          Filters
        </Typography>

        {filters.length === 0 ? (
          <Typography variant="body2" color="text.secondary" gutterBottom>
            No filters added. Click "Add Filter" to create a filter.
          </Typography>
        ) : (
          <Box sx={{ mb: 2 }}>
            {filters.map((filter) => (
              <Box
                key={filter.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  mb: 1,
                  flexWrap: "wrap",
                }}
              >
                <FormControl
                  variant="outlined"
                  size="small"
                  sx={{ minWidth: 150 }}
                >
                  <Select
                    variant="outlined"
                    IconComponent={ExpandMoreOutlinedIcon}
                    value={filter.field}
                    onChange={(e) =>
                      handleFilterChange(filter.id, "field", e.target.value)
                    }
                    displayEmpty
                    sx={{
                      backgroundColor: theme.palette.background.tertiary,
                      borderRadius: "10px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "10px",
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: "none",
                          boxShadow: "none",
                        },
                      "&.Mui-focused, & .MuiOutlinedInput-root.Mui-focused": {
                        outline: "none",
                        boxShadow: "none",
                      },
                      "& .MuiSelect-icon": {
                        color: theme.palette.text.tertiary,
                      },
                      "&:hover": {
                        backgroundColor: theme.palette.background.tertiaryhover,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          backgroundColor: theme.palette.background.tertiary,
                          borderRadius: "10px",
                          "& .MuiMenuItem-root": {
                            color: theme.palette.text.primary,
                          },
                        },
                      },
                      slotProps: {
                        paper: {
                          style: { marginTop: 2 },
                        },
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select a field
                    </MenuItem>
                    {filterFields.map((field) => (
                      <MenuItem key={field.value} value={field.value}>
                        {field.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {filter.field && (
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: 150 }}
                  >
                    <Select
                      IconComponent={ExpandMoreOutlinedIcon}
                      value={filter.operator}
                      onChange={(e) =>
                        handleFilterChange(
                          filter.id,
                          "operator",
                          e.target.value
                        )
                      }
                      displayEmpty
                      sx={{
                        backgroundColor: theme.palette.background.tertiary,
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "10px",
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: "none",
                            boxShadow: "none",
                          },
                        "&.Mui-focused, & .MuiOutlinedInput-root.Mui-focused": {
                          outline: "none",
                          boxShadow: "none",
                        },
                        "& .MuiSelect-icon": {
                          color: theme.palette.text.tertiary,
                        },
                        "&:hover": {
                          backgroundColor:
                            theme.palette.background.tertiaryhover,
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            backgroundColor: theme.palette.background.tertiary,
                            borderRadius: "10px",
                            "& .MuiMenuItem-root": {
                              color: theme.palette.text.primary,
                            },
                          },
                        },
                        slotProps: {
                          paper: {
                            style: { marginTop: 2 },
                          },
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select an operator
                      </MenuItem>
                      {operators[getFieldType(filter.field)] &&
                        operators[getFieldType(filter.field)].map((op) => (
                          <MenuItem key={op.value} value={op.value}>
                            {op.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}

                {filter.field &&
                  filter.operator &&
                  renderFilterValueInput(filter)}

                <IconButton
                  size="small"
                  onClick={() => handleRemoveFilter(filter.id)}
                  sx={{
                    color: theme.palette.text.primary,
                    "&:hover": {
                      backgroundColor: theme.palette.background.tertiaryhover,
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Button
            variant="contained"
            disableRipple
            disableElevation
            startIcon={<AddCircleOutline />}
            onClick={handleAddFilter}
            sx={{
              textTransform: "none",
              borderColor: theme.palette.background.tertiary,
              backgroundColor: theme.palette.background.tertiary,
              color: theme.palette.text.primary,
              fontWeight: "light",
              padding: "9px 15px",
              borderRadius: "11px",
              "&:hover": {
                backgroundColor: theme.palette.background.tertiaryhover,
              },
            }}
          >
            Add Filter
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Button
            variant="contained"
            disableRipple
            disableElevation
            onClick={handleApplyFilters}
            disabled={
              filters.length === 0 ||
              !filters.some(
                (filter) => filter.field && filter.operator && filter.value
              )
            }
            sx={{
              textTransform: "none",
              borderRadius: "10px",
              fontWeight: "light",
              backgroundColor: theme.palette.background.brand,
              color: theme.palette.text.primary,
              minWidth: "120px",
              "&.Mui-disabled": {
                backgroundColor: theme.palette.background.tertiary,
                color: theme.palette.text.tertiary,
                opacity: 0.7,
              },
              "&:hover": {
                backgroundColor: theme.palette.background.brandhover,
              },
            }}
          >
            Apply now
          </Button>
          <Button
            variant="contained"
            disableRipple
            disableElevation
            onClick={handleClearFilters}
            sx={{
              textTransform: "none",
              color: theme.palette.text.primary,
              borderRadius: "10px",
              fontWeight: "light",
              backgroundColor: theme.palette.background.tertiary,
              minWidth: "130px",
              "&:hover": {
                backgroundColor: theme.palette.background.tertiaryhover,
              },
            }}
          >
            Clear all filters
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};

export default FilterPanel;
