import { Grid, useTheme } from "@mui/material";
import { GlobalFiltersProvider } from "../../../../contexts/GlobalFiltersContext";
import PageLayout from "../../../AEO/components/PageLayout/PageLayout";
import OverAllCard from "../../components/OverAllCard";
import LineChartCard from "../../../AEO/components/LineChartCard/LineChartCard";
import VisibilityIcon from "../../../../CommonComponents/customIcons/VisibilityIcon";
import AICrawlerBreakdownCard from "./components/AICrawlerBreakdownCard";
import PageCrawlTable from "./components/PageCrawlTable/PageCrawlTable";

const AIAgents = () => {
  const theme = useTheme();
  return (
    <GlobalFiltersProvider>
      <PageLayout
        title="Agents & Crawlers"
        description="Track AI crawler activity across your site, analyzing crawl visits, indexing patterns, response times, and technical performance to optimize discoverability."
        hasGlobalFilters
        hasLLMSelect={false}
        isAgentAnalytics
      >
        <Grid container spacing={2.5}>
          <Grid item xs={3}>
            <OverAllCard
              title="Total AI Crawl Visits"
              description="Organic revenue:"
              tooltipMessagePrimary="tooltip"
              value="1345"
              movement={34}
              isIncrease
            />
          </Grid>
          <Grid item xs={3}>
            <OverAllCard
              title="Average Crawl Rate"
              description="Organic revenue:"
              tooltipMessagePrimary="tooltip"
              value="240"
              movement={52}
              isIncrease
              percentageValue={false}
            />
          </Grid>{" "}
          <Grid item xs={3}>
            <OverAllCard
              title="Number of Pages Indexed"
              description="Organic revenue:"
              tooltipMessagePrimary="tooltip"
              value="$24K"
              movement={34}
              percentageValue={false}
            />
          </Grid>{" "}
          <Grid item xs={3}>
            <OverAllCard
              title="Average Response Time"
              description="Organic revenue:"
              tooltipMessagePrimary="tooltip"
              value="17000"
              movement={0.5}
              isIncrease
            />
          </Grid>
        </Grid>

        <LineChartCard
          icon={<VisibilityIcon color={theme.palette.text.primary} />}
          title="AI Crawler Activity"
          tooltipPrimary="Tooltip"
          tooltipSecondary="Tooltip"
          height={"100%"}
          chartHasXasix
          chartHasYaxis
          chartHeight={200}
          chartHasTooltip
          chartHasMarginTop={20}
          chartHasMarginLeft={-30}
          chartHasGrid
        />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <AICrawlerBreakdownCard />
          </Grid>
        </Grid>

        <PageCrawlTable />
      </PageLayout>
    </GlobalFiltersProvider>
  );
};
export default AIAgents;
