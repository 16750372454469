import { Typography, useTheme } from "@mui/material";
import { LLMsMap } from "../../../../../../../AEO/utils/DataUtils";

const LLMModelTypography = ({ llm }) => {
  const theme = useTheme();
  const { icon, color: backgroundColor } = LLMsMap[llm] || {};
  return (
    <Typography
      sx={{
        color: theme.palette.text.chiptext,
        backgroundColor: theme.palette.llms?.[backgroundColor],
        display: "inline-flex",
        gap: 0.5,
        alignItems: "center",
        borderRadius: "30px",
        padding: "1px 10px 1px 6px",
        whiteSpace: "nowrap",
        width: "fit-content",
        maxWidth: "100%",
        fontSize: "14px",
      }}
    >
      <img src={icon} style={{ width: 14, height: 14 }} alt={llm} /> {llm}
    </Typography>
  );
};
export default LLMModelTypography;
