import React from "react";

const EmailIcon = ({ color = "#AFB1B7", width, height }) => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="23" cy="23" r="22.5" fill="#8DA3FA" fill-opacity="0.2" />
    <path
      d="M15.29 22.0004H13.29C13.0248 22.0004 12.7705 22.1057 12.5829 22.2933C12.3954 22.4808 12.29 22.7352 12.29 23.0004C12.29 23.2656 12.3954 23.52 12.5829 23.7075C12.7705 23.895 13.0248 24.0004 13.29 24.0004H15.29C15.5553 24.0004 15.8096 23.895 15.9971 23.7075C16.1847 23.52 16.29 23.2656 16.29 23.0004C16.29 22.7352 16.1847 22.4808 15.9971 22.2933C15.8096 22.1057 15.5553 22.0004 15.29 22.0004ZM32.07 19.1604V19.1004C31.7835 18.7502 31.4215 18.4693 31.0111 18.2788C30.6007 18.0883 30.1525 17.9931 29.7 18.0004H21.77C21.0352 17.9938 20.3236 18.2571 19.77 18.7404C19.253 19.1959 18.9131 19.8191 18.81 20.5004L17.93 25.5004C17.8544 25.9322 17.8742 26.3753 17.9883 26.7986C18.1023 27.2219 18.3077 27.615 18.59 27.9504C18.8709 28.2846 19.2213 28.5535 19.6168 28.7383C20.0124 28.9231 20.4435 29.0194 20.88 29.0204H28.82C29.5333 29.0312 30.227 28.7874 30.7768 28.333C31.3266 27.8785 31.6965 27.2429 31.82 26.5404L32.7 21.5404C32.7693 21.1217 32.749 20.693 32.6404 20.2828C32.5318 19.8725 32.3374 19.49 32.07 19.1604ZM29.33 20.0004L25.93 22.7604C25.729 22.9224 25.4731 23.0004 25.2159 22.978C24.9587 22.9557 24.7201 22.8347 24.55 22.6404L22.22 20.0004H29.33ZM29.81 26.1704C29.7696 26.4052 29.6466 26.6178 29.4632 26.77C29.2799 26.9221 29.0483 27.0038 28.81 27.0004H20.88C20.7348 26.9992 20.5916 26.9663 20.4604 26.9042C20.3291 26.842 20.213 26.752 20.12 26.6404C20.0269 26.5294 19.9589 26.3994 19.9209 26.2596C19.883 26.1197 19.8758 25.9733 19.9 25.8304L20.7 21.3004L23.05 23.9604C23.5615 24.5418 24.278 24.9028 25.0496 24.968C25.8212 25.0333 26.5882 24.7976 27.19 24.3104L30.63 21.5004L29.81 26.1704ZM16.29 18.0004H13.29C13.0248 18.0004 12.7705 18.1057 12.5829 18.2933C12.3954 18.4808 12.29 18.7352 12.29 19.0004C12.29 19.2656 12.3954 19.52 12.5829 19.7075C12.7705 19.895 13.0248 20.0004 13.29 20.0004H16.29C16.5553 20.0004 16.8096 19.895 16.9971 19.7075C17.1847 19.52 17.29 19.2656 17.29 19.0004C17.29 18.7352 17.1847 18.4808 16.9971 18.2933C16.8096 18.1057 16.5553 18.0004 16.29 18.0004Z"
      fill="#C6D1FD"
    />
  </svg>
);

export default EmailIcon;
