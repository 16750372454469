import { Avatar, Box, Menu, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState, useEffect } from "react";
import ProfilePopup from "./components/ProfilePopup/ProfilePopup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation, Link, useParams } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import { getInitials } from "../../utils/getInitials";
const useStyles = makeStyles((theme) => ({
  fixedHeader: {
    position: "fixed",
    width: "100%",
    top: 0,
    zIndex: 1000,
    backgroundColor: theme.palette.background.primary,
    paddingTop: 18,
    paddingBottom: 20,
    borderBottom: "none",
    marginBottom: 128,
    display: "flex",
    justifyContent: "space-between",
  },
  workspace: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.tertiary,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
  title: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
}));
const Header = ({ isCollapsed }) => {
  const classes = useStyles();
  const location = useLocation();
  const { brandId, title, blog_id } = useParams();
  const [workspaceName, setWorkspaceName] = useState("");
  useEffect(() => {
    const fetchWorkspaceName = () => {
      const workspaceRaw = localStorage.getItem("workspace");
      if (workspaceRaw) {
        try {
          const workspaceData = JSON.parse(workspaceRaw);
          setWorkspaceName(workspaceData?.name || "Default Workspace");
        } catch (error) {
          setWorkspaceName("Default Workspace");
        }
      }
    };
    fetchWorkspaceName();
    const intervalId = setInterval(fetchWorkspaceName);
    return () => clearInterval(intervalId);
  }, []);

  const normalizedPath = location.pathname.replace(/\/+$/, "");

  const PageURL = {
    [`/dashboard/${brandId}`]: {
      title: ` Dashboard`,
      parent: {
        workspace: `${workspaceName}`,
      },
    },
    [`/content-writer/${brandId}`]: {
      title: `AI Content Writer`,
    },
    [`/workspace-settings/${brandId}/brand-dna-overview`]: {
      title: "Brand DNA",
      parent: {
        workspace: `${workspaceName}`,
        title: ` Workspace Settings`,
        link: `/workspace-settings/${brandId}`,
      },
    },
    [`/workspace-settings/${brandId}`]: {
      title: ` Workspace Settings`,
      parent: {
        workspace: `${workspaceName}`,
      },
    },
    [`/workspace-settings/${brandId}/knowledge-base`]: {
      title: "Knowledge Base",
      parent: {
        title: ` Workspace Settings`,
        workspace: `${workspaceName}`,
        link: `/workspace-settings/${brandId}`,
      },
    },
    [`/workspace-settings/${brandId}/people`]: {
      title: "People",
      parent: {
        title: ` Workspace Settings`,
        workspace: `${workspaceName}`,
        link: `/workspace-settings/${brandId}`,
      },
    },
    [`/workspace-settings/${brandId}/general-settings`]: {
      title: "General",
      parent: {
        title: ` Workspace Settings`,
        workspace: `${workspaceName}`,
        link: `/workspace-settings/${brandId}`,
      },
    },
    [`/workspace-settings/${brandId}/connect-accounts`]: {
      title: "Connect Accounts",
      parent: {
        title: ` Workspace Settings`,
        workspace: `${workspaceName}`,
        link: `/workspace-settings/${brandId}`,
      },
    },
    [`/account/author-stamp`]: {
      title: "Personal Settings",
    },
    [`/ai-visibility/${brandId}/dashboard`]: {
      title: "Dashboard",
      parent: {
        title: `AI Visibility`,
        workspace: `${workspaceName}`,
      },
    },
    [`/ai-visibility/${brandId}/visibility-analysis`]: {
      title: "Visibility Analysis",
      parent: {
        title: `AI Visibility`,
        workspace: `${workspaceName}`,
        link: `/ai-visibility/${brandId}/dashboard`,
      },
    },
    [`/ai-visibility/${brandId}/performance-analysis`]: {
      title: "Performance Analysis",
      parent: {
        title: `AI Visibility`,
        workspace: `${workspaceName}`,
        link: `/ai-visibility/${brandId}/dashboard`,
      },
    },
    [`/ai-visibility/${brandId}/sentiment-analysis`]: {
      title: "Sentiment Analysis",
      parent: {
        title: `AI Visibility`,
        workspace: `${workspaceName}`,
        link: `/ai-visibility/${brandId}/dashboard`,
      },
    },
    [`/ai-visibility/${brandId}/comparison-analysis`]: {
      title: "Comparison Analysis",
      parent: {
        title: `AI Visibility`,
        workspace: `${workspaceName}`,
        link: `/ai-visibility/${brandId}/dashboard`,
      },
    },
    [`/ai-visibility/${brandId}/global-monitoring`]: {
      title: "Global Monitoring",
      parent: {
        title: `AI Visibility`,
        workspace: `${workspaceName}`,
        link: `/ai-visibility/${brandId}/dashboard`,
      },
    },
  };
  const currentDashboardTab =
    PageURL[normalizedPath] ||
    PageURL[`/dashboard/${brandId}/blog/${title}/${blog_id}`] ||
    (title
      ? {
          title: `${title}`,
          parent: {
            workspace: `${workspaceName}`,
          },
        }
      : "Dashboard");
  const { loggedInUser } = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{ pl: { xs: 3, md: 7 }, pr: { xs: 1, md: isCollapsed ? 20 : 40 } }}
      className={classes.fixedHeader}
    >
      <Typography>
        {currentDashboardTab.parent && (
          <>
            {currentDashboardTab.parent.workspace && (
              <>
                <Link to={`/dashboard/${brandId}`} className={classes.link}>
                  {currentDashboardTab.parent.workspace}
                </Link>
                {currentDashboardTab.parent.title && (
                  <span className={classes.link}> / </span>
                )}
              </>
            )}
            {currentDashboardTab.parent.title && (
              <Link
                to={currentDashboardTab.parent.link || "#"}
                className={classes.link}
              >
                {currentDashboardTab.parent.title}
              </Link>
            )}
            <span className={classes.link}> / </span>
          </>
        )}
        <span className={classes.title}> {currentDashboardTab.title}</span>
      </Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: 10,
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <Avatar
          sx={{
            width: "36px",
            height: "36px",
            backgroundColor: "background.secondary",
            color: "text.tertiary",
            textAlign: "center",
            fontSize: 20.25,
            fontWeight: 400,
          }}
          src={loggedInUser?.profile?.profile_picture ?? ""}
          alt={getInitials(loggedInUser.first_name)}
        >
          {getInitials(loggedInUser.first_name)}
        </Avatar>
        <Typography
          sx={{
            fontSize: 14,
            color: "text.primary",
            fontWeight: 500,
          }}
        >
          {`${loggedInUser.first_name} ${loggedInUser.last_name}`}
        </Typography>

        <ExpandMoreIcon />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            top: "75px !important",
            right: "0px !important",
            backgroundColor: "background.secondary",
            width: "fit-content",
          },
        }}
      >
        <ProfilePopup />
      </Menu>
    </Box>
  );
};
export default Header;
