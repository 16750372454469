import { Box, Tooltip, Typography, useTheme } from "@mui/material";

const TopicTableCell = ({ topic }) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={
        <div>
          <div style={{ whiteSpace: "pre-wrap" }}>{topic}</div>
        </div>
      }
    >
      <Box
        sx={{
          display: "flex",
          cursor: "default",
          width: "100%",
        }}
      >
        <Typography
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "100%",
            backgroundColor: theme.palette.background.tertiary,
            borderRadius: "30px",
            padding: "5px 18px",
          }}
        >
          {topic}
        </Typography>
      </Box>
    </Tooltip>
  );
};
export default TopicTableCell;
