import { CircularProgress, Grid, useTheme, Typography } from "@mui/material";
import WorkSpaceCard from "./components/Card/Card";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import WorkspacePageTemplate from "./components/PageTemplate/PageTemplate";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import PeopleIcon from "../../CommonComponents/customIcons/PeopleIcon";
import KnowledgeBaseIcon from "../../CommonComponents/customIcons/KnowledgeBaseIcon";
import DNA from "../../CommonComponents/customIcons/DNA";
import { endpoints } from "../../services/api/endpoints";
import StepperModal from "../../CommonComponents/StepperModal";
import Business from "../BrandDNA/components/BrandDNAForm/components/Business";
import Persona from "../BrandDNA/components/BrandDNAForm/components/Persona";
import BrandVoice from "../BrandDNA/components/BrandDNAForm/components/BrandVoice/BrandVoice";
import Competition from "../BrandDNA/components/BrandDNAForm/components/Competition/Competition";
import Topics from "../BrandDNA/components/BrandDNAForm/components/Topics";
import Global from "../BrandDNA/components/BrandDNAForm/components/Global";
import { getValidationSchema, steps } from "../BrandDNA/conts";

const Workspace = () => {
  const { brandId } = useParams();
  const userId = JSON.parse(localStorage.getItem("loggedInUser")).id;
  const [is_admin, setIsAdmin] = useState();
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [initialStep, setInitialStep] = useState(0);
  const [brandDetails, setBrandDetails] = useState({});
  const [stepCompletionStatus, setStepCompletionStatus] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [isAutoSave, setIsAutoSave] = useState(false);
  const theme = useTheme();

  const fetchBrandDetails = useCallback(() => {
    return endpoints.brands
      .getOverview(brandId)
      .then((response) => {
        const brandData = response.data;
        setBrandDetails(brandData);
        localStorage.setItem("workspace", JSON.stringify(brandData));
        localStorage.setItem("worskapceName", brandData.name);

        const stepCompletionStatus = [
          brandData.business_step_completed,
          brandData.persona_step_completed,
          brandData.brand_voice_step_completed,
          brandData.competitors_step_completed,
          brandData.topics_step_completed,
          brandData.global_step_completed,
        ];

        localStorage.setItem(
          "stepCompletionStatus",
          JSON.stringify(stepCompletionStatus)
        );

        setStepCompletionStatus(stepCompletionStatus);
        return brandData;
      })
      .catch((err) => {
        console.error("Error fetching brand details:", err);
      });
  }, [brandId]);

  useEffect(() => {
    setLoading(true);
    endpoints.brands
      .checkAdmin(userId, brandId)
      .then((response) => {
        setIsAdmin(response.data.is_admin);
        return fetchBrandDetails();
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Error checking admin status:", err);
      });
  }, [userId, brandId, fetchBrandDetails]);

  useEffect(() => {
    if (openModal && !brandDetails.name) {
      fetchBrandDetails();
    }
  }, [openModal, brandDetails.name, fetchBrandDetails]);

  const getStepContent = useCallback(
    (step) => {
      switch (step) {
        case 0:
          return <Business brandDetails={brandDetails} />;
        case 1:
          return <Persona brandDetails={brandDetails} />;
        case 2:
          return <BrandVoice brandDetails={brandDetails} />;
        case 3:
          return <Competition brandDetails={brandDetails} />;
        case 4:
          return <Topics brandDetails={brandDetails} />;
        case 5:
          return <Global brandDetails={brandDetails} />;
        default:
          return <Business brandDetails={brandDetails} />;
      }
    },
    [brandDetails]
  );

  const initialValues = useMemo(
    () => ({
      // business
      name: brandDetails?.name,
      primary_product: brandDetails?.primary_product,
      description: brandDetails?.description,
      conversion_goals: brandDetails?.conversion_goals || [],
      industry: brandDetails?.industry || "",
      business_model: brandDetails?.business_model || "",
      features: brandDetails?.features || ["", ""],
      benefits: brandDetails?.benefits || ["", ""],
      market_focus: brandDetails?.market_focus || "",
      usp: brandDetails.usp || "",
      country: brandDetails?.country || "",
      primary_language: brandDetails.primary_language || "",
      website: brandDetails?.website || "",
      additional_websites: brandDetails?.additional_websites || [""],
      topics: brandDetails?.topics || ["", "", ""],
      market_attributes: brandDetails?.market_attributes || [""],
      comparison_attributes: brandDetails?.comparison_attributes || [""],
      secondary_countries: brandDetails?.secondary_countries || [],
      social_media_accounts_urls: brandDetails?.social_media_accounts_urls || [
        "",
      ],
      // persona
      age_ranges: brandDetails?.age_ranges || [],
      genders: brandDetails?.genders || [],
      interests: brandDetails?.interests || [],
      job_functions: brandDetails?.job_functions || [],
      job_titles: brandDetails?.job_titles || [],
      desires: brandDetails?.desires || ["", ""],
      pain_points: brandDetails?.pain_points || ["", ""],

      // brand voice
      brand_voice: brandDetails?.brand_voice || "",
      not_brand_voice: brandDetails?.not_brand_voice || "",
      formality_tone: brandDetails?.formality_tone || "",
      core_themes: brandDetails?.core_themes || [],
      emotions: brandDetails?.emotions || [],
      styles: brandDetails?.styles || [],

      // competitors
      competitors: brandDetails?.competitors || [{ name: "", website: "" }],
    }),
    [brandDetails]
  );

  const handleSave = useCallback(
    (values) => {
      !isAutoSave && setModalLoading(true);
      endpoints.brands
        .update(brandId, values)
        .then((response) => {
          if (!isAutoSave) {
            setModalLoading(false);
            window.location.href = `/dashboard/${brandId}/`;
          }
        })
        .catch((err) => {
          setModalLoading(false);
        });
    },
    [brandId, isAutoSave]
  );

  const handleCardClick = (card) => {
    if (card.title === "Brand DNA") {
      setOpenModal(true);
      setInitialStep(0);
    } else if (card.navigateTo) {
      window.location.href = `/workspace-settings/${brandId}/${card.navigateTo}`;
    }
  };

  // Use brandDetails as the source of truth for cards
  const cards = useMemo(
    () => [
      {
        icon: <SettingsOutlinedIcon color={theme.palette.text.primary} />,
        title: "General",
        description:
          "Manage your company data, connected services, and data sharing settings",
        isIncomplete: "",
        navigateTo: "general-settings",
      },
      {
        icon: (
          <DNA color={theme.palette.text.primary} width="24px" height="24px" />
        ),
        title: "Brand DNA",
        description:
          "Provide details about your brand to ensure personalized results",
        isIncomplete: !brandDetails.is_brand_dna_completed,
        navigateTo: "brand-dna-overview",
      },
      {
        icon: <LinkOutlinedIcon color={theme.palette.text.primary} />,
        title: "Integrations",
        description:
          "Enable deeper insights and optimizations with connected data.",
        isIncomplete: false,
        navigateTo: "connect-accounts",
      },
      {
        icon: <PeopleIcon color={theme.palette.text.primary} />,
        title: "People",
        description: "Invite, remove and manage your company's team members",
        isIncomplete: "",
        navigateTo: "people",
        requiresAdminAccess: true,
        adminAccess: is_admin,
      },
      {
        icon: <KnowledgeBaseIcon color={theme.palette.text.primary} />,
        title: "Knowledge Base",
        description:
          "Add writing samples for your workspace to reference when creating blogs",
        isIncomplete: "",
        navigateTo: "knowledge-base",
      },
    ],
    [brandDetails, is_admin, theme]
  );

  return (
    <WorkspacePageTemplate title="Workspace Settings">
      <Typography
        sx={{
          borderTop: `1px solid ${theme.palette.border.tertiary}`,
          pt: "20px",
          pb: "60px",
          fontSize: "14px",
          fontWeight: 450,
          lineHeight: "20px",
        }}
        color="text.tertiary"
      >
        All Settings
      </Typography>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "60%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{
              width: 20,
              height: 20,
              color: "#2D333A",
            }}
          />
        </div>
      ) : (
        <Grid container spacing={3} direction="row" alignItems="center">
          {cards.map((card, index) => {
            return (
              <Grid item xs={8} sm={4} md={4} key={index}>
                <WorkSpaceCard
                  card={card}
                  onClick={() => handleCardClick(card)}
                />
              </Grid>
            );
          })}
        </Grid>
      )}

      <StepperModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        setOpenModal={setOpenModal}
        modalName="Brand DNA"
        title={brandDetails.name || "Workspace name"}
        steps={steps}
        initialStep={initialStep}
        getStepContent={getStepContent}
        formikChildren={
          (Business, Persona, BrandVoice, Competition, Global, Topics)
        }
        initialValues={initialValues}
        getValidationSchema={getValidationSchema}
        handleSave={handleSave}
        loading={modalLoading}
        setIsAutoSave={setIsAutoSave}
        isAutoSave={isAutoSave}
        showSaveButton={true}
        isModal={true}
        stepCompletionStatus={stepCompletionStatus}
      />
    </WorkspacePageTemplate>
  );
};
export default Workspace;
