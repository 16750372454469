import {
  Step,
  StepLabel,
  Stepper,
  StepIcon,
  useTheme,
  Box,
  Typography,
  LinearProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import StepperCompletedIcon from "../customIcons/StepperCompletedIcon";
import CompetitionIcon from "../customIcons/CompetitionIcon";
import BusinessIcon from "../customIcons/BusinessIcon";
import PersonaIcon from "../customIcons/PersonaIcon";
import BranvoiceIcon from "../customIcons/BranvoiceIcon";
import GlobalDNAIcon from "../customIcons/GlobalDNAIcon";
import TopicsIcon from "../customIcons/TopicsIcon";
import BoxWithStatus from "../BoxWithStatus/BoxWithStatus";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiStepIcon-root": {
      color: "transparent",
      border: `1.5px solid ${theme.palette.border.tertiary}`,
      borderRadius: "50%",
      fontSize: 24,
    },
    "& .Mui-active .MuiStepIcon-root": {
      fill: theme.palette.stepper.stepCircle,
      border: "none",
    },
    "& .Mui-completed .MuiStepIcon-root": {
      fill: theme.palette.stepper.stepCircleCompleted,
      border: "none",
    },
    "& .MuiStepIcon-root .MuiStepIcon-text": {
      fill: theme.palette.text.secondary,
    },
    "& .MuiStepIcon-root.Mui-active .MuiStepIcon-text": {
      fill: theme.palette.text.primary,
    },
    "& .MuiStepIcon-root.Mui-completed .MuiSvgIcon-root": {
      color: theme.palette.text.primary,
    },
    "& .MuiStepLabel-label": {
      fontSize: 14,
      fontWeight: "500",
    },
    "& .MuiStepLabel-label span": {
      fontWeight: "500 ",
      color: theme.palette.text.primary,
    },
  },
}));

const CustomStepIcon = (props) => {
  const { completed, icon, isModal, active } = props;
  const theme = useTheme();

  const stepIcons = {
    1: (
      <BusinessIcon
        color={
          active ? theme.palette.text.primary : theme.palette.text.tertiary
        }
      />
    ),
    2: (
      <PersonaIcon
        color={
          active ? theme.palette.text.primary : theme.palette.text.tertiary
        }
      />
    ),
    3: (
      <BranvoiceIcon
        color={
          active ? theme.palette.text.primary : theme.palette.text.tertiary
        }
      />
    ),
    4: (
      <CompetitionIcon
        color={
          active ? theme.palette.text.primary : theme.palette.text.tertiary
        }
      />
    ),
    5: (
      <TopicsIcon
        color={
          active ? theme.palette.text.primary : theme.palette.text.tertiary
        }
      />
    ),
    6: (
      <GlobalDNAIcon
        color={
          active ? theme.palette.text.primary : theme.palette.text.tertiary
        }
      />
    ),
  };

  if (!isModal) {
    return completed ? <StepperCompletedIcon /> : <StepIcon {...props} />;
  }
  return stepIcons[icon] || <StepIcon {...props} />;
};

const CustomStepper = ({
  steps,
  activeStep,
  setActiveStep,
  hasClickableSteps,
  isModal,
  stepCompletionStatus,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const progressPercentage = ((activeStep + 1) / steps.length) * 100;

  return (
    <>
      <Stepper
        className={classes.root}
        activeStep={activeStep}
        orientation="vertical"
        connector={null}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            onClick={() =>
              hasClickableSteps
                ? setActiveStep(index)
                : index < activeStep && setActiveStep(index)
            }
            sx={{
              backgroundColor:
                activeStep === index
                  ? theme.palette.stepper.activeBackground
                  : "transparent",
              padding: isModal ? "0px 8px 0px 8px" : 2,
              paddingLeft: isModal ? 1 : 5,
              borderTop: isModal
                ? "none"
                : index === 0
                ? "1.5px solid"
                : "none",
              borderBottom: isModal ? "none" : "1.5px solid",
              borderColor:
                activeStep === index
                  ? theme.palette.stepper.activeBackground
                  : theme.palette.border.tertiary,
              cursor: "pointer",
              margin: isModal ? "0 25px 10px 25px " : 0,
              borderRadius: isModal ? "10px" : 0,
              "&:hover": {
                backgroundColor:
                  activeStep === index
                    ? theme.palette.stepper.activeBackground
                    : isModal
                    ? theme.palette.background.primaryhover
                    : "transparent",
              },
            }}
          >
            <StepLabel
              StepIconComponent={(props) => (
                <CustomStepIcon {...props} icon={index + 1} isModal={isModal} />
              )}
              sx={{
                "& .MuiStepLabel-label": {
                  fontWeight: 500,
                  color:
                    activeStep === index
                      ? theme.palette.text.primary
                      : isModal
                      ? theme.palette.text.tertiary
                      : theme.palette.text.secondary,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                },
              }}
            >
              {label}
              {stepCompletionStatus && !stepCompletionStatus[index] && (
                <BoxWithStatus isIncomplete={!stepCompletionStatus[index]} />
              )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {isModal && (
        <Box sx={{ margin: "auto 25px 40px 25px" }}>
          <Typography
            sx={{
              fontSize: 14,
              color: theme.palette.text.secondary,
              marginBottom: 1,
              lineHeight: "16px",
            }}
          >
            Step {activeStep + 1} of {steps.length}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={progressPercentage}
            sx={{
              height: 5,
              borderRadius: 5,
              backgroundColor: theme.palette.background.brand20,
              "& .MuiLinearProgress-bar": {
                backgroundColor: theme.palette.text.brand,
              },
            }}
          />
        </Box>
      )}
    </>
  );
};

export default CustomStepper;
