import { Grid, useTheme } from "@mui/material";
import {
  GlobalFiltersProvider,
  useGlobalFilters,
} from "../../../../contexts/GlobalFiltersContext";
import PageLayout from "../../../AEO/components/PageLayout/PageLayout";
import OverAllCards from "./components/OverallCards/OverallCards";
import LineChartCard from "../../../AEO/components/LineChartCard/LineChartCard";
import VisibilityIcon from "../../../../CommonComponents/customIcons/VisibilityIcon";
import ImpressionByModelCard from "./components/ImpressionByModelCard/ImpressionByModelCard";
import TrafficByCountry from "./components/TrafficByCountry/TrafficByCountry";
import TrafficByPlatform from "./components/TrafficByPlatform/TrafficByPlatform";
import InsightsSection from "./components/InsightsSection/InsightsSection";
import TrafficAnalyticsTable from "./components/TrafficAnalyticsTable";

// Separate content component that uses the context
const AITrafficContent = () => {
  const theme = useTheme();
  const { date, initialDate, llm } = useGlobalFilters();

  return (
    <PageLayout
      title="Traffic & Attribution"
      description="Analyze AI traffic and impressions. Track trends, compare performance against organic and direct channels, and gain insights."
      hasGlobalFilters
      hasLLMSelect={false}
      isAgentAnalytics
    >
      <OverAllCards />

      <LineChartCard
        icon={<VisibilityIcon color={theme.palette.text.primary} />}
        title="Traffic Trends"
        tooltipPrimary="A historical view of AI-driven sessions over time. Tracking trends helps you analyze patterns and measure the impact of AI visibility on your website traffic."
        height={"100%"}
        chartHasXasix
        chartHasYaxis
        chartHeight={200}
        chartHasTooltip
        chartHasMarginTop={20}
        chartHasMarginLeft={-25}
        chartHasGrid
        isAgentTrends
        hasCaledarSelect
      />
      <Grid
        container
        direction="row"
        sx={{
          mt: 4,
          justifyContent: "space-between",
          alignItems: "stretch",
        }}
      >
        <Grid item xs={3.8}>
          <ImpressionByModelCard />
        </Grid>

        <Grid item xs={7.9}>
          <TrafficByCountry />
        </Grid>
      </Grid>
      <TrafficByPlatform />
      <InsightsSection />
      <TrafficAnalyticsTable
        selectedDate={date}
        initialDate={initialDate}
        selectedLLM={llm}
      />
    </PageLayout>
  );
};

const AITraffic = () => {
  return (
    <GlobalFiltersProvider>
      <AITrafficContent />
    </GlobalFiltersProvider>
  );
};

export default AITraffic;
