import { Grid, Typography } from "@mui/material";
import ScoreMovementTypography from "../../../../../../../OptimizationHub/pages/Overview/components/ScoreMovementTypography/ScoreMovementTypography";
import TextWithmovement from "../../../../../../../AEO/components/TextWithMovement/TextWithMovement";
import LLMModelTypography from "../LLmModelTypography/LLMModelTypography";

const ImpressionRow = ({ llm, impression, movement, isIncrease }) => {
  return (
    <Grid container width={"100%"} sx={{ marginTop: 2 }}>
      <Grid item xs={4}>
        <Typography
          color="text.tertiary"
          style={{ fontWeight: 400, fontSize: 12 }}
        >
          <LLMModelTypography llm={llm} />
        </Typography>
      </Grid>

      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          color="text.primary"
          style={{ fontWeight: 500, fontSize: 14 }}
        >
          {impression?.toLocaleString()}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TextWithmovement movement={movement} isIncrease={isIncrease} />
      </Grid>
    </Grid>
  );
};
export default ImpressionRow;
