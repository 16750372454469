import { apiClient } from "./client";

export const endpoints = {
  auth: {
    refresh: (refreshToken) =>
      apiClient.post("/goodie/accounts/token/refresh", { refreshToken }),
    getUserData: (userId) => apiClient.get(`/goodie/accounts/${userId}`),
    updateUserData: (userId, data) =>
      apiClient.put(`/goodie/accounts/${userId}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    login: (credentials) =>
      apiClient.post("/goodie/accounts/login", credentials),
    signup: (data) => apiClient.post("/goodie/accounts/signup", data),
    microsoftLogin: (accessToken) =>
      apiClient.post("/goodie/accounts/auth/microsoft/callback/", {
        access_token: accessToken,
      }),
    forgotPassword: (email) =>
      apiClient.post("/goodie/accounts/forget-password", { email }),
    resetPassword: (data) =>
      apiClient.post("/goodie/accounts/reset-password", data),
    confirmForgetPassword: (data) =>
      apiClient.post("/goodie/accounts/confirm-forget-password", data),
    verifyEmail: (token) =>
      apiClient.post("/goodie/accounts/verify-email", { token }),
    resendVerification: (email) =>
      apiClient.post("/goodie/accounts/resend-verification", { email }),
  },
  brands: {
    getOverview: (brandId) =>
      apiClient.get(`/goodie/brands/${brandId}/overview`),
    getBrand: (brandId) => apiClient.get(`/goodie/brands/${brandId}`),
    checkAdmin: (userId, brandId) =>
      apiClient.get(
        `/goodie/brands/check-admin?user_id=${userId}&brand_id=${brandId}`
      ),
    update: (brandId, data, hasMultipartHeaders) =>
      apiClient.patch(`/goodie/brands/${brandId}`, data, {
        headers: {
          "Content-Type": hasMultipartHeaders
            ? "multipart/form-data"
            : "application/json",
        },
      }),
    getOptimizationDateRange: (brandId, topic) =>
      apiClient.get(
        `/goodie/aeo/optimization-date-range/${brandId}${
          topic ? `?topic=${encodeURIComponent(topic)}` : ""
        }`
      ),
    updateRecents: (blogId, data) =>
      apiClient.patch(`/goodie/brands/recents/${blogId}`, data),
    deleteRecent: (id) => apiClient.delete(`/goodie/brands/recents/${id}`),
    getRecents: (brandId) => apiClient.get(`/goodie/brands/${brandId}/recents`),
    updateBlogContent: (blogId, content) =>
      apiClient.patch(`/goodie/brands/recents/${blogId}`, { content }),
    addUsers: (brandId, data) =>
      apiClient.post(`/goodie/brands/${brandId}/users`, data),
    getConnectors: (brandId) =>
      apiClient.get(`/goodie/brands/${brandId}/connectors`),
    getKnowledgeBase: (brandId) =>
      apiClient.get(`/goodie/brands/${brandId}/knowledgebase`),
    updateKnowledgeBase: (data) =>
      apiClient.post(`/createai/knowledgebase`, data, {
        baseURL: process.env.REACT_APP_ML_API_URL,
      }),
  },
  workspace: {
    getAll: () => apiClient.get("/goodie/brands"),
    create: (data) => apiClient.post("/goodie/brands/brands", data),
    update: (workspaceId, data) =>
      apiClient.patch(`/goodie/brands/${workspaceId}`, data),
    delete: (workspaceId) =>
      apiClient.delete(`/goodie/workspaces/${workspaceId}`),
    inviteUsers: (workspaceId, data) =>
      apiClient.post(`/goodie/workspaces/${workspaceId}/invite`, data),
    getPendingInvites: (workspaceId) =>
      apiClient.get(`/goodie/workspaces/${workspaceId}/pending-invites`),
    updateUserRole: (data) =>
      apiClient.post(`/goodie/brands/workspace-admin/manage`, data),
    removeUser: (brandId, userId) =>
      apiClient.delete(`/goodie/brands/${brandId}/remove-user/${userId}`),
  },
  optimization: {
    getSourceTracking: (brandId, params) =>
      apiClient.get(`/goodie/aeo/${brandId}/source-tracking`, {
        params,
      }),
    getOpportunities: (brandId) =>
      apiClient.get(`/goodie/aeo/${brandId}/opportunities/${brandId}`),
    getTopicDetails: (brandId, topicId) =>
      apiClient.get(`/goodie/aeo/${brandId}/topics/${topicId}`),
    getAIAuthorityScore: (brandId) =>
      apiClient.get(`/goodie/aeo/${brandId}/ai-authority-score/`),
    getTopicRanking: (brandId) =>
      apiClient.get(`/goodie/aeo/${brandId}/topic-ranking/`),
    getTopicVisibility: (brandId) =>
      apiClient.get(`/goodie/aeo/${brandId}/topic-visibility/`),
    getSourceBreakdown: (brandId) =>
      apiClient.get(`/goodie/aeo/${brandId}/source-breakdown/`),
    getSourceRecommendations: (brandId, params) =>
      apiClient.get(`/goodie/aeo/${brandId}/source-recommendation/`, {
        params,
      }),
    getReference: (referenceId) =>
      apiClient.get(`/goodie/aeo/references/${referenceId}`),
    updateReference: (referenceId, data) =>
      apiClient.patch(`/goodie/aeo/references/${referenceId}`, data),
    getSourceRiskAudit: (brandId, params) =>
      apiClient.get(`/goodie/aeo/${brandId}/source-risk-audit/`, { params }),
    generateOptimization: (data) =>
      apiClient.post(`/aeo/aeo/optimization-generation`, data),
    getTopRankingTopics: (data) =>
      apiClient.post(`/aeo/aeo/top-ranking-topics`, data),
    getBrandEntityRecognition: (data) =>
      apiClient.post(`/aeo/aeo/brand-entity-recognition`, data),
  },
  aeo: {
    getGlobalMonitoring: (brandId) =>
      apiClient.get(`/goodie/aeo/${brandId}/global-monitoring/`),
    getPromptMetrics: (brandId) =>
      apiClient.get(`/goodie/aeo/prompt-metrics/${brandId}`),
    getPromptIndex: (brandId) =>
      apiClient.post(`/aeo/aeo/prompt-index`, { brandId }),
    getRankingData: (brandId) =>
      apiClient.get(`/goodie/aeo/ranking/${brandId}`),
    getCountries: () => apiClient.get("/goodie/aeo/countries"),
    getBrandVisibilityScoreRank: (data) =>
      apiClient.post(`/aeo/aeo/brand-visibility-score-rank`, data),
    getBrandPerformanceScoreRank: (data) =>
      apiClient.post(`/aeo/aeo/brand-performance-score-rank`, data),
    getLLMRanking: (brandId) =>
      apiClient.post(`/aeo/aeo/brand-visibility-regions`, { brandId }),
    getAvailableDates: (brandId) =>
      apiClient.post(`/aeo/aeo/availabledates`, { brandId }),
    getMarketPerformance: (data) =>
      apiClient.post(`/aeo/aeo/market-performance`, data),
    getSentimentCompetitor: (data) =>
      apiClient.post(`/aeo/aeo/sentiment-competitor`, data),
    getAviIndex: (data) => apiClient.post(`/aeo/aeo/avi_index`, data),
    getSentimentVisibilityWidget: (data) =>
      apiClient.post(`/aeo/aeo/sentiment-visibility-widget`, data),
    getSourceBreakdown: (brandId, startDate, endDate) =>
      apiClient.get(
        `/goodie/aeo/${brandId}/source-breakdown/?startDate=${startDate}&endDate=${endDate}`
      ),
    getBrandCountries: (brandId) =>
      apiClient.get(`/goodie/brands/${brandId}/countries/`),
    getOptimizationCountMovement: (brandId, topic) =>
      apiClient.get(
        `/goodie/aeo/optimization-count-movement/${brandId}?topic=${encodeURIComponent(
          topic
        )}`
      ),
    getReputationIndex: (data) =>
      apiClient.post(`/aeo/aeo/reputation_index`, data),
    getGlobalPerformanceLineChart: (data) =>
      apiClient.post(`/aeo/aeo/global-performance-line-chart`, data),
    getVisibilityPerformanceLineChart: (data) =>
      apiClient.post(`/aeo/aeo/visibility-performance-line-chart`, data),
    getAssessmentReports: (assessmentDataId) =>
      apiClient.get(
        `/goodie/aeo-assessment/assessment-data/${assessmentDataId}/reports`
      ),
  },
  agentAnalytics: {
    getAgentAnalyticsTrendLineChart: (data) =>
      apiClient.post(`/goodie/ai-agents/trends/linechart`, data),
    getAgentAnalyticsTraffic: (data) =>
      apiClient.post(`/goodie/ai-agents/sessions`, data),
    getAgentAnalyticsAIOrganicTrends: (data) =>
      apiClient.post(`/goodie/ai-agents/ai-organic-trends`, data),
    getAgentAnalyticsImpressionsTotal: (data) =>
      apiClient.post(`/aeo/ai_traffic/impressions/total`, data),
    getAgentAnalyticsInsighits: (data) =>
      apiClient.post(`/aeo/ai_traffic/insights`, data),
    getAgentAnalyticsModelImpressions: (data) =>
      apiClient.post(`/aeo/ai_traffic/impressions/by_llm`, data),
    getGA4Analytics: (brandId, params) =>
      apiClient.get(`goodie/ai-agents/analytics/${brandId}`, {
        params,
      }),
    getAvailableDates: (brandId) =>
      apiClient.get(`/goodie/ai-agents/date-range/${brandId}`),
  },
  profile: {
    update: (userId, data) =>
      apiClient.patch(`/goodie/profile/${userId}`, data),
    updateAuthorStamp: (userId, data) =>
      apiClient.patch(`/goodie/profile/${userId}/author-stamp`, data),
    getAuthorStamp: (userId) =>
      apiClient.get(`/goodie/accounts/${userId}/authorstamp`),
    createAuthorStamp: (data) =>
      apiClient.post(`/createai/authorstamp`, data, {
        baseURL: process.env.REACT_APP_ML_API_URL,
      }),
  },
  content: {
    generateBlog: (data) =>
      apiClient.post("/createai/generateblog", data, {
        baseURL: process.env.REACT_APP_ML_API_URL,
      }),
    generateOutline: (data) =>
      apiClient.post("/content/generate-outline", data, {
        baseURL: process.env.REACT_APP_ML_API_URL,
      }),
    generateTitles: (data) =>
      apiClient.post("/createai/generatetitles", data, {
        baseURL: process.env.REACT_APP_ML_API_URL,
      }),
    getReferences: (query) =>
      apiClient.get(
        `/goodie/content/references?q=${encodeURIComponent(query)}`
      ),
    getRecentBlog: (blogId) =>
      apiClient.get(`/goodie/brands/recents/${blogId}`),
    generateReferences: (data) =>
      apiClient.post("/createai/generaterefernces", data, {
        baseURL: process.env.REACT_APP_ML_API_URL,
      }),
  },
  inviteUsers: {
    inviteToWorkspace: (brandId, values) =>
      apiClient.post(`/goodie/brands/${brandId}/users`, values),
    acceptInvite: (inviteId, values) =>
      apiClient.patch(`/goodie/brands/invitations/${inviteId}`, values),
    validateUrls: (values) =>
      apiClient.post(`/createai/validateurls`, values, {
        baseURL: process.env.REACT_APP_ML_API_URL,
      }),
    deleteInvitation: (inviteId) =>
      apiClient.delete(`/goodie/brands/invitations/${inviteId}`),
  },
  connectors: {
    updateConnector: (connectorId, values) =>
      apiClient.patch(`/goodie/brands/connector/${connectorId}`, values),
  },
  blogWriter: {
    generateOutline: (values) =>
      apiClient.post(`/createai/generateoutline`, values, {
        baseURL: process.env.REACT_APP_ML_API_URL,
      }),
  },
  aeoAnalytics: {
    getVisibilityScoreRank: (values) =>
      apiClient.post(`/aeo/aeo/brand-visibility-score-rank`, values),
    getPerformanceScoreRank: (values) =>
      apiClient.post(`/aeo/aeo/brand-performance-score-rank`, values),
    getVisibilityPerformanceLineChart: (values) =>
      apiClient.post(`/aeo/aeo/visibility-performance-line-chart`, values),
    getGlobalVisibilityRegions: (values) =>
      apiClient.post(`/aeo/aeo/global-visibility-regions`, values),
    getSentimentCompetitor: (values) =>
      apiClient.post(`/aeo/aeo/sentiment-competitor`, values),
    getSentimentTimeSeries: (values) =>
      apiClient.post(`/aeo/aeo/sentiment-time-series`, values),
  },
};
