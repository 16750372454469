import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import Wrapper from "../Wrapper/Wrapper";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { endpoints } from "../../../../services/api/endpoints";
import AccountsCard from "../Card/Card";
import EmailIcon from "../../../../CommonComponents/customIcons/EmailIcon";

const ConfirmForgetPassword = () => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email") || "";

  const handleOnClick = (values) => {
    setError("");
    setIsLoading(true);
    endpoints.auth
      .forgotPassword(email)
      .then((response) => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError("Failed to send reset instructions!");
      });
  };

  return (
    <Wrapper isfullLength>
      <AccountsCard
        icon={<EmailIcon />}
        title="Check your email"
        backToLoginButton
        description={
          "An email with reset instructions has been sent (if your account is registered with that address). Please check your inbox and spam folder."
        }
        error={error}
        isLoading={isLoading}
        rowGap={0}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: 8,
            alignItems: "center",
            marginTop: 40,
          }}
        >
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: theme.palette.text.secondary,
            }}
          >
            Didn’t receive the email?
            <Button
              variant="text"
              sx={{
                color: theme.palette.text.brandbold,
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}
              onClick={() => handleOnClick()}
            >
              {!isLoading ? (
                " Click to resend"
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    style={{
                      width: 20,
                      height: 20,
                      color: theme.palette.text.chiptext,
                    }}
                  />
                </div>
              )}
            </Button>
          </Typography>{" "}
        </Box>
      </AccountsCard>
    </Wrapper>
  );
};
export default ConfirmForgetPassword;
