import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import ChipButton from "../../../../../../CommonComponents/ChipButton/ChipButton";
import { endpoints } from "../../../../../../services/api/endpoints";
import { useLocation } from "react-router-dom";

const ConnectProperty = ({ access_token, onClose, refetch }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const connector_id = params.get("connector_id");
  const [initialLoading, setInitialLoading] = useState();
  const [accounts, setAccounts] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isLoading, setLoading] = useState();
  const theme = useTheme();

  useEffect(() => {
    if (!access_token) return;

    const fetchAccountSummaries = async () => {
      setInitialLoading(true);
      try {
        const response = await fetch(
          "https://analyticsadmin.googleapis.com/v1beta/accountSummaries",
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setInitialLoading(false);

        if (!response.ok) {
          throw new Error(`Error fetching accounts: ${response.statusText}`);
        }

        const data = await response.json();
        setAccounts(data.accountSummaries || []);
      } catch (error) {
        console.error("Error fetching accounts:", error);
      }
    };

    fetchAccountSummaries();
  }, [access_token]);

  const handleSelectProperty = (property) => {
    setSelectedProperty(property === selectedProperty ? null : property);
  };

  const handleSave = () => {
    setLoading(true);
    const values = {
      property_id: selectedProperty.split("/").pop(),
      status: "connected",
    };
    endpoints.connectors
      .updateConnector(connector_id, values)
      .then((response) => {
        setLoading(false);
        refetch();
        onClose();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Box sx={{ padding: "40px", height: "100%", overflowY: "auto" }}>
      <Typography variant="h4" sx={{ mb: 3, fontWeight: 500 }}>
        Connect Google Analytics Property
      </Typography>

      <Typography
        variant="subtitle1"
        sx={{ fontSize: "14px", maxWidth: "596px", mb: 4 }}
      >
        Select a property you'd like to link to your workspace.
      </Typography>

      <Divider sx={{ mb: 3 }} />

      {accounts.length > 0 ? (
        accounts.map((account) => (
          <Box key={account.account} sx={{ mb: 2, p: 2 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              {account.displayName}
            </Typography>

            <Grid container spacing={2}>
              {account.propertySummaries?.map((property) => (
                <Grid item key={property.property}>
                  <Button
                    onClick={() => handleSelectProperty(property.property)}
                    sx={{
                      textTransform: "none",
                      padding: "10px 11px",
                      backgroundColor:
                        selectedProperty === property.property
                          ? theme.palette.buttons.selected
                          : "transparent",
                      color:
                        selectedProperty === property.property
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                      borderRadius: "11px",
                      border: `1px solid ${
                        selectedProperty === property.property
                          ? theme.palette.buttons.selected
                          : theme.palette.border.tertiary
                      }`,
                      "&:hover": {
                        backgroundColor: theme.palette.buttons.secondaryhover,
                        borderColor: theme.palette.border.tertiary,
                      },
                    }}
                  >
                    {property.displayName}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))
      ) : initialLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{
              width: 20,
              height: 20,
              color: theme.palette.text.chiptext,
            }}
          />
        </div>
      ) : (
        <Typography>No accounts found or unauthorized access.</Typography>
      )}

      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          backgroundColor: "background.secondary",
          padding: 3,
          borderBottomRightRadius: 35,
          borderBottomLeftRadius: 35,
          borderTop: `1px solid ${theme.palette.border.tertiary}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            alignItems: "center",
            columnGap: 1,
          }}
        >
          <ChipButton
            label="Link Property"
            backgroundColor={theme.palette.background.brand}
            textColor={theme.palette.text.chiptext}
            hoverBackgroundColor={theme.palette.background.brandhover}
            hoverTextColor={theme.palette.text.chiptext}
            hoverBorderColor={theme.palette.background.brandhover}
            padding="5px 15px"
            onClick={handleSave}
            disabled={!selectedProperty}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ConnectProperty;
