import React from "react";

const CustomFilterIcon = ({}) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6663 0.333496H2.33301C1.80257 0.333496 1.29387 0.54421 0.918794 0.919283C0.543722 1.29436 0.333008 1.80306 0.333008 2.3335V3.1135C0.332912 3.38879 0.389652 3.66114 0.499675 3.9135V3.9535C0.593861 4.16748 0.727269 4.36194 0.893008 4.52683L4.99967 8.60683V13.0002C4.99945 13.1135 5.0281 13.2249 5.08292 13.3241C5.13774 13.4232 5.21693 13.5068 5.31301 13.5668C5.4191 13.6326 5.54152 13.6672 5.66634 13.6668C5.7707 13.6662 5.87346 13.6411 5.96634 13.5935L8.63301 12.2602C8.74295 12.2048 8.83541 12.12 8.90012 12.0153C8.96484 11.9105 8.9993 11.7899 8.99967 11.6668V8.60683L13.0797 4.52683C13.2454 4.36194 13.3788 4.16748 13.473 3.9535V3.9135C13.5922 3.66312 13.6581 3.39068 13.6663 3.1135V2.3335C13.6663 1.80306 13.4556 1.29436 13.0806 0.919283C12.7055 0.54421 12.1968 0.333496 11.6663 0.333496ZM7.85967 7.86016C7.79789 7.92246 7.749 7.99633 7.71583 8.07756C7.68265 8.15878 7.66583 8.24576 7.66634 8.3335V11.2535L6.33301 11.9202V8.3335C6.33351 8.24576 6.3167 8.15878 6.28352 8.07756C6.25034 7.99633 6.20146 7.92246 6.13967 7.86016L2.60634 4.3335H11.393L7.85967 7.86016ZM12.333 3.00016H1.66634V2.3335C1.66634 2.15668 1.73658 1.98712 1.8616 1.86209C1.98663 1.73707 2.1562 1.66683 2.33301 1.66683H11.6663C11.8432 1.66683 12.0127 1.73707 12.1377 1.86209C12.2628 1.98712 12.333 2.15668 12.333 2.3335V3.00016Z"
      fill="#B2B2B2"
    />
  </svg>
);

export default CustomFilterIcon;
