import React from "react";

const BranvoiceIcon = ({ color = "#8C8C8C", width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : "16px"}
    height={height ? height : "16px"}
    viewBox="0 0 16 16"
    fill={color}
  >
    <path
      d="M11.3334 6.00016H4.6667C4.48989 6.00016 4.32032 6.0704 4.19529 6.19543C4.07027 6.32045 4.00003 6.49002 4.00003 6.66683C4.00003 6.84364 4.07027 7.01321 4.19529 7.13823C4.32032 7.26326 4.48989 7.3335 4.6667 7.3335H11.3334C11.5102 7.3335 11.6797 7.26326 11.8048 7.13823C11.9298 7.01321 12 6.84364 12 6.66683C12 6.49002 11.9298 6.32045 11.8048 6.19543C11.6797 6.0704 11.5102 6.00016 11.3334 6.00016ZM8.6667 8.66683H4.6667C4.48989 8.66683 4.32032 8.73707 4.19529 8.86209C4.07027 8.98712 4.00003 9.15669 4.00003 9.3335C4.00003 9.51031 4.07027 9.67988 4.19529 9.8049C4.32032 9.92992 4.48989 10.0002 4.6667 10.0002H8.6667C8.84351 10.0002 9.01308 9.92992 9.1381 9.8049C9.26313 9.67988 9.33337 9.51031 9.33337 9.3335C9.33337 9.15669 9.26313 8.98712 9.1381 8.86209C9.01308 8.73707 8.84351 8.66683 8.6667 8.66683ZM8.00003 1.3335C7.12455 1.3335 6.25765 1.50593 5.44881 1.84097C4.63997 2.176 3.90504 2.66706 3.28599 3.28612C2.03574 4.53636 1.33337 6.23205 1.33337 8.00016C1.32754 9.53959 1.86056 11.0325 2.84003 12.2202L1.5067 13.5535C1.41419 13.6472 1.35153 13.7663 1.32661 13.8956C1.3017 14.0249 1.31565 14.1588 1.3667 14.2802C1.42207 14.4001 1.51184 14.5009 1.6246 14.5698C1.73736 14.6386 1.86803 14.6724 2.00003 14.6668H8.00003C9.76814 14.6668 11.4638 13.9645 12.7141 12.7142C13.9643 11.464 14.6667 9.76827 14.6667 8.00016C14.6667 6.23205 13.9643 4.53636 12.7141 3.28612C11.4638 2.03588 9.76814 1.3335 8.00003 1.3335ZM8.00003 13.3335H3.6067L4.2267 12.7135C4.35087 12.5886 4.42056 12.4196 4.42056 12.2435C4.42056 12.0674 4.35087 11.8984 4.2267 11.7735C3.35376 10.9015 2.81015 9.75386 2.68849 8.52603C2.56684 7.2982 2.87466 6.06617 3.55952 5.03984C4.24437 4.01352 5.26389 3.2564 6.44438 2.89748C7.62486 2.53855 8.89327 2.60003 10.0335 3.07144C11.1737 3.54284 12.1153 4.39501 12.6977 5.48276C13.28 6.5705 13.4673 7.82653 13.2275 9.03683C12.9876 10.2471 12.3356 11.3369 11.3824 12.1203C10.4292 12.9038 9.23387 13.3325 8.00003 13.3335Z"
      fill={color}
    />
  </svg>
);

export default BranvoiceIcon;
