import { endpoints } from "../services/api/endpoints";

export const handleUserLogic = () => {
  const loggedInUser = localStorage.getItem("loggedInUser");
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  if (loggedInUser && (!accessToken || !refreshToken)) {
    localStorage.removeItem("loggedInUser");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.href = "/login";
    return;
  }

  const userId = loggedInUser
    ? JSON.parse(localStorage.getItem("loggedInUser")).id
    : "";

  if (userId && accessToken && refreshToken) {
    endpoints.auth
      .getUserData(userId)
      .then((response) => {
        const userData = response.data;
        localStorage.setItem("loggedInUser", JSON.stringify(userData));
      })
      .catch((error) => {
        // If we get an auth error, clear everything and redirect
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          localStorage.removeItem("loggedInUser");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          window.location.href = "/login";
        }
      });
  }
};
