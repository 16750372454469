import { Button } from "@mui/material";
import React from "react";
import microsoftLogo from "../../../../assets/images/microsoft-logo.png";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../../msalConfig";

const MicrosoftLogin = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };
  return (
    <Button
      onClick={handleLogin}
      sx={{
        fontFamily: "Roboto",
        textTransform: "none",
        display: "flex",
        flexDirection: "row",
        columnGap: 1,
        borderRadius: "11px",
        color: "text.tertiary",
        fontSize: 20,
        borderColor: "border.tertiary",
        "&:hover": {
          borderColor: "border.tertiary",
          backgroundColor: "transparent",
        },
        "&.Mui-focused": {
          borderColor: "border.tertiary",
        },
      }}
      fullWidth
      variant="outlined"
      startIcon={<img src={microsoftLogo} alt="google logo" />}
    >
      Continue with Microsoft
    </Button>
  );
};

export default MicrosoftLogin;
