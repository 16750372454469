import ChartCard from "../../../../../AEO/components/ChartCard/ChartCard";
import { Box, Typography, useTheme } from "@mui/material";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import { useState } from "react";
import MultipleValuesWheelGrid from "../../../../../../CommonComponents/MultipleValuesWheelGrid";
import SourceBreakDownIcon from "../../../../../../CommonComponents/customIcons/SourceBreakdownIcon";
import { LLMsMap } from "../../../../../AEO/utils/DataUtils";
import MultipleValuesPercentageWheel from "../../../../../../CommonComponents/MultipleValuesPercentageWheel";
import LLMModelTypography from "../../../AITraffic/components/ImpressionByModelCard/components/LLmModelTypography/LLMModelTypography";

const data = [
  { llm: "ChatGpt", value: 45 },
  { llm: "Perplexity", value: 25 },
  { llm: "Claude", value: 15 },
  { llm: "DeepSeek", value: 15 },
  { llm: "Gemini", value: 10 },
];
export const getLLMColors = (theme) => {
  const colors = Object.values(LLMsMap).map(
    (llm) => theme.palette.llms?.[llm.color]
  );
  return [...new Set(colors)];
};

const AICrawlerBreakdownCard = () => {
  const theme = useTheme();
  const { date: globalDate, initialDate: globalInitialDate } =
    useGlobalFilters();
  const [date, setDate] = useState(globalDate);
  const [initialDate, setInitialDate] = useState(globalInitialDate);
  //   const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  return (
    <ChartCard
      icon={<SourceBreakDownIcon color={theme.palette.text.primary} />}
      title="AI Crawler Breakdown"
      tooltipMessagePrimary="tooltip"
      tooltipMessageSecondary="tooltip"
      height={356}
      isLoading={loading}
      isError={error}
      setDate={setDate}
      date={date}
      initialDate={initialDate}
      hasCalendarSelect
      rowGap={5}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          columnGap: { xs: "15px", sm: "70px" },
          alignItems: "center",
          justifyContent: "center",
          marginTop: 5,
        }}
      >
        <MultipleValuesPercentageWheel
          data={data}
          wheelColors={getLLMColors(theme)}
          width={197}
          height={197}
          wheelInnerRaduis={80}
          wheelOuterRaduis={94}
        />
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
          {data.map(({ llm, value }) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                columnGap: 1,
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "14px", lineHeight: "14px" }}>
                {value}%
              </Typography>
              <LLMModelTypography llm={llm} />
            </Box>
          ))}
        </Box>
      </Box>
    </ChartCard>
  );
};
export default AICrawlerBreakdownCard;
