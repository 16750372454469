import DNAStep from "../Step/Step";
import TopicsForm from "./Form/TopicsForm";

const Topics = ({ brandDetails }) => {
  return (
    <DNAStep
      title="Topics"
      subtitle="Topics define where you want your brand to appear in AI search. Our system tracks these topics to measure relevance, identify visibility gaps, and provide strategic optimizations to enhance your ranking and influence in AI-generated answers."
      tipTitle="Get the most from your topics by following these tips:"
      tips={[
        "Be specific: Avoid overly broad terms; choose topics relevant to your brand.",
        "Think naturally: Use phrases your audience would search for.",
        "Stay focused: Choose topics that align with your brand’s goals.",
      ]}
      required
      children={<TopicsForm brandDetails={brandDetails} />}
    />
  );
};
export default Topics;
