import React, { useMemo, useState } from "react";
import {
  Avatar,
  MenuItem,
  Typography,
  Divider,
  ListItemIcon,
  Box,
  useTheme,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ProfileIcon from "../../../customIcons/ProfileIcon";
import { useUser } from "../../../../contexts/UserContext";
import { getInitials } from "../../../../utils/getInitials";
import { useBrand } from "../../../../contexts/BrandContext";

const ProfilePopupStatic = () => {
  const { loggedInUser, updateUser, logout } = useUser();
  const name = useMemo(() => {
    return `${loggedInUser.first_name} ${loggedInUser.last_name}`;
  }, [loggedInUser?.first_name, loggedInUser?.last_name]);

  const email = loggedInUser?.email || "User";
  const navigate = useNavigate();
  const theme = useTheme();
  const { clearWorkspaceSelection } = useBrand();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    clearWorkspaceSelection();
    navigate(`/login`);
    handleClose();
  };

  return (
    <Box
      sx={{
        width: "226px",
        padding: "10px 16px",
        backgroundColor: "background.secondary",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          pb: "20px",
        }}
      >
        <Box sx={{ position: "relative", mb: "10px" }}>
          <Avatar
            sx={{
              width: "64px",
              height: "64px",
              fontSize: "36px",
              color: "text.tertiary",
              backgroundColor: "background.quaternary",
            }}
            src={loggedInUser?.profile?.profile_picture ?? ""}
            alt={getInitials(loggedInUser.first_name)}
          >
            {getInitials(loggedInUser.first_name)}
          </Avatar>
        </Box>

        <Typography
          sx={{
            pb: "5px",
            fontSize: "12px",
            fontWeight: 450,
            lineHeight: "16px",
            color: "text.primary",
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            pb: "5px",
            fontSize: "12px",
            fontWeight: 450,
            lineHeight: "16px",
            color: "text.tertiary",
          }}
        >
          {email}
        </Typography>
      </Box>

      <Divider
        sx={{
          height: "0.5px",
          borderColor: "border.tertiary",
          mr: "8px",
          mb: "8px",
        }}
      />
      <MenuItem
        sx={{
          fontSize: "12px",
          fontWeight: 450,
          lineHeight: "16px",
          color: "text.primary",
        }}
        onClick={() => navigate(`account/profile-settings`)}
      >
        <ListItemIcon>
          <ProfileIcon
            sx={{ width: "17px" }}
            color={theme.palette.text.primary}
          />
        </ListItemIcon>
        Profile Settings
      </MenuItem>
      <Divider
        sx={{
          height: "0.5px",
          borderColor: "border.tertiary",
        }}
      />
      <MenuItem
        onClick={handleLogout}
        disableRipple
        sx={{
          fontSize: "12px",
          fontWeight: 450,
          lineHeight: "16px",
          color: "text.primary",
        }}
      >
        <ListItemIcon>
          <Logout sx={{ width: "17px", color: theme.palette.text.primary }} />
        </ListItemIcon>
        Log out
      </MenuItem>
    </Box>
  );
};

export default ProfilePopupStatic;
