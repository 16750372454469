import { useEffect, useState } from "react";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import ChartCard from "../../../../../AEO/components/ChartCard/ChartCard";
import SourceTrackingTable from "../../../../components/SourceTrackingTable";

const SourceTrackingCard = () => {
  const {
    date: globalDate,
    initialDate: globalInitialDate,
    llm,
  } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const [date, setDate] = useState(globalDate);

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);

  return (
    <ChartCard
      title="Source Tracking"
      tooltipMessagePrimary="Shows your brand's visibility score across various regions and selected LLM. Higher scores indicate stronger brand presence in each country or region."
      tooltipMessageSecondary="All score out of 100."
      description="Analyze and improve source performance through topic and prompt tracking in real time."
      height={"100%"}
      hasLLMselect
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
      hasCalendarSelect
      setDate={setDate}
      date={date}
      initialDate={globalInitialDate}
    >
      <SourceTrackingTable date={date} selecetdLLm={selectedLLM} />
    </ChartCard>
  );
};
export default SourceTrackingCard;
