import {
  Box,
  Menu,
  MenuItem,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useState } from "react";
import AddWorkspace from "../../../../pages/Accounts/Components/Workspace/Components/AddWorkspace/AddWorkspace";
import Wrapper from "../../../../pages/Accounts/Components/Wrapper/Wrapper";
import WelcomeDNA from "../../../../pages/Accounts/Components/Workspace/Components/WelcomeDNA/WeclomeDNA";
import { useBrand } from "../../../../contexts/BrandContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const WorkspacesDropdown = ({
  anchorEl,
  open,
  setAnchorEl,
  userBrands = [],
}) => {
  const navigate = useNavigate();
  const { brandId } = useParams();
  const { selectBrand } = useBrand();
  const [openAddWorkspace, setOpenAddWorkspace] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [brandName, setBrandName] = useState("");
  const theme = useTheme();
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      PaperProps={{
        sx: {
          width: "285px",
          boxShadow: "none",
          backgroundColor: "background.primary",
          padding: "7px",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {userBrands?.map((brand) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 5,
          }}
        >
          {brand.image ? (
            <img src={brand.image} width={24} height={24} />
          ) : (
            <Typography
              style={{
                width: 27,
                height: 27,
                borderRadius: 1,
                backgroundColor: theme.palette.background.secondary,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: 500,
                fontSize: 15,
              }}
              color="text.primary"
            >
              {brand.name.charAt(0).toUpperCase()}
            </Typography>
          )}
          <MenuItem
            onClick={() => {
              selectBrand(brand.id);
              navigate(`/dashboard/${brand.id}/`);
              setAnchorEl(null);
              localStorage.setItem("worskapceName", brand.name);
            }}
            disableRipple
            sx={{
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
              "&:focus": {
                backgroundColor: "transparent",
              },
              "&:active": {
                backgroundColor: "transparent",
              },
              fontSize: "14px",
              color: "text.primary",
              fontWeight: 500,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              lineHeight: 1,
            }}
          >
            {brand.name}
            {parseInt(brand.id) === parseInt(brandId) && (
              <span
                variant="h5"
                style={{
                  fontSize: "12px",
                  color: theme.palette.text.secondary,
                }}
              >
                selected
              </span>
            )}
          </MenuItem>
        </div>
      ))}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 5,
        }}
        onClick={() => {
          setOpenAddWorkspace(true);
        }}
      >
        <Box
          sx={{
            backgroundColor: "background.quaternary",
            borderRadius: 1,
            width: 27,
            height: 27,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AddRoundedIcon
            style={{ color: theme.palette.text.secondary, fontSize: 18 }}
          />
        </Box>
        <MenuItem
          onClick={() => {}}
          disableRipple
          sx={{
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&:focus": {
              backgroundColor: "transparent",
            },
            "&:active": {
              backgroundColor: "transparent",
            },
            fontSize: "14px",
            color: "text.secondary",
            fontWeight: 500,
          }}
        >
          Create new workspace
        </MenuItem>
      </div>
      <Modal
        open={openAddWorkspace}
        onClose={() => {
          setOpenAddWorkspace(false);
        }}
      >
        <Wrapper backgroundColor="transparent">
          <Box sx={style}>
            {currentStep === 1 && (
              <AddWorkspace
                backButton={false}
                setCurrentStep={setCurrentStep}
                hasClose={true}
                handleClose={() => setOpenAddWorkspace(false)}
                setBrandName={setBrandName}
              />
            )}
            {/* {currentStep === 5 && (
              <InviteUsers
                backButton={false}
                setCurrentStep={setCurrentStep}
                hasClose={true}
                handleClose={() => setOpenAddWorkspace(false)}
                brandName={brandName}
              />
            )} */}
            {currentStep === 6 && (
              <WelcomeDNA
                setCurrentStep={setCurrentStep}
                backButton={false}
                onClose={() => setOpenAddWorkspace(false)}
              />
            )}
          </Box>
        </Wrapper>
      </Modal>
    </Menu>
  );
};
export default WorkspacesDropdown;
