import { Grid, Typography } from "@mui/material";

const GridInputSection = ({
  label,
  grid1Title,
  grid2Title,
  children,
  required,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
      <Typography variant="h4" sx={{ fontSize: "16px", fontWeight: 500 }}>
        {label}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5}>
          <Typography variant="h4" sx={{ fontSize: "14px", fontWeight: 500 }}>
            {grid1Title}
            {required && (
              <span style={{ color: "#718cf9", marginLeft: "2px" }}>*</span>
            )}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h4" sx={{ fontSize: "14px", fontWeight: 500 }}>
            {grid2Title}
            {required && (
              <span style={{ color: "#718CF9", marginLeft: "2px" }}>*</span>
            )}
          </Typography>
        </Grid>
      </Grid>
      {children}
    </div>
  );
};
export default GridInputSection;
