import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useUser } from "../contexts/UserContext";

export const AuthGuard = () => {
  const { loggedInUser } = useUser();
  const location = useLocation();

  const PUBLIC_PATHS = ["/login", "/signup", "/auth/callback"];
  const isPublicPath = PUBLIC_PATHS.some((path) =>
    location.pathname.startsWith(path)
  );

  if (!loggedInUser?.id && !isPublicPath) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};
