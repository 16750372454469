import { useTheme } from "@mui/styles";
import TopicRecognitionIcon from "../../../../../../CommonComponents/customIcons/TopicRecognitionIcon";
import ChartCard from "../../../../../AEO/components/ChartCard/ChartCard";
import { Box } from "@mui/material";
import { LLMsMap } from "../../../../../AEO/utils/DataUtils";
import ScoreMovementTypography from "../../../../../OptimizationHub/pages/Overview/components/ScoreMovementTypography";
import LLMModelTypography from "../ImpressionByModelCard/components/LLmModelTypography/LLMModelTypography";
import { useEffect, useMemo, useState } from "react";
import { endpoints } from "../../../../../../services/api/endpoints";
import { useParams } from "react-router-dom";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";

const dummyData = [
  { label: "Claude", value: 334, movement: 1 },
  // { label: "DeepSeek", value: 241, movement: 1 },
  { label: "ChatGpt", value: 177, movement: 1 },
  { label: "Perplexity", value: 145, movement: 1 },
  { label: "Gemini", value: 103, movement: 1 },
];
// const expectedLLMs = ["Claude", "DeepSeek", "ChatGPT", "Perplexity", "Gemini"];
const expectedLLMs = ["Claude", "ChatGPT", "Perplexity", "Gemini"];

const transformData = (backendResponse) => {
  const responseData = backendResponse.data
    .filter(
      (item) => item.llm && expectedLLMs.includes(item.llm.replace(/\s+/g, ""))
    )
    .map((item) => ({
      label: item.llm.replace(/\s+/g, ""),
      value: item.total_sessions,
      movement: item.movement,
    }));

  const existingLabels = new Set(responseData.map((item) => item.label));
  const missingLLMs = expectedLLMs
    .filter((llm) => !existingLabels.has(llm))
    .map((llm) => ({ label: llm, value: 0, movement: 0 }));

  return [...responseData, ...missingLLMs];
};

const TrafficByPlatform = () => {
  const theme = useTheme();
  const {
    date: globalDate,
    initialDate: globalInitialDate,
    noData,
  } = useGlobalFilters();
  const [data, setData] = useState(noData ? dummyData : []);
  const [loading, setLoading] = useState();
  const { brandId } = useParams();
  const [initialDate, setInitialDate] = useState(globalInitialDate);
  const [date, setDate] = useState(globalDate);
  const totalValue = useMemo(() => {
    return data.reduce((sum, item) => sum + item.value, 0);
  }, [data]);

  useEffect(() => {
    if (date.length > 0 && date[0] && date[1]) {
      setLoading(true);
      endpoints.agentAnalytics
        .getAgentAnalyticsTraffic({
          brandId,
          start_date: date[0].format("YYYY-MM-DD"),
          end_date: date[1].format("YYYY-MM-DD"),
          aggregate_by: ["llm"],
        })
        .then((response) => {
          setLoading(false);

          setData(transformData(response.data));
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching user data:", error);
        });
    }
  }, [brandId, date]);

  useEffect(() => {
    setDate(globalDate);
  }, [globalDate]);

  useEffect(() => {
    setInitialDate(globalInitialDate);
  }, [globalInitialDate]);

  useEffect(() => {
    noData && setData(dummyData);
  }, [noData]);

  return (
    <ChartCard
      title="Traffic by Platform"
      icon={<TopicRecognitionIcon color={theme.palette.text.primary} />}
      tooltipMessagePrimary="Shows which AI models are driving traffic to your site. This helps assess which platforms are most influential in generating visits."
      height={190}
      hasCalendarSelect
      isLoading={loading}
      setDate={setDate}
      date={date}
      initialDate={initialDate}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          padding: "8px",
          whiteSpace: "nowrap",
          flexDirection: "row",
          columnGap: 2,
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        {data?.map((item) => {
          const widthPercentage = (item.value / totalValue) * 100;
          return (
            <Box
              sx={{
                display: "inline-flex",
                flexDirection: "column",
                gap: 1,
                minWidth: "100px",
                width: `${widthPercentage}%`,
                height: 86,
              }}
            >
              <LLMModelTypography llm={item.label} />
              <ScoreMovementTypography
                score={item.value}
                movement={item.movement}
                padding="20px"
                borderRadius="10px"
                backgroundColor={
                  theme.palette.llms?.[LLMsMap[item.label]?.hover]
                }
                movementBackgroundColor={
                  theme.palette.llms?.[LLMsMap[item.label]?.color]
                }
                color={theme.palette.text.primary}
                movementColor={theme.palette.text.chiptext}
                isPercentage={!!item.movement}
              />{" "}
            </Box>
          );
        })}
      </Box>
    </ChartCard>
  );
};
export default TrafficByPlatform;
