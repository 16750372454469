import { IconButton, Typography } from "@mui/material";
import FormikTextField from "../TextField/TextField";
import { useState } from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
const PasswordField = ({
  fieldName = "password",
  placeholder = "Enter Password",
  title = "Password",
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
      <Typography style={{ fontSize: 15 }} color="text.primary">
        {title}
      </Typography>
      <FormikTextField
        name={fieldName}
        placeholder={placeholder}
        ispassword={!showPassword}
        endAdornment={
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleTogglePasswordVisibility}
            edge="end"
            sx={{
              color: "text.tertiary",
            }}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        }
        margin={false}
      />
    </div>
  );
};
export default PasswordField;
