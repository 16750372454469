import React from "react";
import { useTheme } from "@mui/material";

const AgentIcon = ({ color, width, height }) => {
  const theme = useTheme();

  const Color = color || theme.palette.text.secondary || "#AFB1B7";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "16"}
      height={height ? height : "16"}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.33337 6.00035H6.66669C6.48988 6.00035 6.32031 6.07059 6.19528 6.19562C6.07026 6.32064 6.00002 6.49021 6.00002 6.66702V14.6671C6.00002 14.8439 6.07026 15.0134 6.19528 15.1385C6.32031 15.2635 6.48988 15.3337 6.66669 15.3337H9.33337C9.51018 15.3337 9.67975 15.2635 9.80478 15.1385C9.9298 15.0134 10 14.8439 10 14.6671V6.66702C10 6.49021 9.9298 6.32064 9.80478 6.19562C9.67975 6.07059 9.51018 6.00035 9.33337 6.00035ZM8.6667 14.0004H7.33336V7.33369H8.6667V14.0004ZM14.6667 0.666992H12C11.8232 0.666992 11.6537 0.73723 11.5286 0.862255C11.4036 0.98728 11.3334 1.15685 11.3334 1.33366V14.6671C11.3334 14.8439 11.4036 15.0134 11.5286 15.1385C11.6537 15.2635 11.8232 15.3337 12 15.3337H14.6667C14.8435 15.3337 15.0131 15.2635 15.1381 15.1385C15.2632 15.0134 15.3334 14.8439 15.3334 14.6671V1.33366C15.3334 1.15685 15.2632 0.98728 15.1381 0.862255C15.0131 0.73723 14.8435 0.666992 14.6667 0.666992ZM14.0001 14.0004H12.6667V2.00033H14.0001V14.0004ZM4.00001 10.0004H1.33333C1.15651 10.0004 0.986945 10.0706 0.86192 10.1956C0.736895 10.3207 0.666656 10.4902 0.666656 10.667V14.6671C0.666656 14.8439 0.736895 15.0134 0.86192 15.1385C0.986945 15.2635 1.15651 15.3337 1.33333 15.3337H4.00001C4.17682 15.3337 4.34639 15.2635 4.47141 15.1385C4.59644 15.0134 4.66668 14.8439 4.66668 14.6671V10.667C4.66668 10.4902 4.59644 10.3207 4.47141 10.1956C4.34639 10.0706 4.17682 10.0004 4.00001 10.0004ZM3.33334 14.0004H2V11.3337H3.33334V14.0004Z"
        fill={color}
      />
    </svg>
  );
};

export default AgentIcon;
