import { useTheme, Box, Grid, Typography } from "@mui/material";

const CustomeTooltip = ({ active, payload, label, reverseTooltip }) => {
  const theme = useTheme();

  if (active && payload && payload.length) {
    const processedPayload = reverseTooltip ? [...payload].reverse() : payload;

    return (
      <Box
        style={{
          width: 150,
          backgroundColor: theme.palette.background.tertiary,
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          rowGap: 5,
          padding: 10,
        }}
      >
        <Typography
          color="text.secondary"
          style={{ fontWeight: 450, fontSize: 14 }}
        >
          {label}
        </Typography>

        {processedPayload.map((entry, index) => (
          <Box
            key={`item-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "100%",
                backgroundColor: entry.color,
                marginRight: "8px",
              }}
            ></div>
            <Grid
              container
              direction="row"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid>
                <Typography style={{ fontWeight: 450, fontSize: 14 }}>
                  {entry.name.charAt(0).toUpperCase() + entry.name.slice(1)}
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  color="text.tertiary"
                  style={{ fontWeight: 450, fontSize: 12 }}
                >
                  {Math.round(entry.value)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    );
  }

  return null;
};
export default CustomeTooltip;
