import React from "react";

const CompetitionIcon = ({ color = "#8C8C8C", width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : "16px"}
    height={height ? height : "16px"}
    viewBox="0 0 16 16"
    fill={color}
  >
    <path
      d="M1.66667 7.03988L7.66667 10.5065C7.76802 10.5651 7.88298 10.5959 8.00001 10.5959C8.11703 10.5959 8.23199 10.5651 8.33334 10.5065L14.3333 7.03988C14.4344 6.98128 14.5182 6.89716 14.5765 6.79596C14.6348 6.69475 14.6655 6.58001 14.6655 6.46322C14.6655 6.34642 14.6348 6.23168 14.5765 6.13047C14.5182 6.02927 14.4344 5.94515 14.3333 5.88655L8.33334 2.41988C8.23199 2.36137 8.11703 2.33057 8.00001 2.33057C7.88298 2.33057 7.76802 2.36137 7.66667 2.41988L1.66667 5.88655C1.56565 5.94515 1.48179 6.02927 1.4235 6.13047C1.3652 6.23168 1.33452 6.34642 1.33452 6.46322C1.33452 6.58001 1.3652 6.69475 1.4235 6.79596C1.48179 6.89716 1.56565 6.98128 1.66667 7.03988ZM8.00001 3.76655L12.6667 6.43322L8.00001 9.13322L3.33334 6.45988L8.00001 3.76655ZM13.6667 8.95988L8.00001 12.2332L2.33334 8.95988C2.25765 8.91553 2.17395 8.88655 2.08703 8.87461C2.00012 8.86266 1.9117 8.86799 1.82685 8.89029C1.742 8.91259 1.66239 8.95141 1.59257 9.00454C1.52276 9.05766 1.46412 9.12405 1.42001 9.19988C1.37593 9.27597 1.34734 9.36003 1.33588 9.44722C1.32443 9.5344 1.33033 9.62299 1.35326 9.70789C1.37619 9.79278 1.41568 9.8723 1.46947 9.94186C1.52326 10.0114 1.59028 10.0697 1.66667 10.1132L7.66667 13.5799C7.76802 13.6384 7.88298 13.6692 8.00001 13.6692C8.11703 13.6692 8.23199 13.6384 8.33334 13.5799L14.3333 10.1132C14.4097 10.0697 14.4768 10.0114 14.5305 9.94186C14.5843 9.8723 14.6238 9.79278 14.6468 9.70789C14.6697 9.62299 14.6756 9.5344 14.6641 9.44722C14.6527 9.36003 14.6241 9.27597 14.58 9.19988C14.5359 9.12405 14.4773 9.05766 14.4074 9.00454C14.3376 8.95141 14.258 8.91259 14.1732 8.89029C14.0883 8.86799 13.9999 8.86266 13.913 8.87461C13.8261 8.88655 13.7424 8.91553 13.6667 8.95988Z"
      fill={color}
    />
  </svg>
);

export default CompetitionIcon;
