import React, { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { Box, Typography, useTheme } from "@mui/material";

const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

const MapChart = ({ countryData }) => {
  const theme = useTheme();
  const [tooltip, setTooltip] = useState({
    visible: false,
    x: 0,
    y: 0,
    content: "",
  });

  return (
    <Box
      sx={{
        background: theme.palette.background.secondary,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {tooltip.visible && (
        <Box
          sx={{
            position: "fixed",
            left: tooltip.x,
            top: tooltip.y,
            transform: "translate(-50%, -120%)",
            padding: "6.189px",
            background: theme.palette.background.tertiary,
            borderRadius: "6.19px",
            backdropFilter: "blur(3.65px)",
            pointerEvents: "none",
            zIndex: 1000,
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "14px",
              color: theme.palette.text.secondary,
              fontStyle: "normal",
              paddingBottom: "3px",
            }}
          >
            {tooltip.content.countryName}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "18px",
              color: theme.palette.text.primary,
              fontStyle: "normal",
            }}
          >
            {tooltip.content.value}
          </Typography>
        </Box>
      )}

      <ComposableMap
        projection="geoEqualEarth"
        projectionConfig={{
          scale: 155,
          rotation: [-10, 0, 0],
        }}
        width={800}
        height={440}
        style={{
          width: "100%",
          height: "auto",
          background: "transparent",
          marginBottom: "-40px",
        }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) => {
            const sortedCountries = Object.entries(countryData || {})
              .map(([country, value]) => ({ country, value: Number(value) }))
              .sort((a, b) => a.value - b.value);

            const countryPositionMap = {};
            sortedCountries.forEach((item, index) => {
              countryPositionMap[item.country] = index;
            });

            const totalCountries = sortedCountries.length;
            const groupSize = Math.ceil(totalCountries / 3);

            return geographies
              .filter((geo) => geo.properties?.name !== "Antarctica")
              .map((geo) => {
                let countryName =
                  geo.properties?.name || geo.properties?.NAME || "Unknown";

                if (countryName === "United States of America") {
                  countryName = "United States";
                }

                const countryValue = countryData?.[countryName] ?? "0";
                let fillColor = theme.palette.map.lightPurple;

                if (countryName in countryData) {
                  const position = countryPositionMap[countryName];

                  if (position < groupSize) {
                    fillColor = theme.palette.map.purple;
                  } else if (position < groupSize * 2) {
                    fillColor = theme.palette.map.darkerPurple;
                  } else {
                    fillColor = theme.palette.map.darkestPurple;
                  }
                }

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseMove={(evt) => {
                      setTooltip({
                        visible: true,
                        x: evt.clientX,
                        y: evt.clientY,
                        content: {
                          countryName,
                          value:
                            countryValue !== "No Data"
                              ? `${countryValue}`
                              : "No Data Available",
                        },
                      });
                    }}
                    onMouseLeave={() =>
                      setTooltip({ ...tooltip, visible: false })
                    }
                    style={{
                      default: {
                        fill: fillColor,
                        outline: "none",
                        transition: "border 0.2s ease-in-out",
                      },
                      hover: {
                        fill: fillColor,
                        stroke: "#0a8ce9",
                        strokeWidth: 1,
                      },
                      pressed: {
                        fill: fillColor,
                        outline: "none",
                      },
                    }}
                  />
                );
              });
          }}
        </Geographies>
      </ComposableMap>
    </Box>
  );
};

export default MapChart;
