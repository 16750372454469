import { Box, useTheme } from "@mui/material";
import { getRiskColor } from "../../../../../AEO/utils/DataUtils";

const RiskTableCell = ({ value, piriority }) => {
  const theme = useTheme();
  return (
    <Box
      style={{
        backgroundColor: getRiskColor(piriority, theme)[0],
        color: getRiskColor(piriority, theme)[1],
        fontSize: 13,
        borderRadius: 30,
        fontWeight: 400,
        padding: "5px 18px",
        display: "inline-block",
        maxWidth: "fit-content",
        height: "auto",
        lineHeight: "1.5",
        textAlign: "center",
      }}
    >
      {value}
    </Box>
  );
};
export default RiskTableCell;
