import { Box, Typography } from "@mui/material";
import Wrapper from "../Wrapper/Wrapper";
import LoginIcon from "../../../../CommonComponents/customIcons/LoginIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { endpoints } from "../../../../services/api/endpoints";
import AccountsCard from "../Card/Card";
import EastIcon from "@mui/icons-material/East";
import FormikTextField from "../../../../CommonComponents/FormField/components/TextField/TextField";
import { Formik } from "formik";
import PasswordField from "../../../../CommonComponents/FormField/components/PasswordField";

const ResetPassword = () => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const token = queryParams.get("token");

  const handleOnClick = (values) => {
    setError("");
    setIsLoading(true);
    const data = { id, token, password: values.password };
    endpoints.auth
      .resetPassword(data)
      .then((response) => {
        setIsLoading(false);
        navigate(`confirm`);
      })
      .catch((err) => {
        setIsLoading(false);
        setError("Failed to send reset instructions!");
      });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 8) {
      errors.password = "Password must be at least 8 characters";
    }

    if (values.confirmPassword && values.password !== values.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    return errors;
  };

  const initialValues = useMemo(() => ({ password: "" }), []);
  return (
    <Wrapper isfullLength>
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnClick}
        validate={validate}
      >
        {({ errors, handleSubmit }) => (
          <AccountsCard
            icon={<LoginIcon />}
            title="Set new password"
            backToLoginButton
            buttonIcon={<EastIcon />}
            buttonLabel="Reset password"
            description="Your new password must be different from previously used passwords."
            error={error || errors.password || errors.confirmPassword}
            isLoading={isLoading}
            onButtonClick={() => handleSubmit()}
            rowGap={20}
            isButtonDisabled={errors.password || errors.confirmPassword}
          >
            <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
              <PasswordField placeholder="********" />

              <PasswordField
                fieldName="confirmPassword"
                title=" Confirm Password"
                placeholder="********"
              />
            </Box>
          </AccountsCard>
        )}
      </Formik>
    </Wrapper>
  );
};
export default ResetPassword;
