import LineChart from "../../../../CommonComponents/Charts/LineChart";
import ChartCard from "../ChartCard/ChartCard";
import BrandsList from "../BrandsList";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  reorderKeys,
  transformCompetitorsLineChartData,
  transformLLMLineChartData,
  transformAgentsTrendData,
} from "../../utils/DataUtils";
import { Typography, useTheme } from "@mui/material";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import { dummyLineData } from "../../consts/consts";
import { endpoints } from "../../../../services/api/endpoints";

const LineChartCard = ({
  title,
  tooltipPrimary,
  tooltipSecondary,
  hasLLMselect,
  hasCaledarSelect,
  hasCountryDropdown,
  height,
  chartHeight,
  chartHasTooltip,
  chartHasGrid,
  chartHasXasix,
  chartHasYaxis,
  chartHasMarginTop,
  chartHasMarginLeft,
  icon,
  isLLMComparison,
  hasBrandLabelOnly,
  isGlobal,
  isMainSelected,
  isAgentTrends,
}) => {
  const {
    date: globalDate,
    llm,
    initialDate: globalInitialDate,
    selectedCountries,
    noData,
  } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const { brandId } = useParams();
  const [data, setData] = useState([]);
  const currentWorkspaceName = localStorage.getItem("worskapceName");
  const [initialBrands, setInititalBrands] = useState([]);
  const [date, setDate] = useState(globalDate);
  const [initialDate, setInitialDate] = useState(globalInitialDate);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      if (date.length > 0 && date[0] && date[1]) {
        setLoading(true);
        const commonValues = {
          brandId,
          start_date: date[0].format("YYYY-MM-DD"),
          end_date: date[1].format("YYYY-MM-DD"),
          LLMs: selectedLLM,
        };

        try {
          let response;
          if (isGlobal) {
            response = await endpoints.aeo.getGlobalPerformanceLineChart({
              ...commonValues,
              countries: selectedCountry
                ? [selectedCountry]
                : selectedCountries,
            });
          } else if (isAgentTrends) {
            response =
              await endpoints.agentAnalytics.getAgentAnalyticsTrendLineChart({
                ...commonValues,
              });
          } else {
            response = await endpoints.aeo.getVisibilityPerformanceLineChart({
              ...commonValues,
              comparsion_type: isLLMComparison ? "LLMs" : "Competitors",
            });
          }

          const filteredData = isLLMComparison
            ? transformLLMLineChartData(response.data)
            : isAgentTrends
            ? transformAgentsTrendData(response.data.data)
            : transformCompetitorsLineChartData(response.data, selectedLLM[0]);

          setData(filteredData.transformedData);
          setInititalBrands(filteredData?.keys);
          if (!isLLMComparison) {
            setSelectedBrands(filteredData?.keys);
          }
        } catch (error) {
          setError(true);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [
    brandId,
    selectedLLM,
    isLLMComparison,
    date,
    isGlobal,
    selectedCountries,
    selectedCountry,
  ]);

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);

  useEffect(() => {
    setDate(globalDate);
  }, [globalDate]);

  useEffect(() => {
    setInitialDate(globalInitialDate);
  }, [globalInitialDate]);

  const colorMap = useMemo(() => {
    const colors = [
      theme.palette.brands.A,
      theme.palette.brands.B,
      theme.palette.brands.C,
      theme.palette.brands.D,
      theme.palette.brands.E,
      theme.palette.brands.F,
      theme.palette.brands.G,
    ];
    const brands = reorderKeys(initialBrands, currentWorkspaceName);
    const map = {};
    brands.forEach((brand, index) => {
      map[brand] = colors[index % colors.length];
    });
    return map;
  }, [initialBrands]);

  useEffect(() => {
    noData && setData(dummyLineData);
  }, [noData]);
  return (
    <ChartCard
      icon={icon}
      title={title}
      tooltipMessagePrimary={tooltipPrimary}
      tooltipMessageSecondary={tooltipSecondary}
      hasCalendarSelect={hasCaledarSelect}
      hasLLMselect={hasLLMselect}
      height={height}
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
      hasCountryDropdown={hasCountryDropdown}
      onCountryChange={setSelectedCountry}
      setDate={setDate}
      date={date}
      initialDate={initialDate}
      isLoading={loading}
      isError={error}
    >
      <LineChart
        data={data}
        xaxisDatakey="xAxis"
        hasXasix={chartHasXasix}
        hasYaxis={chartHasYaxis}
        height={chartHeight}
        hasTooltip={chartHasTooltip}
        marginTop={chartHasMarginTop}
        marginLeft={chartHasMarginLeft}
        hasGrid={chartHasGrid}
        mainArea={
          noData
            ? "NoGood"
            : isLLMComparison
            ? selectedLLM[0]
            : isAgentTrends
            ? "AI Traffic"
            : currentWorkspaceName
        }
        secondaryLinesDataKeys={selectedBrands.filter((key) =>
          isAgentTrends ? key !== "AI Traffic" : key !== currentWorkspaceName
        )}
        isMainSelected={
          isMainSelected ||
          hasBrandLabelOnly ||
          selectedBrands.filter((key) =>
            isAgentTrends ? key === "AI Traffic" : key === currentWorkspaceName
          ).length > 0
        }
        colorMap={colorMap}
      />
      {!isLLMComparison && (
        <BrandsList
          brands={reorderKeys(initialBrands, currentWorkspaceName)}
          selectedBrands={selectedBrands}
          setSelectedBrands={setSelectedBrands}
          colorMap={colorMap}
        />
      )}
      {hasBrandLabelOnly && (
        <div
          style={{
            display: "inline-block",
          }}
        >
          <Typography
            style={{
              display: "inline-block",
              border: `1px solid ${theme.palette.brands.A}`,
              padding: "10px 12px",
              fontSize: 14,
              borderRadius: 11,
              cursor: "pointer",
              backgroundColor: theme.palette.brands.A,
            }}
            color="text.chiptext"
          >
            {currentWorkspaceName}
          </Typography>
        </div>
      )}
    </ChartCard>
  );
};
export default LineChartCard;
