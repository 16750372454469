import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Avatar,
  Grid,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import Doc from "../../../../CommonComponents/customIcons/doc";
import DeleteIcon from "../../../../CommonComponents/customIcons/DeleteIcon";
import { endpoints } from "../../../../services/api/endpoints";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { format } from "date-fns";
import Tag from "./components/Tag/Tag";
import CustomSearchInput from "./components/CustomSearch/CustomSearchInput";
import RecentsCard from "../RecentsCard/RecentsCard";
import { useBlog } from "../../../../contexts/blogContext";
import { useTheme } from "@emotion/react";
import EditIcon from "../../../../CommonComponents/customIcons/EditIcon";
import AnimationLoader from "../../../../CommonComponents/AnimationLoader/AnimationLoader";

const RecentSection = ({ isDashboardView = false }) => {
  const { brandId } = useParams();
  const [data, setData] = useState([]);
  const [visibleRows, setVisibleRows] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { setEditedBlog: setEditedBlogContext } = useBlog();
  const location = useLocation();
  const [hasAnyRecentArticles, setHasAnyRecentArticles] = useState();
  const theme = useTheme();
  const refetch = () => {
    setLoading(true);

    endpoints.brands
      .getRecents(brandId)
      .then((response) => {
        setData(response.data);
        setHasAnyRecentArticles(!!response.data.length);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching recent data:", error);
        setError("Failed to fetch recent data");
        setLoading(false);
      });
  };
  useEffect(() => {
    refetch();
  }, [brandId]);

  const handleLoadMore = () => {
    setVisibleRows((prev) => Math.min(prev + 5, data.length));
  };
  const handleDelete = async (id) => {
    await endpoints.brands.deleteRecent(id);
    refetch();
  };
  const filteredData = useMemo(() => {
    return data.filter(
      (row) =>
        row.title?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        row.user?.first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        row.user?.last_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [data, searchQuery]);

  return loading ? (
    <AnimationLoader text="Loading Recent Articles..." />
  ) : (
    hasAnyRecentArticles && (
      <Box sx={{ margin: "60px auto", pl: "0px", pr: "0px" }}>
        <Grid
          container
          alignItems="flex-start"
          sx={{
            padding: "0px",
            borderTop: "solid 1px",
            borderColor: theme.palette.border.tertiary,
            mb: 7,
          }}
        >
          <Grid
            item
            xs={6}
            sx={{
              paddingTop: 3,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "14px",
                color: theme.palette.text.tertiary,
                fontWeight: 500,
                lineHeight: "18px",
                textAlign: "left",
              }}
            >
              {isDashboardView ? "AI Content Writer" : "All Files"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              paddingTop: "18px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CustomSearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </Grid>
        </Grid>

        <Box sx={{ marginBottom: "10px" }}>
          <Grid container>
            <Grid item xs={5}>
              <Typography
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: "14.805px",
                  fontWeight: 500,
                  lineHeight: "18.506px",
                  pl: 2,
                }}
              >
                Title
              </Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: "14.805px",
                  fontWeight: 500,
                  lineHeight: "18.506px",
                }}
              >
                Type
              </Typography>
            </Grid>
            <Grid item xs={2.9}>
              <Typography
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: "14.805px",
                  fontWeight: 500,
                  lineHeight: "18.506px",
                  textAlign: "left",
                }}
              >
                Last Edited
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: "14.805px",
                  fontWeight: 500,
                  lineHeight: "18.506px",
                  textAlign: "left",
                }}
              >
                Author
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {loading ? (
          <div
            style={{
              position: "absolute",
              top: "90%",
              left: "60%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              style={{
                width: 20,
                height: 20,
                color: "#2D333A",
              }}
            />
          </div>
        ) : (
          <>
            {filteredData.slice(0, visibleRows).map((row) => (
              <Box
                key={row.id}
                sx={{
                  padding: "11.1px 20px 11.1px 20px",
                  backgroundColor: theme.palette.background.secondary,
                  borderRadius: "10px",
                  marginBottom: "10px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: theme.palette.background.tertiary,
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  setEditedBlogContext(row.content);
                  navigate(
                    `/dashboard/${brandId}/blog/${row.title}/${row.id}`,
                    {
                      state: { author: row.user },
                    }
                  );
                }}
              >
                <Grid container alignItems="center">
                  <Grid item xs={5}>
                    <Box display="flex" alignItems="center">
                      <EditIcon color={theme.palette.text.primary} />
                      <Typography
                        noWrap
                        sx={{
                          maxWidth: "345px",
                          textAlign: "left",
                          color: theme.palette.text.primary,
                          fontSize: "14.805px",
                          fontWeight: 500,
                          lineHeight: "18.506px",
                          pl: 1,
                        }}
                      >
                        {row.title}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Tag sx={{ color: theme.palette.text.primary }}>
                      <Doc color={theme.palette.text.primary}></Doc>
                      {row.type}
                    </Tag>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{
                        color: theme.palette.text.tertiary,
                        fontSize: "12.954px",
                        fontWeight: 450,
                        lineHeight: "15.315px",
                        letterSpacing: "-0.13px",
                        textAlign: "left",
                      }}
                    >
                      {format(
                        new Date(row.updated_at),
                        "MMMM dd, yyyy '@' h:mma"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: theme.palette.text.primary,
                        fontSize: "14.805px",
                        fontWeight: 400,
                        lineHeight: "18.506px",
                        textAlign: "left",
                      }}
                    >
                      <Avatar
                        sx={{
                          width: 20,
                          height: 20,
                          marginRight: "10px",
                          backgroundColor: theme.palette.background.primary,
                          color: "#797979",
                          textAlign: "center",
                          fontSize: "11.537px",
                          fontWeight: 400,
                        }}
                        src={row.user.profile?.profile_picture}
                      >
                        {row.user.first_name
                          ? row.user.first_name.charAt(0)
                          : row.user.email.charAt(0)}
                      </Avatar>
                      <Typography noWrap sx={{ maxWidth: "85px" }}>
                        {row.user.first_name && row.user.last_name
                          ? `${row.user.first_name} ${row.user.last_name.charAt(
                              0
                            )}.`
                          : row.user.email}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={0.5} sx={{ textAlign: "right" }}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(row.id);
                      }}
                    >
                      <DeleteIcon color={theme.palette.text.tertiary} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            ))}

            {visibleRows < filteredData.length && (
              <Button
                onClick={handleLoadMore}
                sx={{
                  display: "block",
                  margin: "10px 0 0 0",
                  color: theme.palette.text.tertiary,
                  padding: "6px 12px",
                  fontSize: "14px",
                  fontWeight: 450,
                  lineHeight: "20px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                Load More
              </Button>
            )}
          </>
        )}
      </Box>
    )
  );
};

export default RecentSection;
