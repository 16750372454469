import ChartCard from "../../../AEO/components/ChartCard/ChartCard";
import TextWithmovement from "../../../AEO/components/TextWithMovement/TextWithMovement";

const OverAllCard = ({
  title,
  tooltipMessagePrimary,
  tooltipMessageSecondary,
  tooltipMessageTertiary,
  description,
  loading,
  error,
  value,
  movement,
  isIncrease,
  percentageValue,
}) => {
  return (
    <ChartCard
      title={title}
      tooltipMessagePrimary={tooltipMessagePrimary}
      tooltipMessageSecondary={tooltipMessageSecondary}
      tooltipMessageTertiary={tooltipMessageTertiary}
      description={description}
      height={130}
      isLoading={loading}
      isError={error}
    >
      <TextWithmovement
        score={value}
        movement={movement}
        isIncrease={isIncrease}
        percentageValue={percentageValue}
      />
    </ChartCard>
  );
};
export default OverAllCard;
