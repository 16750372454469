import { Navigate, Outlet, useParams, useLocation } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { useBrand } from "../contexts/BrandContext";

export const BrandAccessGuard = () => {
  const { loggedInUser } = useUser();
  const { brandId } = useParams();
  const { selectedBrand } = useBrand();
  const location = useLocation();

  if (!brandId) {
    return <Outlet />;
  }

  const canAccessBrand = loggedInUser?.brands?.some(
    (brand) => brand.id === Number(brandId)
  );

  if (!canAccessBrand) {
    return (
      <Navigate to="/workspace-selection" replace state={{ from: location }} />
    );
  }

  if (selectedBrand !== Number(brandId)) {
    if (!selectedBrand) {
      return (
        <Navigate
          to="/workspace-selection"
          replace
          state={{ from: location }}
        />
      );
    }
    return <Navigate to={`/dashboard/${selectedBrand}`} replace />;
  }

  return <Outlet />;
};
