import {
  Box,
  Card,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowOutwardSharpIcon from "@mui/icons-material/ArrowOutwardSharp";
import { useNavigate } from "react-router-dom";
import LLMDropDown from "../LLMDropdown/LLMDropdown";
import DateFilter from "../DateFilter/DateFilter";
import SingleCountryDropdown from "../SingleCountryDropdown";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import AnimationLoader from "../../../../CommonComponents/AnimationLoader/AnimationLoader";
import ErrorTypography from "../../../../CommonComponents/FormField/components/ErrorMessage/components/ErrorTypography/ErrorTypography";
import CustomDropDown from "../CustomDropDown";
import { useState } from "react";

const ChartCard = ({
  icon,
  title,
  tooltipMessagePrimary,
  tooltipMessageSecondary,
  tooltipMessageTertiary,
  navigateTo,
  hasLLMselect,
  hasCountryDropdown,
  hasCalendarSelect,
  children,
  height = 345,
  selectedLLM = ["ChatGpt"],
  setSelectedLLM,
  date,
  setDate,
  initialDate,
  llmIsMultipleSelect,
  isLoading,
  isError,
  onCountryChange,
  padding,
  description,
  rowGap = 0,
  hasCustomFilter,
  customFilterOptions,
}) => {
  const navigate = useNavigate();
  const { noData } = useGlobalFilters();
  const theme = useTheme();
  return (
    <Card
      style={{
        borderRadius: 10,
        padding: padding ? padding : noData ? 0 : 16,
        boxShadow: "none",
        height: height,
        position: "relative",
        backgroundColor: theme.palette.background.secondary,
        display: "flex",
        flexDirection: "column",
        rowGap: rowGap,
      }}
    >
      <Grid
        container
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          padding: noData && 1,
          flexShrink: 0,
        }}
      >
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: 4,
            alignItems: "center",
          }}
        >
          {icon}
          <Typography
            color="text.primary"
            style={{ fontWeight: 500, fontSize: 14 }}
          >
            {title}
          </Typography>
          {tooltipMessagePrimary && (
            <Tooltip
              title={
                <div>
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    {tooltipMessagePrimary}
                  </div>
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    {tooltipMessageSecondary}
                  </div>
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    {tooltipMessageTertiary}
                  </div>
                </div>
              }
            >
              <InfoOutlinedIcon
                sx={{ color: "text.tertiary", width: 15, height: 15 }}
              />
            </Tooltip>
          )}
        </Grid>

        <Grid
          item
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            columnGap: 2,
          }}
        >
          {hasCustomFilter && <CustomDropDown options={customFilterOptions} />}
          {hasCountryDropdown && (
            <SingleCountryDropdown
              backgroundColorValue="tertiary"
              onChange={(selectedCountry) => {
                onCountryChange(selectedCountry);
              }}
            />
          )}
          {hasCalendarSelect && initialDate && (
            <DateFilter
              value={date}
              setValue={setDate}
              startDate={initialDate[0]}
              endDate={initialDate[1]}
              backgroundColorValue="tertiary"
            />
          )}

          {hasLLMselect && (
            <LLMDropDown
              selectedLLM={selectedLLM}
              setSelectedLLM={setSelectedLLM}
              backgroundColorValue="tertiary"
              isMultipleSelect={llmIsMultipleSelect}
            />
          )}
          {navigateTo && (
            <IconButton
              style={{
                backgroundColor: theme.palette.background.tertiary,
                padding: 7,
              }}
              onClick={() => navigate(navigateTo)}
            >
              <ArrowOutwardSharpIcon
                style={{
                  color: theme.palette.text.primary,
                  width: 15,
                  height: 15,
                }}
              />
            </IconButton>
          )}
        </Grid>
      </Grid>

      <div
        style={{
          flex: 1,
          position: "relative",
          overflowY: "auto",
          height: noData && "100%",
          overflow: "auto",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
        }}
      >
        {noData && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.01)",
              zIndex: 1,
              backdropFilter: "blur(5px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
        )}
        {/* {isError && !isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#FFEFF0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
              borderRadius: 11,
            }}
          >
            <ErrorTypography errorMessage="Failed to load data" />{" "}
          </div>
        )} */}
        {description && (
          <Typography
            sx={{
              color: theme.palette.text.tertiary,
              margin: "10px 0px",
              borderRadius: "7px",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            {description}
          </Typography>
        )}{" "}
        <Box style={{ padding: noData ? 16 : 0 }}>{children}</Box>
      </div>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: theme.palette.background.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <AnimationLoader
            text="Generating chart..."
            width={90}
            textFontSize={17}
          />
        </div>
      )}
    </Card>
  );
};
export default ChartCard;
