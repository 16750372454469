import { createTheme } from "@mui/material";
import { fontFamily } from "./ThemeFonts";
const commonTypography = {
  h2: {
    fontSize: "2.25rem",
  },
  body1: {
    fontSize: "0.875rem",
  },
  fontFamily,
};
export const lightTheme = createTheme({
  typography: { ...commonTypography },
  palette: {
    background: {
      primary: "#F5F5F5",
      secondary: "#FFFFFF",
      tertiary: "#F5F5F5",
      quaternary: "#E5E5E5",
      activeHover: "#E3E8FE",
      positive: "#33AD5C33",
      negative: "#EA333333",
      neutral: "#FFCC0033",
      brand20: "#8DA3FA33",
      brand: "#AABAFB",
      brandhover: "#E3E8FE",
      secondaryhover: "#FAFAFA",
      disabled: "#F5F5F5",
      primaryhover: "#E5E5E5",
      tertiaryhover: "#E5E5E5",
      tertiarypressed: "#D9D9D9",
      backdrop: "#727272",
      selectedDropdown: "#C6D1FD",
    },
    text: {
      primary: "#0D0D0D",
      secondary: "#666666",
      tertiary: "#8C8C8C",
      quaternary: "#BFBFBF",
      chiptext: "#0D0D0D",
      positive: "#007A29",
      negative: "#B70000",
      neutral: "#665200",
      brandbold: "#5A70C7",
      brand: "#718CF9",
      error: "#E50000",
      disabled: "#B2B2B2",
      link: "#5A70C7",
      warning: "#FFD633",
      archive: "#C95CFF",
    },
    addCountry: {
      background: "#FFFFFF",
      text: "#0D0D0D",
    },
    border: {
      primary: "#B2B2B2",
      secondary: "#CCCCCC",
      tertiary: "#E5E5E5",
      quaternary: "#F5F5F5",
      brand: "#718CF9",
    },
    filters: {
      background: "#FFFFFF",
    },
    circle: {
      positive: "#009933",
      positive20: "#33AD5C33",
      negative: "#E50000",
      neutral: "#FFD633",
    },
    brands: {
      A: "#8DA3FA",
      B: "#FF9933",
      C: "#FFD633",
      D: "#33D6D6",
      E: "#33BBFF",
      F: "#C95CFF",
      G: "#FA87D4",
      hoverA: "#8DA3FA33",
      hoverB: "#FF993333",
      hoverC: "#FFD63333",
      hoverD: "#33D6D633",
      hoverE: "#33BBFF33",
      hoverF: "#C95CFF33",
      hoverG: "#FA87D433",
    },
    llms: {
      A: "#DDFA9A",
      B: "#99EBEB",
      C: "#E4ADFF",
      D: "#FFCC99",
      E: "#99DDFF",
      F: "#B3D0FF",
      hoverA: "#D2F87833",
      hoverB: "#33D6D633",
      hoverC: "#C95CFF33",
      hoverD: "#FF993333",
      hoverE: "#33BBFF33",
      hoverF: "#B3D0FF",
    },
    buttons: {
      primary: "#8DA3FA",
      selected: "#E3E8FE",
      secondaryhover: "#FAFAFA",
    },
    bars: {
      purble: "#8DA3FA",
      positive: "#33AD5C33",
      negative: "#EA333333",
      neutral: "#FFCC0033",
    },
    dropdown: {
      hover: "#F5F5F5",
      selected: "#8DA3FA33",
    },
    stepper: {
      activeBackground: "#E3E8FE",
      stepCircle: "#AABAFB",
      stepCircleCompleted: "#E3E8FE",
    },
    map: {
      lightPurple: "#E3E8FE",
      purple: "#AABAFB",
      darkerPurple: "#8DA3FA",
      darkestPurple: "#718CF9",
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    background: {
      primary: "#1A1A1A",
      secondary: "#262626",
      tertiary: "#404040",
      quaternary: "#404040",
      activeHover: "#5A70C7",
      positive: "#33AD5C33",
      negative: "#EA333333",
      neutral: "#FFCC0033",
      brand20: "#8DA3FA33",
      brand: "#718CF9",
      brandhover: "#5A70C7",
      secondaryhover: "#404040",
      disabled: "#333333",
      primaryhover: "#404040",
      tertiaryhover: "#4D4D4D",
      tertiarypressed: "595959",
      selectedDropdown: "#445495",
      backdrop: "#727272",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#D9D9D9",
      tertiary: "#B2B2B2",
      quaternary: "#999999",
      chiptext: "#0D0D0D",
      positive: "#66C285",
      negative: "#F59999",
      neutral: "#FFEB99",
      brandbold: "#C6D1FD",
      brand: "#8DA3FA",
      error: "#EF6666",
      disabled: "#4D4D4D",
      link: "#AABAFB",
      warning: "#FFE066",
      archive: "#C95CFF",
    },
    addCountry: {
      background: "#262626",
      text: "#FFFFFF",
    },
    border: {
      primary: "#999999",
      secondary: "#666666",
      tertiary: "#404040",
      quaternary: "#333333",
      brand: "#AABAFB",
    },
    filters: {
      background: "#262626",
    },
    circle: {
      positive: "#66C285",
      negative: "#EF6666",
      neutral: "#FFE066",
    },
    brands: {
      A: "#8DA3FA",
      B: "#FF9933",
      C: "#FFD633",
      D: "#33D6D6",
      E: "#33BBFF",
      F: "#C95CFF",
      G: "#FA87D4",
      hoverA: "#8DA3FA33",
      hoverB: "#FF993333",
      hoverC: "#FFD63333",
      hoverD: "#33D6D633",
      hoverE: "#33BBFF33",
      hoverF: "#C95CFF33",
      hoverG: "#FA87D433",
    },
    llms: {
      A: "#DDFA9A",
      B: "#99EBEB",
      C: "#E4ADFF",
      D: "#FFCC99",
      E: "#99DDFF",
      F: "#B3D0FF",
      hoverA: "#D2F87833",
      hoverB: "#33D6D633",
      hoverC: "#C95CFF33",
      hoverD: "#FF993333",
      hoverE: "#33BBFF33",
      hoverF: "#B3D0FF",
    },
    buttons: {
      primary: "#8DA3FA",
      selected: "#445495",
      secondaryhover: "#333333",
    },
    bars: {
      purble: "#8DA3FA",
      positive: "#33AD5C33",
      negative: "#EA333333",
      neutral: "#FFCC0033",
    },
    dropdown: {
      hover: "#404040",
      selected: "#8DA3FA33",
    },
    stepper: {
      activeBackground: "#5A70C7",
      stepCircle: "#718CF9",
      stepCircleCompleted: "#5A70C7",
    },
    map: {
      lightPurple: "#445495",
      purple: "#5A70C7",
      darkerPurple: "#718CF9",
      darkestPurple: "#8DA3FA",
    },
  },
  typography: { ...commonTypography },
});
