import { Card, Typography, Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AuthorStampIcon from "../../../../CommonComponents/customIcons/AuthorStampIcon";

const ProfileSettingsCard = ({ card }) => {
  const { iconType, title, description, navigateTo } = card;
  const theme = useTheme();
  const navigate = useNavigate();

  const renderIcon = () => {
    switch (iconType) {
      case "settings":
        return <SettingsOutlinedIcon color={theme.palette.text.primary} />;
      case "authorStamp":
        return <AuthorStampIcon color={theme.palette.text.primary} />;
      default:
        return null;
    }
  };

  return (
    <Card
      style={{
        borderRadius: "12px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        border: `1px solid ${theme.palette.background.secondary}`,
        position: "relative",
        height: "188px",
        boxShadow: "none",
        backgroundColor: theme.palette.background.secondary,
      }}
      sx={{
        "&:hover": {
          backgroundColor: `${theme.palette.background.secondaryhover} !important`,
          border: `1px solid ${theme.palette.border.tertiary} !important`,
        },
      }}
      onClick={() => {
        navigate(`/account/profile-settings/${navigateTo}`);
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          width: "40px",
          height: "40px",
          backgroundColor: "background.tertiary",
          borderRadius: "50%",
        }}
      >
        {renderIcon()}
      </Box>
      <Typography
        variant="body"
        sx={{
          fontSize: 24,
          mt: { xs: 1, md: 4 },
          fontWeight: 500,
          lineHeight: "32px",
          letterSpacing: "-0.48px",
        }}
        color="text.primary"
      >
        {title}
      </Typography>
      <Typography style={{ fontSize: 14 }} color="text.secondary">
        {description}
      </Typography>
    </Card>
  );
};

export default ProfileSettingsCard;
