/* eslint-disable */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTheme } from "@mui/styles";
import PopUp from "./../OptimizationPopUp/Popup";
import SourceTableCell from "./components/SourceTableCell/SourceTableCell";
import TopicTableCell from "./components/TopicTableCell";
import TypographyTableCell from "./components/TypographyTableCell/TypographyTableCell";
import RiskTableCell from "./components/RiskTableCell";
import ArrowRightIcon from "../../../../CommonComponents/customIcons/ArrowRightIcon";
import { Typography, Box } from "@mui/material";
import StatusTableCell from "./components/StatusTableCell/StatusTableCell";
import { endpoints } from "../../../../services/api/endpoints";
import { useParams } from "react-router-dom";
import SentimentTableCell from "./components/SentimentTableCell";
import { convertFiltersToParams } from "../../../../CommonComponents/BaseTable/utils/filterUtils";
import { createHeaderCellWithTooltip } from "../../../../CommonComponents/BaseTable/utils/headerUtils";
import BaseTable from "../../../../CommonComponents/BaseTable";
import { useDebounce } from "use-debounce";

const SourceTrackingTable = ({ isOverview, date, selecetdLLm }) => {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [searchQuery, setSearchQuery] = useState();
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const { brandId, topicName } = useParams();
  const topic = topicName ? topicName.replace(/-/g, " ") : "";
  const theme = useTheme();

  const handleOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedRow({});
    setOpen(false);
  };

  const fetchData = useCallback(
    async ({
      paginationModel,
      sortModel,
      searchQuery: queryFromTable,
      activeFilters,
    }) => {
      try {
        let orderBy = "created_at";
        let order = "desc";

        if (sortModel.length > 0) {
          orderBy = sortModel[0].field;
          order = sortModel[0].sort;
        }

        // Convert filter objects to query parameters
        const filterParams = convertFiltersToParams(activeFilters);

        const start_date =
          date && date[0] ? date[0].format("YYYY-MM-DD") : null;
        const end_date = date && date[1] ? date[1].format("YYYY-MM-DD") : null;

        // Use either the query from the table or our local debounced query
        const currentSearchQuery = queryFromTable || debouncedSearchQuery;

        const response = await endpoints.optimization.getSourceTracking(
          brandId,
          {
            page: paginationModel.page + 1,
            page_size: paginationModel.pageSize,
            ordering: order === "asc" ? orderBy : `-${orderBy}`,
            created_after: start_date,
            created_before: end_date,
            llm_name: selecetdLLm[0],
            source_url: currentSearchQuery,
            topic: topic,
            ...filterParams,
          }
        );

        const rowsWithIds = response.data.results.map((row, index) => ({
          ...row,
          id: row.id || `row-${index}`,
        }));

        return {
          data: rowsWithIds,
          count: response.data.count,
        };
      } catch (error) {
        console.error("Error fetching data:", error);
        return {
          data: [],
          count: 0,
        };
      }
    },
    [brandId, date, selecetdLLm, debouncedSearchQuery, topic]
  ); // Only dependencies that should trigger refetching

  // Action cell renderer
  const ActionCellRenderer = (params) => {
    return (
      <Typography
        sx={{
          color: theme.palette.text.primary,
          fontSize: "12px",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          columnGap: 0.5,
          borderRadius: "30px",
          padding: "5px 8px",
          maxWidth: "fit-content",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: theme.palette.background.quaternary,
          },
        }}
        className="should-optimize-typography"
        onClick={(e) => {
          e.stopPropagation();
          handleOpen(params.row);
        }}
      >
        {params.row.should_optimize ? "Optimize" : "View"}{" "}
        <ArrowRightIcon color={theme.palette.text.primary} />
      </Typography>
    );
  };

  // Define columns with memoization
  const getColumns = useCallback(() => {
    const baseColumns = [
      {
        field: "created_at",
        headerName: "Date",
        flex: 1,
        minWidth: 100,
        renderHeader: () =>
          createHeaderCellWithTooltip(
            "Date",
            "The date the source was added and scored for AI optimization.",
            theme
          ),
        renderCell: (params) => (
          <TypographyTableCell
            value={new Date(params.row.created_at).toLocaleDateString()}
          />
        ),
        sortable: true,
      },
      {
        field: "source_url",
        headerName: "Source",
        flex: 1,
        minWidth: 200,
        renderHeader: () =>
          createHeaderCellWithTooltip(
            "Source",
            "The URL referencing your brand, a competitor, or tracked search topic.",
            theme
          ),
        renderCell: (params) => (
          <SourceTableCell
            value={params.row.source}
            category={params.row.category}
            navigate={params.row.source_url}
          />
        ),
        sortable: true,
      },
      {
        field: "type",
        headerName: "Type",
        flex: 1,
        minWidth: 100,
        renderHeader: () =>
          createHeaderCellWithTooltip(
            "Type",
            "Indicates the source's ownership classification: Owned (brand-controlled), Earned (outside brand's direct control), or Operated (partially brand-controlled; e.g., your brand's social media).",
            theme
          ),
        renderCell: (params) => <TypographyTableCell value={params.row.type} />,
        sortable: true,
      },
      {
        field: "sentiment",
        headerName: "Sentiment",
        flex: 1,
        minWidth: 120,
        renderHeader: () =>
          createHeaderCellWithTooltip(
            "Sentiment",
            "The tone (positive, neutral, negative) towards your brand within the source.",
            theme
          ),
        renderCell: (params) => (
          <SentimentTableCell score={params.row.sentiment} />
        ),
        sortable: true,
      },
      {
        field: "brand_mentions",
        headerName: "Mentions",
        flex: 1,
        minWidth: 150,
        renderHeader: () =>
          createHeaderCellWithTooltip(
            "Mentions",
            "The number of times your brand is mentioned within the source.",
            theme
          ),
        renderCell: (params) => (
          <TypographyTableCell value={params.row.brand_mentions} />
        ),
        sortable: true,
      },
      {
        field: "influence_score",
        headerName: "Quality",
        flex: 1,
        minWidth: 150,
        renderHeader: () =>
          createHeaderCellWithTooltip(
            "Quality",
            "Indicates the degree to which the AI model uses this source to generate outputs and answers. Scores are out of 100. Higher scores indicate the source is more AI-friendly or favored by AI.",
            theme
          ),
        renderCell: (params) => (
          <TypographyTableCell value={params.row.influence_score} />
        ),
        sortable: true,
      },
      {
        field: "impact_score",
        headerName: "AVI",
        flex: 1,
        minWidth: 100,
        renderHeader: () =>
          createHeaderCellWithTooltip(
            "AVI",
            "Evaluates how strongly each source impacts your brand's AI search visibility by factoring in frequency, ranking, mention quality, and sentiment. Scores are out of 100. Higher scores indicate the source has a stronger, more positive impact on AI search visibility.",
            theme
          ),
        renderCell: (params) => (
          <div
            style={{ height: "auto", display: "flex", alignItems: "center" }}
          >
            <RiskTableCell
              value={params.row.impact_score}
              piriority={params.row.optimization_priority}
            />
          </div>
        ),
        sortable: true,
      },
      {
        field: "action",
        headerName: "Action",
        flex: 1,
        minWidth: 120,
        renderHeader: () =>
          createHeaderCellWithTooltip(
            "Action",
            "Indicates whether the source requires optimization. Sources without an action do not currently require optimization.",
            theme
          ),
        renderCell: ActionCellRenderer,
        sortable: false,
        disableClickEventBubbling: true,
      },
    ];

    if (isOverview) {
      return [
        baseColumns[0],
        baseColumns[1],
        {
          field: "topic",
          headerName: "Topic",
          flex: 1,
          minWidth: 150,
          renderHeader: () =>
            createHeaderCellWithTooltip(
              "Topic",
              "The tracked search topic for analyzing the source's relevance and visibility.",
              theme
            ),
          renderCell: (params) => <TopicTableCell topic={params.row.topic} />,
          sortable: true,
        },
        {
          field: "country",
          headerName: "Country",
          flex: 1,
          minWidth: 120,
          renderHeader: () =>
            createHeaderCellWithTooltip(
              "Country",
              "The country where the source data was collected and scored. Optimization recommendations will be generated for this target market.",
              theme
            ),
          renderCell: (params) => (
            <TypographyTableCell value={params.row.country} />
          ),
          sortable: true,
        },
        ...baseColumns.slice(2),
      ];
    } else {
      return [
        baseColumns[0],
        baseColumns[1],
        {
          field: "source_status",
          headerName: "Status",
          flex: 1,
          minWidth: 120,
          renderHeader: () =>
            createHeaderCellWithTooltip(
              "Status",
              "Indicates the current stage of source optimization (Inbox, In Progress, Completed, Archived). Sources without a status do not currently require optimization.",
              theme
            ),
          renderCell: (params) => (
            <StatusTableCell status={params.row.source_status} />
          ),
          sortable: true,
        },
        {
          field: "country",
          headerName: "Country",
          flex: 1,
          minWidth: 120,
          renderHeader: () =>
            createHeaderCellWithTooltip(
              "Country",
              "The country where the source data was collected and scored. Optimization recommendations will be generated for this target market.",
              theme
            ),
          renderCell: (params) => (
            <TypographyTableCell value={params.row.country} />
          ),
          sortable: true,
        },
        ...baseColumns.slice(2),
      ];
    }
  }, [isOverview, theme]);

  // Memoize columns
  const columns = useMemo(() => getColumns(), [getColumns]);

  // Define filter fields with memoization
  const filterFields = useMemo(
    () => [
      { value: "source_url", label: "Source URL", type: "text" },
      { value: "topic", label: "Topic", type: "text" },
      { value: "country", label: "Country", type: "text" },
      {
        value: "type",
        label: "Type",
        type: "choice",
        options: [
          { value: "Owned", label: "Owned" },
          { value: "Operated", label: "Operated" },
          { value: "Earned", label: "Earned" },
        ],
      },
      {
        value: "dominant_sentiment",
        label: "Sentiment",
        type: "choice",
        options: [
          { value: "1", label: "Negative" },
          { value: "2", label: "Neutral" },
          { value: "3", label: "Positive" },
        ],
      },
      { value: "brand_mentions", label: "Mentions", type: "number" },
      { value: "influence_score", label: "Quality", type: "number" },
      { value: "impact_score", label: "AVI", type: "number" },
      {
        value: "source_status",
        label: "Status",
        type: "choice",
        options: [
          { value: "Inbox", label: "Inbox" },
          { value: "Inprogress", label: "In Progress" },
          { value: "Completed", label: "Completed" },
          { value: "Archived", label: "Archived" },
        ],
      },
      {
        value: "optimization_priority",
        label: "Priority",
        type: "choice",
        options: [
          { value: "Low", label: "Low" },
          { value: "Medium", label: "Medium" },
          { value: "High", label: "High" },
        ],
      },
    ],
    []
  );

  return (
    <Box sx={{ width: "100%" }}>
      <BaseTable
        columns={columns}
        fetchData={fetchData}
        initialSortModel={[{ field: "created_at", sort: "desc" }]}
        initialPaginationModel={{ page: 0, pageSize: 20 }}
        searchPlaceholder="Search by Source"
        hasFiltering={true}
        hasSearch={true}
        isServerSide={true}
        filterFields={filterFields}
        searchHandler={setSearchQuery}
      />

      {selectedRow && (
        <PopUp
          open={open}
          onClose={handleClose}
          initialSelected={selectedRow}
          setInitialSelected={setSelectedRow}
        />
      )}
    </Box>
  );
};

export default SourceTrackingTable;
