import React, { useEffect, useState } from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "./Theme/ThemeContext";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./index.css";
import Workspace from "./pages/Workspace";
import Layout from "./CommonComponents/Layout/Layout";
import Dashboard from "./pages/Dashboard";
import FinalBlog from "./pages/BlogWriter/components/FinalBlog/FinalBlog";
import BrandDnaOverview from "./pages/BrandDNA/BrandDnaOverView";
import Accounts from "./pages/Accounts";
import Login from "./pages/Accounts/Components/Login/Login";
import SignUp from "./pages/Accounts/Components/SignUp";
import AccountWorkspace from "./pages/Accounts/Components/Workspace/Workspace";
import People from "./pages/Workspace/components/People";
import AuthorStamp from "./pages/ProfileSettings/components/AuthorStamp";
import KnowledgeBase from "./pages/Workspace/components/KnowledgeBase";
import NotFound404 from "./pages/NotFound404";
import { BlogProvider } from "./contexts/blogContext";
import AEODashboard from "./pages/AEO/Pages/Dashboard/Dashboard";
import VisibilityAnalysis from "./pages/AEO/Pages/VisibilityAnalysis/VisibilityAnalysis";
import PerformanceAnalysis from "./pages/AEO/Pages/PerformanceAnalysis/PerformanceAnalysis";
import SentimentAnalysis from "./pages/AEO/Pages/SentimentAnalysis/SentimentAnalysis";
import ComparisonAnalysis from "./pages/AEO/Pages/ComparisonAnalysis/ComparisonAnalysis";
import GlobalMonitoring from "./pages/AEO/Pages/GlobalMonitoring/GlobalMonitoring";
import ConnectAccounts from "./pages/Workspace/components/ConnectAccounts";
import GeneralSettings from "./pages/Workspace/components/GeneralSettings";
import { handleUserLogic } from "./pages/utils";
import PendingInvites from "./pages/Accounts/Components/Workspace/Components/PendingInvites";
import ExpiredAEOReport from "./pages/ExpiredAEOReport/ExpiredAEOReport";
import AeoAssessmentReport from "./pages/AEOAssessmentReport/AeoAssessmentReport";
import OptimizationOverview from "./pages/OptimizationHub/pages/Overview/Overview";
import TopicDetails from "./pages/OptimizationHub/pages/TopicDetails";
import ProfileSettings from "./pages/ProfileSettings/ProfileSettings";
import Profile from "./pages/ProfileSettings/components/profile";
import { UserProvider } from "./contexts/UserContext";
import AiContentWriter from "./pages/AiContentWriter";
import { GlobalFiltersProvider } from "./contexts/GlobalFiltersContext";
import TestStaging from "./pages/TestStaging";
import WorkspaceSelection from "./pages/Accounts/Components/WorkspaceSelection";
import { BrandProvider } from "./contexts/BrandContext";
import { BrandAccessGuard } from "./guards/brandAccessGuard";
import { AuthGuard } from "./guards/authGuard";
import ForgetPassword from "./pages/Accounts/Components/ForgetPassword";
import ConfirmForgetPassword from "./pages/Accounts/Components/ConfirmForgetPassword";
import ConfirmResetPassword from "./pages/Accounts/Components/ConfirmResetPassword/ConfirmResetPassword";
import ResetPassword from "./pages/Accounts/Components/ResetPassword/ResetPassword";
import AuthCallback from "./pages/Accounts/Components/AuthCallback/AuthCallback";
import AITraffic from "./pages/AgentAnalytics/Pages/AITraffic/AITraffic";
import AIAgents from "./pages/AgentAnalytics/Pages/AIAgents";
import { msalInstance } from "./msalConfig";
import { MsalProvider } from "@azure/msal-react";

function App() {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    msalInstance.initialize().then(() => setIsInitialized(true));
  }, []);

  useEffect(() => {
    handleUserLogic();
  }, []);

  if (!isInitialized) return;
  return (
    <MsalProvider instance={msalInstance}>
      <BrandProvider>
        <UserProvider>
          <BlogProvider>
            <ThemeProvider>
              <CssBaseline />
              <Router>
                <Routes>
                  {/* Public Routes */}
                  <Route path="/" element={<Navigate to="/login" replace />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/accounts/:userId" element={<Accounts />} />
                  <Route
                    path="/auth/callback/:provider/:userId?"
                    element={<AuthCallback />}
                  />
                  <Route path="/forget-password" element={<ForgetPassword />} />
                  <Route
                    path="/forget-password/confirm"
                    element={<ConfirmForgetPassword />}
                  />
                  <Route path="/reset-password/" element={<ResetPassword />} />
                  <Route
                    path="/reset-password/confirm"
                    element={<ConfirmResetPassword />}
                  />
                  <Route
                    path="/accounts/:userId/pending-invites"
                    element={<PendingInvites />}
                  />

                  {/* Auth Required Routes */}
                  <Route element={<AuthGuard />}>
                    <Route
                      path="/workspace-setup"
                      element={<AccountWorkspace />}
                    />
                    <Route
                      path="/workspace-selection"
                      element={<WorkspaceSelection />}
                    />
                    <Route
                      path="/accounts/:userId/pending-invites"
                      element={<PendingInvites />}
                    />

                    {/* Layout-wrapped Protected Routes */}
                    <Route element={<Layout />}>
                      {/* Brand-specific routes */}
                      <Route element={<BrandAccessGuard />}>
                        <Route
                          path="/dashboard/:brandId"
                          element={
                            <GlobalFiltersProvider>
                              <Dashboard />
                            </GlobalFiltersProvider>
                          }
                        />
                        <Route
                          path="/content-writer/:brandId"
                          element={<AiContentWriter />}
                        />
                        <Route
                          path="/workspace-settings/:brandId"
                          element={<Workspace />}
                        />
                        <Route
                          path="/workspace-settings/:brandId/people"
                          element={<People />}
                        />
                        <Route
                          path="/workspace-settings/:brandId/knowledge-base"
                          element={<KnowledgeBase />}
                        />
                        <Route
                          path="/workspace-settings/:brandId/brand-dna-overview"
                          element={<BrandDnaOverview />}
                        />
                        <Route
                          path="/workspace-settings/:brandId/connect-accounts"
                          element={<ConnectAccounts />}
                        />
                        <Route
                          path="/workspace-settings/:brandId/general-settings"
                          element={<GeneralSettings />}
                        />
                        <Route
                          path="/dashboard/:brandId/blog/:title/:blog_id"
                          element={<FinalBlog />}
                        />

                        {/* AEO Routes */}
                        <Route
                          path="ai-visibility/:brandId/dashboard"
                          element={<AEODashboard />}
                        />
                        <Route
                          path="ai-visibility/:brandId/visibility-analysis"
                          element={<VisibilityAnalysis />}
                        />
                        <Route
                          path="ai-visibility/:brandId/performance-analysis"
                          element={<PerformanceAnalysis />}
                        />
                        <Route
                          path="ai-visibility/:brandId/sentiment-analysis"
                          element={<SentimentAnalysis />}
                        />
                        <Route
                          path="ai-visibility/:brandId/comparison-analysis"
                          element={<ComparisonAnalysis />}
                        />
                        <Route
                          path="ai-visibility/:brandId/global-monitoring"
                          element={<GlobalMonitoring />}
                        />

                        {/* Optimization Hub Routes */}
                        <Route
                          path="optimizationhub/:brandId/overview"
                          element={<OptimizationOverview />}
                        />
                        <Route
                          path="optimizationhub/:brandId/topic/:topicName"
                          element={<TopicDetails />}
                        />
                        {/* Agent Analytics Routes */}
                        <Route
                          path="/agent-analytics/:brandId/ai-traffic"
                          element={<AITraffic />}
                        />
                        <Route
                          path="/agent-analytics/:brandId/ai-agents"
                          element={<AIAgents />}
                        />
                      </Route>

                      {/* Non-brand-specific authenticated routes */}
                      <Route
                        path="account/profile-settings"
                        element={<ProfileSettings />}
                      />
                      <Route
                        path="account/profile-settings/author-stamp"
                        element={<AuthorStamp />}
                      />
                      <Route
                        path="account/profile-settings/profile"
                        element={<Profile />}
                      />
                    </Route>
                  </Route>

                  {/* Public Assessment Routes */}
                  <Route
                    path="aeo-assessment-report/:assessmentDataId"
                    element={<AeoAssessmentReport />}
                  />
                  <Route
                    path="aeo-assessment-report/:assessmentDataId/expired"
                    element={<ExpiredAEOReport />}
                  />
                  <Route path="test-staging" element={<TestStaging />} />

                  {/* 404 Route */}
                  <Route path="*" element={<NotFound404 />} />
                </Routes>
              </Router>
            </ThemeProvider>
          </BlogProvider>
        </UserProvider>
      </BrandProvider>
    </MsalProvider>
  );
}

export default App;
