import { Grid } from "@mui/material";
import OverAllCard from "../../../../components/OverAllCard";
import { useEffect, useState } from "react";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import { endpoints } from "../../../../../../services/api/endpoints";
import { useParams } from "react-router-dom";

const isPositive = (num) => num > 0;

const dummyData = {
  ai_traffic: {
    sessions: 32,
    sessions_movement: -32,
    revenue: 0.0,
    revenue_movement: 100,
    key_events: 170,
    key_events_movement: -36,
  },
  organic: {
    sessions: 1458,
    revenue: 0.0,
    key_events: 9440,
  },
};
const dummyImpressionsData = {
  total_impressions: 9440,
  projected_impression: 9440,
  movement: 50,
};

const OverAllCards = () => {
  const [loading, setLoading] = useState();
  const { date: globalDate, noData } = useGlobalFilters();
  const { brandId } = useParams();
  const [data, setData] = useState(noData ? dummyData : []);
  const [impressionsData, setImpressionsData] = useState(
    noData ? dummyImpressionsData : []
  );
  const [impressionsLoading, setImpressionsLoading] = useState();

  const [date, setDate] = useState(globalDate);

  useEffect(() => {
    if (date.length > 0 && date[0] && date[1]) {
      setLoading(true);
      endpoints.agentAnalytics
        .getAgentAnalyticsAIOrganicTrends({
          brandId,
          start_date: date[0].format("YYYY-MM-DD"),
          end_date: date[1].format("YYYY-MM-DD"),
        })
        .then((response) => {
          setLoading(false);
          setData(response.data);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching user data:", error);
        });
    }
  }, [brandId, date]);

  useEffect(() => {
    if (date.length > 0 && date[0] && date[1]) {
      setImpressionsLoading(true);
      endpoints.agentAnalytics
        .getAgentAnalyticsImpressionsTotal({
          brandId,
          start_date: date[0].format("YYYY-MM-DD"),
          end_date: date[1].format("YYYY-MM-DD"),
        })
        .then((response) => {
          setImpressionsLoading(false);
          setImpressionsData(response.data);
        })
        .catch((error) => {
          setImpressionsLoading(false);
          console.error("Error fetching user data:", error);
        });
    }
  }, [brandId, date]);

  useEffect(() => {
    setDate(globalDate);
  }, [globalDate]);

  useEffect(() => {
    if (noData) {
      setData(dummyData);
      setImpressionsData(dummyImpressionsData);
    }
  }, [noData]);
  return (
    <Grid container spacing={2.5}>
      <Grid item xs={3}>
        <OverAllCard
          title="Sessions"
          description={`Organic sessions: ${
            noData ? "" : data?.organic?.sessions?.toLocaleString()
          }`}
          tooltipMessagePrimary="Total site visits generated by AI search. A higher session count indicates stronger AI-driven traffic to your site."
          value={data?.ai_traffic?.sessions}
          movement={data?.ai_traffic?.sessions_movement}
          isIncrease={isPositive(data?.ai_traffic?.sessions_movement)}
          loading={loading}
        />
      </Grid>
      <Grid item xs={3}>
        <OverAllCard
          title="Conversions"
          description={`Organic Conversions: ${
            noData ? "" : data?.organic?.key_events?.toLocaleString()
          }`}
          tooltipMessagePrimary="Total number of desired user actions (e.g., purchases, form submissions) completed from AI traffic. Higher conversions signal greater engagement from AI-driven visitors."
          value={data?.ai_traffic?.key_events}
          movement={data?.ai_traffic?.key_events_movement}
          isIncrease={isPositive(data?.ai_traffic?.key_events_movement)}
          percentageValue={false}
          loading={loading}
        />
      </Grid>{" "}
      <Grid item xs={3}>
        <OverAllCard
          title="Revenue Impact"
          description={`Organic revenue:  $${
            noData ? "" : data?.organic?.revenue?.toLocaleString()
          }`}
          tooltipMessagePrimary="Total monetary value generated from AI-driven sessions. This reflects the direct financial impact of AI traffic on your business."
          value={data?.ai_traffic?.revenue}
          movement={data?.ai_traffic?.revenue_movement}
          isIncrease={isPositive(data?.ai_traffic?.revenue_movement)}
          percentageValue={false}
          loading={loading}
        />
      </Grid>{" "}
      <Grid item xs={3}>
        <OverAllCard
          title="Total Impressions"
          description={`Projected impressions: ${impressionsData?.projected_impression?.toLocaleString()}`}
          tooltipMessagePrimary="The number of times AI models mention or reference your brand/site. More impressions indicate greater exposure within AI-generated answers."
          value={impressionsData?.total_impressions}
          movement={impressionsData?.movement}
          isIncrease={isPositive(impressionsData?.movement)}
          loading={impressionsLoading}
        />
      </Grid>
    </Grid>
  );
};
export default OverAllCards;
