import { Avatar, Box, Button, Grid, Modal, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { endpoints } from "../../../../../../../services/api/endpoints";

const InvitedUserRow = ({ invite, refetch }) => {
  const companyName = localStorage.getItem("worskapceName");
  const { brandId } = useParams();
  const [confirmDelete, setConfirmDelete] = useState();

  const deleteInvitation = () => {
    endpoints.inviteUsers
      .deleteInvitation(invite.id)
      .then((response) => {
        setConfirmDelete(false);
        refetch();
      })
      .catch((err) => {});
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 10,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: 10,
          alignItems: "center",
        }}
      >
        <Grid
          container
          sx={{
            borderRadius: "10px !important",
            padding: "12px 16px 12px 16px",
            boxShadow: "none !important",
            rowGap: 10,
            width: "100%",
            backgroundColor: "background.secondary",
          }}
        >
          <Grid item xs={12} md={11}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: 10,
                alignItems: "center",
                fontSize: 14.8,
              }}
            >
              <Avatar
                sx={{
                  width: 20,
                  height: 20,
                  fontSize: 11,
                  color: "text.secondary",
                  backgroundColor: "background.tertiary",
                }}
              >
                {invite.email.charAt(0).toUpperCase()}
              </Avatar>
              {invite.email}
              <Typography
                sx={{
                  backgroundColor: "background.tertiary",
                  fontSize: 12,
                  fontWeight: 400,
                  padding: "3px 8px",
                  borderRadius: 30,
                }}
                color="text.secondary"
              >
                Pending Invite Acceptance
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <DeleteOutlineOutlinedIcon
          onClick={() => {
            setConfirmDelete(true);
          }}
          style={{
            fontSize: 25,
            color: "#797979",
            cursor: "pointer",
          }}
        />
      </Box>
      <Modal
        open={confirmDelete}
        onClose={() => {
          setConfirmDelete(false);
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.secondary",
            borderRadius: 10,
            boxShadow: 24,
            outline: "none",
            padding: 5,
          }}
        >
          <Typography
            style={{ fontWeight: 400, fontSize: 20 }}
            color="text.primary"
          >
            Are you sure you want to remove{" "}
            <span style={{ fontWeight: 600 }}>{invite.email} </span>
            from {companyName}'s workspace?
          </Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: 10,
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{ color: "text.brandbold", backgroundColor: "transparent" }}
              onClick={() => {
                setConfirmDelete(false);
              }}
            >
              Close
            </Button>
            <Button
              sx={{
                color: "text.negative",
                backgroundColor: "background.negative",
                "&:hover": {
                  color: "text.negative",
                  backgroundColor: "background.negative",
                },
              }}
              onClick={() => {
                deleteInvitation();
              }}
            >
              Remove
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default InvitedUserRow;
