import { Avatar, Box, Tooltip, useTheme } from "@mui/material";
import ArticalIcon from "../../../../../../CommonComponents/customIcons/ArticalIcon";
import BlogIcon from "../../../../../../CommonComponents/customIcons/BlogIcon";
import LandingPageIcon from "../../../../../../CommonComponents/customIcons/LandingPageIcon";
import OpenLinkIcon from "../../../../../../CommonComponents/customIcons/OpenLinkIcon";
import SocialMediaIcon from "../../../../../../CommonComponents/customIcons/SocialMediaIcon";
import TypographyTableCell from "../TypographyTableCell/TypographyTableCell";
import { getFaviconUrl } from "../../../../../../utils/getFaviconUrl";

const SourceTableCell = ({ value, category, navigate: url }) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={
        <div>
          <div style={{ whiteSpace: "pre-wrap" }}>{url}</div>
        </div>
      }
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: 4,
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
          overflow: "hidden",
        }}
        onClick={() => window.open(url, "_blank", "noopener,noreferrer")}
      >
        <Avatar
          sx={{
            width: "20px",
            height: "20px",
            textAlign: "center",
            mr: 1,
            flexShrink: 0,
          }}
          src={getFaviconUrl(url)}
        />
        <Box sx={{ flexGrow: 1, minWidth: 0 }}>
          <TypographyTableCell value={value} />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexShrink: 0,
            width: "20px",
            height: "20px",
            ml: 1,
          }}
        >
          <OpenLinkIcon color={theme.palette.text.tertiary} />
        </Box>
      </Box>
    </Tooltip>
  );
};
export default SourceTableCell;
