import React from "react";

const CheckIcon = ({ color = "#AFB1B7", width, height }) => (
  <svg
    width="46"
    height="45"
    viewBox="0 0 46 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="23" cy="22.5" r="22.5" fill="#8DA3FA" fill-opacity="0.2" />
    <path
      d="M25.764 19.148L20.616 24.308L18.636 22.328C18.5284 22.2024 18.396 22.1004 18.2472 22.0283C18.0983 21.9563 17.9361 21.9158 17.7709 21.9094C17.6056 21.9031 17.4408 21.9309 17.2868 21.9912C17.1328 22.0516 16.993 22.1431 16.876 22.26C16.7591 22.377 16.6676 22.5168 16.6072 22.6708C16.5469 22.8248 16.5191 22.9896 16.5254 23.1549C16.5318 23.3201 16.5723 23.4823 16.6443 23.6312C16.7164 23.78 16.8184 23.9124 16.944 24.02L19.764 26.852C19.8761 26.9632 20.0091 27.0512 20.1553 27.1109C20.3015 27.1706 20.4581 27.2009 20.616 27.2C20.9308 27.1987 21.2325 27.0737 21.456 26.852L27.456 20.852C27.5685 20.7404 27.6578 20.6077 27.7187 20.4615C27.7796 20.3153 27.811 20.1584 27.811 20C27.811 19.8416 27.7796 19.6847 27.7187 19.5385C27.6578 19.3923 27.5685 19.2596 27.456 19.148C27.2312 18.9245 26.927 18.799 26.61 18.799C26.293 18.799 25.9888 18.9245 25.764 19.148ZM22.5 11C20.1266 11 17.8066 11.7038 15.8332 13.0224C13.8598 14.3409 12.3217 16.2151 11.4135 18.4078C10.5052 20.6005 10.2676 23.0133 10.7306 25.3411C11.1936 27.6689 12.3365 29.807 14.0147 31.4853C15.693 33.1635 17.8311 34.3064 20.1589 34.7694C22.4867 35.2324 24.8995 34.9948 27.0922 34.0865C29.2849 33.1783 31.1591 31.6402 32.4776 29.6668C33.7962 27.6934 34.5 25.3734 34.5 23C34.5 21.4241 34.1896 19.8637 33.5866 18.4078C32.9835 16.9519 32.0996 15.629 30.9853 14.5147C29.871 13.4004 28.5481 12.5165 27.0922 11.9134C25.6363 11.3104 24.0759 11 22.5 11ZM22.5 32.6C20.6013 32.6 18.7452 32.037 17.1665 30.9821C15.5878 29.9272 14.3574 28.4279 13.6308 26.6738C12.9042 24.9196 12.714 22.9893 13.0845 21.1271C13.4549 19.2649 14.3692 17.5544 15.7118 16.2118C17.0544 14.8692 18.7649 13.9549 20.6271 13.5845C22.4894 13.214 24.4196 13.4042 26.1738 14.1308C27.9279 14.8574 29.4272 16.0878 30.4821 17.6665C31.537 19.2452 32.1 21.1013 32.1 23C32.1 25.5461 31.0886 27.9879 29.2882 29.7882C27.4879 31.5886 25.0461 32.6 22.5 32.6Z"
      fill="#C6D1FD"
    />
  </svg>
);

export default CheckIcon;
