import { Card, Typography, useTheme } from "@mui/material";
import ChipButton from "../../../../../../CommonComponents/ChipButton";
import Wrapper from "../../../Wrapper/Wrapper";
import EastIcon from "@mui/icons-material/East";
import DNA from "../../../../../../CommonComponents/customIcons/DNA";
import { useNavigate } from "react-router-dom";

const WelcomeDNA = ({ setCurrentStep, onClose = () => {} }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const brandId = localStorage.getItem("brandId");
  return (
    <Wrapper>
      <Card
        style={{
          borderRadius: "35px",
          padding: "20px 85px 20px 85px",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          width: 550,
          rowGap: 20,
          backgroundColor: "transparent",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            rowGap: 16,
          }}
        >
          <DNA color={theme.palette.text.primary} width="24px" height="24px" />

          <Typography
            style={{ fontSize: 36, fontWeight: 500, lineHeight: "44px" }}
            color="text.primary"
          >
            Help us get to know your brand a little better...
          </Typography>
          <div>
            <Typography
              style={{ fontSize: 14, fontWeight: 450 }}
              color="text.secondary"
            >
              Complete the following questions to train Goodie on your
              workspace, and what makes it unique.
            </Typography>{" "}
          </div>
        </div>

        <ChipButton
          iconAfter={<EastIcon />}
          label={"Complete Brand DNA"}
          backgroundColor={theme.palette.background.brand}
          textColor={theme.palette.text.chiptext}
          hoverBackgroundColor={theme.palette.text.brandbold}
          hoverTextColor={theme.palette.text.chiptext}
          hoverBorderColor={theme.palette.text.brandbold}
          fullWidth
          onClick={() => {
            onClose();
            navigate(`/dashboard/${brandId}/`);
          }}
        />
      </Card>{" "}
    </Wrapper>
  );
};
export default WelcomeDNA;
