import WorkspacePageTemplate from "../Workspace/components/PageTemplate/PageTemplate";
import { CircularProgress, Grid } from "@mui/material";
import StepperModal from "../../CommonComponents/StepperModal";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import GenerateBlogModal from "../Dashboard/components/GenerateBlogModal";
import IncompleteDna from "../Dashboard/components/IncompleteDna/IncompleteDna";
import RecentSection from "../Dashboard/components/RecentSection/RecentSection";
import ShortcutCard from "../Dashboard/components/ShorcutCard/ShortcutsCard";
import Business from "../BrandDNA/components/BrandDNAForm/components/Business";
import Persona from "../BrandDNA/components/BrandDNAForm/components/Persona";
import BrandVoice from "../BrandDNA/components/BrandDNAForm/components/BrandVoice/BrandVoice";
import Global from "../BrandDNA/components/BrandDNAForm/components/Global";
import Competition from "../BrandDNA/components/BrandDNAForm/components/Competition/Competition";
import Topics from "../BrandDNA/components/BrandDNAForm/components/Topics/Topics";
import { getValidationSchema, steps } from "../BrandDNA/conts";
import KnowledgeBaseIcon from "../../CommonComponents/customIcons/KnowledgeBaseIcon";
import { useTheme } from "@emotion/react";
import { endpoints } from "../../services/api/endpoints";

const AiContentWriter = () => {
  const { brandId: id } = useParams();
  const brandId = id || localStorage.getItem("brandId");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDnaCompleted, setIsDnaCompleted] = useState(false);
  const [openBlogDrawer, setOpenBlogDrawer] = useState(false);
  const [openDNAModal, setOpenDNAModal] = useState(false);
  const userBrands = JSON.parse(localStorage.getItem("loggedInUser"))?.brands;
  const [brandDetails, setBrandDetails] = useState({});
  const [stepCompletionStatus, setStepCompletionStatus] = useState([]);
  const theme = useTheme();

  const companyName =
    localStorage.getItem("worskapceName") ||
    userBrands?.filter((brand) => parseInt(brand.id) === parseInt(brandId))[0]
      ?.name;

  const shortcuts = useMemo(
    () => [
      {
        icon: <KnowledgeBaseIcon color={theme.palette.text.primary} />,
        title: "Write a blog",
        description:
          "Craft engaging, informative, SEO-friendly blog posts, tailored to your audience",
        backgroundColor: "#EBFFC1",
        onClick: () => setOpenBlogDrawer(true),
        isReady: true,
      },
    ],
    [setOpenBlogDrawer, theme]
  );

  useEffect(() => {
    setLoading(true);
    endpoints.brands
      .getOverview(brandId)
      .then((response) => {
        const workspaceData = response.data;

        localStorage.setItem("workspace", JSON.stringify(workspaceData));

        const stepCompletionStatus = {
          business_step_completed: workspaceData.business_step_completed,
          persona_step_completed: workspaceData.persona_step_completed,
          brand_voice_step_completed: workspaceData.brand_voice_step_completed,
          competitors_step_completed: workspaceData.competitors_step_completed,
          topics_step_completed: workspaceData.topics_step_completed,
          global_step_completed: workspaceData.global_step_completed,
        };

        localStorage.setItem(
          "stepCompletionStatus",
          JSON.stringify(stepCompletionStatus)
        );

        const iscomplete = Object.values(stepCompletionStatus).every(Boolean);
        setIsDnaCompleted(iscomplete);

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setError("Failed to fetch user data");
        setLoading(false);
      });
  }, [brandId]);

  const getStepContent = useCallback(
    (step) => {
      switch (step) {
        case 0:
          return <Business brandDetails={brandDetails} />;
        case 1:
          return <Persona brandDetails={brandDetails} />;

        case 2:
          return <BrandVoice brandDetails={brandDetails} />;
        case 3:
          return <Competition brandDetails={brandDetails} />;
        case 4:
          return <Topics brandDetails={brandDetails} />;
        case 5:
          return <Global brandDetails={brandDetails} />;
        default:
          return <Business brandDetails={brandDetails} />;
      }
    },
    [brandDetails]
  );

  const initialValues = useMemo(
    () => ({
      // business
      name: brandDetails?.name,
      primary_product: brandDetails?.primary_product,
      description: brandDetails?.description,
      conversion_goals: brandDetails?.conversion_goals || [],
      industry: brandDetails?.industry || "",
      business_model: brandDetails?.business_model || "",
      features: brandDetails?.features || ["", ""],
      benefits: brandDetails?.benefits || ["", ""],
      market_focus: brandDetails?.market_focus || "",
      usp: brandDetails.usp || "",
      country: brandDetails?.country || "",
      primary_language: brandDetails.primary_language || "",
      website: brandDetails?.website || "",
      additional_websites: brandDetails?.additional_websites || [""],
      topics: brandDetails?.topics || ["", "", ""],
      market_attributes: brandDetails?.market_attributes || [""],
      comparison_attributes: brandDetails?.comparison_attributes || [""],
      secondary_countries: brandDetails?.secondary_countries || [],
      social_media_accounts_urls: brandDetails?.social_media_accounts_urls || [
        "",
      ],
      // persona
      age_ranges: brandDetails?.age_ranges || [],
      genders: brandDetails?.genders || [],
      interests: brandDetails?.interests || [],
      job_functions: brandDetails?.job_functions || [],
      job_titles: brandDetails?.job_titles || [],
      desires: brandDetails?.desires || ["", ""],
      pain_points: brandDetails?.pain_points || ["", ""],

      // brand voice
      brand_voice: brandDetails?.brand_voice || "",
      not_brand_voice: brandDetails?.not_brand_voice || "",
      formality_tone: brandDetails?.formality_tone || "",
      core_themes: brandDetails?.core_themes || [],
      emotions: brandDetails?.emotions || [],
      styles: brandDetails?.styles || [],

      // competitors
      competitors: brandDetails?.competitors || [{ name: "", website: "" }],
    }),
    // eslint-disable-next-line
    [brandId, brandDetails]
  );

  const handleSave = useCallback(
    (values) => {
      setLoading(true);
      endpoints.brands
        .update(brandId, values)
        .then((response) => {
          setLoading(false);
          window.location.href = `/dashboard/${brandId}/`;
        })
        .catch((err) => {
          setLoading(false);
        });
    },
    [brandId]
  );

  useEffect(() => {
    if (!isDnaCompleted && !loading) setOpenDNAModal(true);
    else setOpenDNAModal(false);
  }, [isDnaCompleted, loading]);
  return loading ? (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "60%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        style={{
          width: 20,
          height: 20,
          color: "#2D333A",
        }}
      />
    </div>
  ) : (
    <WorkspacePageTemplate title="AI Content Writer">
      {!isDnaCompleted && <IncompleteDna />}
      <Grid
        container
        spacing={3}
        direction="row"
        alignItems="center"
        sx={{ marginTop: 3 }}
      >
        {shortcuts.map((card, index) => {
          return (
            <Grid item xs={8} sm={4} md={4} key={index}>
              <ShortcutCard card={card} disabled={!isDnaCompleted} />
            </Grid>
          );
        })}
      </Grid>
      <RecentSection />
      <GenerateBlogModal
        openBlogDrawer={openBlogDrawer}
        setOpenBlogDrawer={setOpenBlogDrawer}
        brandId={brandId}
      />
      <StepperModal
        open={openDNAModal}
        handleClose={() => setOpenDNAModal(false)}
        setOpenModal={setOpenDNAModal}
        modalName="Brand DNA"
        title={companyName}
        steps={steps}
        initialStep={0}
        getStepContent={getStepContent}
        formikChildren={
          (Business, Persona, BrandVoice, Competition, Global, Topics)
        }
        initialValues={initialValues}
        getValidationSchema={getValidationSchema}
        handleSave={handleSave}
        loading={loading}
        isModal={true}
        stepCompletionStatus={stepCompletionStatus}
      />
    </WorkspacePageTemplate>
  );
};
export default AiContentWriter;
