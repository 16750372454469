import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  Toolbar,
} from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import ChipButton from "../../../../../CommonComponents/ChipButton/ChipButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useLocation, useParams } from "react-router-dom";
import { endpoints } from "../../../../../services/api/endpoints";
import { useBlog } from "../../../../../contexts/blogContext";

const headings = [
  { label: "Normal", value: "" },
  { label: "Heading 1", value: "1" },
  { label: "Heading 2", value: "2" },
  { label: "Heading 3", value: "3" },
  { label: "Heading 4", value: "4" },
];

const CustomToolbar = ({ quillRef }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedHeading, setSelectedHeading] = useState("Normal");
  const [isCopied, setIsCopied] = useState(false);
  const location = useLocation();
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const { blog_id } = useParams();
  const [currentContent, setCurrentContent] = useState("");
  const isBlogPage = location.pathname.includes("/blog");
  const { editedBlog } = useBlog();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleHeadingSelect = (value, label) => {
    const quill = quillRef?.current?.getEditor();
    if (value === "") {
      quill?.format("header", false);
    } else {
      quill?.format("header", value);
    }
    setSelectedHeading(label);
    handleMenuClose();
  };

  const handleFormat = (format) => {
    const quill = quillRef?.current?.getEditor();
    const currentFormat = quill?.getFormat();

    const isActive = currentFormat[format];
    quill?.format(format, !isActive);
  };

  const handleButtonClick = () => {
    const quill = quillRef?.current?.getEditor();
    if (quill) {
      const htmlContent = quill.root.innerHTML;
      if (navigator.clipboard && window.ClipboardItem) {
        navigator.clipboard
          .write([
            new ClipboardItem({
              "text/html": new Blob([htmlContent], { type: "text/html" }),
              "text/plain": new Blob([quill.root.innerText], {
                type: "text/plain",
              }),
            }),
          ])
          .then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 5000);
          })
          .catch((err) => console.error("Failed to copy content: ", err));
      } else {
        navigator.clipboard
          .writeText(htmlContent)
          .then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 5000);
          })
          .catch((err) => console.error("Failed to copy text: ", err));
      }
    }
  };

  const saveContent = async () => {
    if (!editedBlog || !blog_id) return;
    setIsSaving(true);
    try {
      await endpoints.brands.updateBlogContent(blog_id, editedBlog);
      setIsSaved(true);
      setTimeout(() => setIsSaved(false), 3000);
    } catch (error) {
      console.error("Error saving content:", error);
    } finally {
      setIsSaving(false);
    }
  };

  // Auto-Save on Content Change with Debounce
  useEffect(() => {
    const timer = setTimeout(() => {
      saveContent();
    }, 1000);
    return () => clearTimeout(timer);
  }, [editedBlog]);

  return (
    <Toolbar
      id="toolbar"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "8px",
        borderBottom: `1px solid ${theme.palette.border.tertiary}`,
        ".ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button": {
          display: "flex !important",
        },
      }}
    >
      <Box>
        <Typography
          onClick={handleMenuOpen}
          sx={{
            fontSize: 14,
            color: theme.palette.text.secondary,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          {selectedHeading}
          <ArrowDropDownIcon
            sx={{
              fontSize: 20,
              color: theme.palette.text.secondary,
              "&:hover": {
                color: theme.palette.text.secondary,
              },
            }}
          />
        </Typography>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: theme.palette.background.secondary,
              borderRadius: "8px",
              boxShadow: "none",
            },
            "& .MuiMenuItem-root": {
              color: theme.palette.text.secondary,
              "&:hover": {
                backgroundColor: theme.palette.dropdown.hover,
              },
              "&.Mui-selected": {
                backgroundColor: theme.palette.dropdown.selected,
              },
            },
          }}
        >
          {headings.map((heading) => (
            <MenuItem
              key={heading.value}
              onClick={() => handleHeadingSelect(heading.value, heading.label)}
              selected={selectedHeading === heading.label}
            >
              {heading.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", columnGap: 1, ml: 2 }}>
        <IconButton
          onClick={() => handleFormat("bold")}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatBoldIcon />
        </IconButton>
        <IconButton
          onClick={() => handleFormat("italic")}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatItalicIcon />
        </IconButton>
        <IconButton
          onClick={() => handleFormat("underline")}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatUnderlinedIcon />
        </IconButton>
        <IconButton
          onClick={() => handleFormat("strike")}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatStrikethroughIcon />
        </IconButton>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", columnGap: 1, ml: 2 }}>
        <IconButton
          onClick={() => handleFormat("align", "left")}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatAlignLeftIcon />
        </IconButton>
        <IconButton
          onClick={() => handleFormat("align", "center")}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatAlignCenterIcon />
        </IconButton>
        <IconButton
          onClick={() => handleFormat("align", "right")}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatAlignRightIcon />
        </IconButton>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", columnGap: 1, ml: 2 }}>
        <IconButton
          onClick={() => handleFormat("list", "ordered")}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatListNumberedIcon />
        </IconButton>
        <IconButton
          onClick={() => handleFormat("list", "bullet")}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatListBulletedIcon />
        </IconButton>
      </Box>
      {isBlogPage && (
        <Box
          sx={{
            marginLeft: "auto",
            display: "flex",
            columnGap: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ChipButton
            iconBefore={
              isSaved ? <CheckCircleOutlineIcon fontSize="13px" /> : null
            }
            label={
              isSaving
                ? "Saving Changes..."
                : isSaved
                ? "Changes Saved"
                : "Save Changes"
            }
            backgroundColor={
              isSaving
                ? theme.palette.background.primary
                : isSaved
                ? theme.palette.background.positive
                : theme.palette.background.primary
            }
            textColor={
              isSaving
                ? theme.palette.text.primary
                : isSaved
                ? theme.palette.text.positive
                : theme.palette.text.primary
            }
            padding="5px 8px"
            fontSize="12px"
            borderColor={
              isSaving
                ? theme.palette.border.secondary
                : isSaved
                ? theme.palette.background.positive
                : theme.palette.border.secondary
            }
            hoverBackgroundColor={
              isSaving
                ? theme.palette.background.primary
                : isSaved
                ? theme.palette.background.positive
                : theme.palette.background.primaryhover
            }
            hoverTextColor={
              isSaving
                ? theme.palette.text.primary
                : isSaved
                ? theme.palette.text.positive
                : theme.palette.text.primary
            }
            borderRadius="30px"
            onClick={() => saveContent(currentContent)}
          />
          <ChipButton
            iconBefore={
              isCopied ? (
                <CheckCircleOutlineIcon fontSize="13px" />
              ) : (
                <ContentPasteOutlinedIcon fontSize="13px" />
              )
            }
            label={isCopied ? "Copied to clipboard" : "Copy to Clipboard"}
            backgroundColor={
              isCopied
                ? theme.palette.background.positive
                : theme.palette.background.primary
            }
            textColor={
              isCopied
                ? theme.palette.text.positive
                : theme.palette.text.secondary
            }
            padding="10px 10px"
            fontSize="14px"
            borderColor={
              isCopied ? "transparent" : theme.palette.border.secondary
            }
            hoverBackgroundColor={
              isCopied
                ? theme.palette.background.positive
                : theme.palette.background.primaryhover
            }
            hoverTextColor={
              isCopied
                ? theme.palette.text.positive
                : theme.palette.text.primary
            }
            borderRadius="11px"
            fontWeight={450}
            columnGap={0.5}
            onClick={handleButtonClick}
          />
        </Box>
      )}
    </Toolbar>
  );
};

export default CustomToolbar;
