import React from "react";

const BusinessIcon = ({ color = "#8C8C8C", width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : "16px"}
    height={height ? height : "16px"}
    viewBox="0 0 16 16"
    fill="none"
  >
    <mask
      x="0"
      y="0"
      width={width ? width : "16px"}
      height={height ? height : "16px"}
    >
      <rect
        width={width ? width : "16px"}
        height={height ? height : "16px"}
        fill={color}
      />
    </mask>
    <g>
      <path
        d="M2.66683 14.0002C2.30016 14.0002 1.98627 13.8696 1.72516 13.6085C1.46405 13.3474 1.3335 13.0335 1.3335 12.6668V5.3335C1.3335 4.96683 1.46405 4.65294 1.72516 4.39183C1.98627 4.13072 2.30016 4.00016 2.66683 4.00016H5.3335V2.66683C5.3335 2.30016 5.46405 1.98627 5.72516 1.72516C5.98627 1.46405 6.30016 1.3335 6.66683 1.3335H9.3335C9.70016 1.3335 10.0141 1.46405 10.2752 1.72516C10.5363 1.98627 10.6668 2.30016 10.6668 2.66683V4.00016H13.3335C13.7002 4.00016 14.0141 4.13072 14.2752 4.39183C14.5363 4.65294 14.6668 4.96683 14.6668 5.3335V12.6668C14.6668 13.0335 14.5363 13.3474 14.2752 13.6085C14.0141 13.8696 13.7002 14.0002 13.3335 14.0002H2.66683ZM2.66683 12.6668H13.3335V5.3335H2.66683V12.6668ZM6.66683 4.00016H9.3335V2.66683H6.66683V4.00016Z"
        fill={color}
      />
    </g>
  </svg>
);

export default BusinessIcon;
