import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import AddMoreButton from "../../../../CommonComponents/FormField/components/AddMoreButton/AddMoreButton";
import WorkspacePageTemplate from "../PageTemplate/PageTemplate";
import AddConnector from "./Components/AddConnector/AddConnector";
import ConnectorRow from "./Components/ConnectorRow/ConnectorRow";
import { endpoints } from "../../../../services/api/endpoints";
import ConnectProperty from "./Components/ConnectProperty";

const ConnectAccounts = () => {
  const [loading, setLoading] = useState();
  const [connectedAccounts, setConnectedAccounts] = useState([]);
  const [openConnectAccounts, setOpenConnectAccounts] = useState(false);
  const [openConnectProperty, setOpenConnectProperty] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const access_token = params.get("access_token");
  const { brandId } = useParams();

  const refetch = () => {
    setLoading(true);
    endpoints.brands
      .getConnectors(brandId)
      .then((response) => {
        setLoading(false);
        setConnectedAccounts(
          response.data.filter((conn) => conn.status === "connected")
        );
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    refetch();
  }, [brandId]);

  useEffect(() => {
    if (access_token) {
      setOpenConnectProperty(true);
    }
  }, [access_token]);

  return (
    <WorkspacePageTemplate title="Integrations">
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: "14px",
          fontWeight: 450,
          maxWidth: "596px",
          mt: -4,
        }}
        color="text.secondary"
      >
        Enhance your AI search visibility analysis and optimization
        recommendations by connecting your GA4, Google Search Console, social
        media, and other third-party accounts.
      </Typography>
      <Divider
        sx={{
          width: "100%",
          borderColor: "border.tertiary",
          pb: { xs: 1, md: 6 },
          mb: { xs: 1, md: 3 },
        }}
      />
      <Typography
        style={{
          fontSize: 14,
          fontWeight: 450,
          marginTop: 10,
        }}
        color="text.tertiary"
      >
        All Connected Accounts{" "}
      </Typography>

      <Box style={{ marginBottom: 22, marginTop: 52 }}>
        <Grid container>
          <Grid item xs={12} md={7}>
            <Typography
              style={{ fontSize: 14, fontWeight: 500 }}
              color="text.primary"
            >
              Account
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography
              style={{ fontSize: 14, fontWeight: 500 }}
              color="text.primary"
            >
              Installed By
            </Typography>
          </Grid>{" "}
          <Grid item xs={12} md={2}>
            <Typography
              style={{ fontSize: 14, fontWeight: 500 }}
              color="text.primary"
            >
              Date Connected
            </Typography>
          </Grid>{" "}
          <Grid item xs={12} md={1}>
            <Typography
              style={{ fontSize: 14, fontWeight: 500 }}
              color="text.primary"
            >
              Status
            </Typography>
          </Grid>
        </Grid>
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: "70%",
              left: "60%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              style={{
                width: 20,
                height: 20,
                color: "#2D333A",
              }}
            />
          </div>
        ) : (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 10,
              marginTop: 15,
            }}
          >
            {connectedAccounts.map((account, index) => (
              <ConnectorRow account={account} key={index} refetch={refetch} />
            ))}
          </Box>
        )}
        <AddMoreButton onClick={() => setOpenConnectAccounts(true)} />
        <Modal
          open={openConnectAccounts}
          onClose={() => {
            setOpenConnectAccounts(false);
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.secondary",
              borderRadius: "30px",
              boxShadow: 24,
              height: "80%",
              outline: "none",
              maxHeight: "90vh",
              outline: "none",
              overflow: "hidden",
            }}
          >
            <AddConnector connectedAccounts={connectedAccounts} />
          </Box>
        </Modal>
        <Modal
          open={openConnectProperty}
          onClose={() => {
            setOpenConnectProperty(false);
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.secondary",
              borderRadius: "30px",
              boxShadow: 24,
              height: "80%",
              outline: "none",
              maxHeight: "90vh",
              outline: "none",
              overflow: "hidden",
            }}
          >
            <ConnectProperty
              access_token={access_token}
              refetch={refetch}
              onClose={() => setOpenConnectProperty(false)}
            />
          </Box>
        </Modal>
      </Box>
    </WorkspacePageTemplate>
  );
};
export default ConnectAccounts;
