import React from "react";
import { Box } from "@mui/material";
import SidebarButton from "../SideBarButton/SidebarButton";
import AnalyticsIcon from "../../../customIcons/AnalyticsIcon";
import AgentsIcon from "../../../customIcons/AgentsIcon";
import AuditIcon from "../../../customIcons/AuditIcon";
import { useParams } from "react-router-dom";

const AgentAnalyticsButtons = ({
  isCollapsed,
  sx,
  expandedPadding = "5px 0px 5px 8px",
  borderRadius = "8px",
  isDisabled,
}) => {
  const { brandId: id } = useParams();
  const brandId = id || localStorage.getItem("brandId");

  return (
    <Box sx={{ ...sx }}>
      <SidebarButton
        to={`/agent-analytics/${brandId}/ai-traffic`}
        primary="Traffic & Attribution"
        IconComponent={AnalyticsIcon}
        expandedPadding={expandedPadding}
        isCollapsed={isCollapsed}
        borderRadius={borderRadius}
        isDisabled={isDisabled}
      />
      <SidebarButton
        to={`/agent-analytics/${brandId}/ai-agents`}
        primary="Agents & Crawlers"
        IconComponent={AgentsIcon}
        expandedPadding={expandedPadding}
        isCollapsed={isCollapsed}
        borderRadius={borderRadius}
        isDisabled={true}
      />
      <SidebarButton
        // to={`/agent-analytics/${brandId}/ai-audit`}
        primary="Technical Audit"
        IconComponent={AuditIcon}
        expandedPadding={expandedPadding}
        borderRadius={borderRadius}
        isDisabled={true}
      />
    </Box>
  );
};

export default AgentAnalyticsButtons;
