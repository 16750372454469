import { Box, Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useBlog } from "../../../../contexts/blogContext";
import BlogWriterCard from "../Card/Card";
import CustomEditor from "../TextEditor";
import { endpoints } from "../../../../services/api/endpoints";

const Outline = ({
  nextFunction,
  generatedOutline,
  setOpenBlogDrawer,
  setLoadingAnimation,
  setLoadingAnimationText,
}) => {
  const navigate = useNavigate();
  const { values } = useFormikContext();
  const { brandId } = useParams();
  const [editedBlog, setEditedBlog] = useState("");
  const { setEditedBlog: setEditedBlogContext } = useBlog();
  const user = JSON.parse(localStorage.getItem("loggedInUser"));
  const theme = useTheme();

  const handleNext = () => {
    values.outline = editedBlog;
    setLoadingAnimation(true);
    setLoadingAnimationText("Your blog is currently generating");

    endpoints.content
      .generateBlog(values)
      .then((response) => {
        setLoadingAnimation(false);
        setEditedBlogContext(response.data.blogPost);

        navigate(
          `/dashboard/${brandId}/blog/${response.data.title}/${response.data.blog_id}`,
          {
            state: {
              author: {
                first_name: user?.first_name || "",
                last_name: user?.last_name || "",
              },
            },
          }
        );
        setOpenBlogDrawer(false);
      })
      .catch((err) => {
        setLoadingAnimation(false);
      });
  };

  useEffect(() => {
    nextFunction(handleNext);
  }, [nextFunction, values, editedBlog]);

  return (
    <BlogWriterCard title="Blog Outline" width={900} marginTop={60}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 40,
          marginTop: 5,
          marginBottom: 20,
        }}
      >
        <Typography
          style={{ fontSize: 14, maxWidth: 659 }}
          color="text.secondary"
        >
          Use the text editor to customize your blog post outline.
        </Typography>
        <CustomEditor
          generatedOutline={generatedOutline}
          setEditedBlog={setEditedBlog}
          backgroundColor={theme.palette.background.primary}
          width={700}
        />
      </Box>
    </BlogWriterCard>
  );
};

export default Outline;
