import { useTheme } from "@mui/styles";
import ChartCard from "../../../../../AEO/components/ChartCard/ChartCard";
import GlobalIcon from "../../../../../../CommonComponents/customIcons/GlobalIcon";
import { Box, Divider, Grid, Typography } from "@mui/material";
import ImpressionRow from "./components/ImpressionRow/ImpressionRow";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import { endpoints } from "../../../../../../services/api/endpoints";

const dummyImpressionData = [
  { movement: 4.1, impression: 334, llm: "Claude", isIncrease: true },
  { movement: 2.4, impression: 241, llm: "DeepSeek", isIncrease: true },
  { movement: 1.7, impression: 177, llm: "ChatGpt" },
  { movement: 1.7, impression: 145, llm: "Perplexity" },
  { movement: 1.4, impression: 103, llm: "Gemini", isIncrease: true },
];

const transformImpressionData = (backendResponse) => {
  return Object.entries(backendResponse).map(([llm, data]) => ({
    llm,
    impression: data.total_impressions,
    movement: data.movement,
    isIncrease: data.movement > 0,
  }));
};
const ImpressionByModelCard = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState();
  const { brandId } = useParams();
  const { date: globalDate, noData } = useGlobalFilters();

  const [data, setData] = useState(noData ? dummyImpressionData : []);

  const [date, setDate] = useState(globalDate);

  useEffect(() => {
    if (date.length > 0 && date[0] && date[1]) {
      setLoading(true);
      endpoints.agentAnalytics
        .getAgentAnalyticsModelImpressions({
          brandId,
          start_date: date[0].format("YYYY-MM-DD"),
          end_date: date[1].format("YYYY-MM-DD"),
        })
        .then((response) => {
          setLoading(false);
          setData(transformImpressionData(response.data));
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching user data:", error);
        });
    }
  }, [brandId, date]);

  useEffect(() => {
    setDate(globalDate);
  }, [globalDate]);

  useEffect(() => {
    noData && setData(dummyImpressionData);
  }, [noData]);

  return (
    <ChartCard
      title="Impressions by Model"
      icon={<GlobalIcon color={theme.palette.text.primary} />}
      tooltipMessagePrimary="Breakdown of how frequently different AI models display or reference your brand/site. Higher impressions indicate greater exposure in AI-generated results."
      height={"100%"}
      isLoading={loading}
    >
      <Grid container width={"100%"} sx={{ marginTop: 2 }}>
        <Grid item xs={4}>
          <Typography
            color="text.tertiary"
            style={{ fontWeight: 500, fontSize: 12 }}
          >
            Model
          </Typography>
        </Grid>

        {["Impressions", "Change"].map((title, index) => (
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            key={index}
          >
            <Typography
              color="text.tertiary"
              style={{ fontWeight: 500, fontSize: 12 }}
            >
              {title}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Divider
        sx={{
          borderColor: theme.palette.border.quaternary,
          width: "100%",
          margin: "10px 0px",
        }}
      />

      {data?.map((data, index) => (
        <Box>
          <ImpressionRow key={index} {...data} />
          <Divider
            sx={{
              borderColor: theme.palette.border.quaternary,
              width: "100%",
              margin: "10px 0px",
            }}
          />
        </Box>
      ))}
    </ChartCard>
  );
};
export default ImpressionByModelCard;
